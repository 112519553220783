import React, { useState, useEffect } from 'react';
import Util from '../libs/Util'
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useSelector } from 'react-redux';


import Avatar from '../assets/img/user4-128x128-608e09db39f5a410efb193ff5a5ba607.jpg';


export default function Profile() {

    const cookies = new Cookies();
    const userData = useSelector(state => state.session);

    const [selfDataList, setSelfDataList] = useState({});

    useEffect(() => {
        fetchSelfData();
    }, []);


    const fetchSelfData = async () => {

        let response = await Util.formSubmit(`${global.MainClient}/selfdata`, "POST", {
            "token": cookies.get(global.CookiesVariable)
        });

        if (response.status == "TRUE") {
            setSelfDataList(response.data)
        }

    }


    return (
        <div className='content-wrapper'>
            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Profile</h1>
                        </div>
                    </div>
                </div>
            </div>
            {/* Card */}
            <section class="content">
                <div class="container-fluid">
                    <div class="row">


                        <div class="col-lg-4">

                            <div class="card card-primary card-outline">
                                <div class="card-body box-profile">
                                    <div class="text-center">
                                        <img class="profile-user-img img-fluid img-circle" src={Avatar} alt="User profile picture" />
                                    </div>

                                    <h3 class="profile-username text-center">{userData.userid} {selfDataList.name}</h3>

                                    <p class="text-muted text-center">Admin</p>

                                    <ul class="list-group list-group-unbordered mb-3">
                                        <li class="list-group-item">
                                            <b>Share</b> <a class="float-right">{selfDataList.myShare}</a>
                                        </li>
                                        <li class="list-group-item">
                                            <b>Match Commission</b> <a class="float-right"> {selfDataList.mComm} </a>
                                        </li>
                                        <li class="list-group-item">
                                            <b>Session Commission</b> <a class="float-right">{selfDataList.sComm}</a>
                                        </li>
                                        {/* <li class="list-group-item">
                                            <b>Mobile Share</b> <a class="float-right">100.0</a>
                                        </li> */}
                                    </ul>

                                    <Link to={"/setting"} class="btn btn-primary btn-block"><b>Change Password</b></Link>
                                </div>

                            </div>

                        </div>



                        <div class="card card-primary col-lg-4">
                            <div class="card-header">
                                <h3 class="card-title">About Me</h3>
                            </div>

                            <div class="card-body">
                                <strong><i class="fas fa-id-card"></i> Profile</strong>

                                <p class="text-muted">{userData.userid} {selfDataList.name}</p>

                                <hr />

                                <strong><i class="fas fa-coins"></i> <label>Current Limit :  {selfDataList.limit}</label></strong>


                                <hr />

                                <strong><i class="fas fa-pencil-alt mr-1"></i> Contact No</strong>

                                <p class="text-muted">
                                    <span class="tag tag-danger">0000000000</span>
                                </p>

                                <hr />

                                <strong><i class="far fa-file-alt mr-1"></i> Date Of Joining</strong>

                                <p class="text-muted">2022-08-01 17:36:38</p>
                            </div>

                        </div>



                    </div>
                </div>
            </section>
        </div>
    )
}
