import '../config/GlobalVariable';
export const TransactionsConst = {
    subadmin: {
        title: "Sub admin",
        fetchClinet: `${global.MainClient}/ledger/subadmin`,
        processLink: `${global.MainClient}/ledger/subadmin/process`,
        submitLink: `${global.MainClient}/ledger/subadmin/submit`,
        deleteEntry: `${global.MainClient}/ledger/subadmin/delete`,
    },
    // supermaster: {
    //     title: "Super master",
    //     fetchClinet: `${global.MainClient}/ledger/supermaster`,
    //     processLink: `${global.MainClient}/ledger/supermaster/process`,
    //     submitLink: `${global.MainClient}/ledger/supermaster/submit`,
    //     deleteEntry: `${global.MainClient}/ledger/supermaster/delete`,
    // },
    master: {
        title: "Master",
        fetchClinet: `${global.MainClient}/ledger/master/client`,
        processLink: `${global.MainClient}/ledger/master/process`,
        submitLink: `${global.MainClient}/ledger/master/submit`,
        deleteEntry: `${global.MainClient}/ledger/master/delete`,
    },
    superagent: {
        title: "Super agent",
        fetchClinet: `${global.MainClient}/ledger/superagent/client`,
        processLink: `${global.MainClient}/ledger/superagent/process`,
        submitLink: `${global.MainClient}/ledger/superagent/submit`,
        deleteEntry: `${global.MainClient}/ledger/superagent/delete`,
    },
    agent: {
        title: "Agent",
        fetchClinet: `${global.MainClient}/ledger/agent/client`,
        processLink: `${global.MainClient}/ledger/agent/process`,
        submitLink: `${global.MainClient}/ledger/agent/submit`,
        deleteEntry: `${global.MainClient}/ledger/agent/delete`,
    },
    client: {
        title: "Client",
        fetchClinet: `${global.MainClient}/ledger/client`,
        processLink: `${global.MainClient}/ledger/client/process`,
        submitLink: `${global.MainClient}/ledger/client/submit`,
        deleteEntry: `${global.MainClient}/ledger/client/delete`,
    }
}