import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Checkbox from '../components/Checkbox';
import Cookies from 'universal-cookie';
import Util from '../libs/Util';


const Collection = () => {

    const cookies = new Cookies();
    const navigate = useNavigate();

    const [list, setList] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    useEffect(() => {

        (async()=>{

            await fecthCollection();
            fecthCollection();
            const script = document.createElement('script');
            script.src = '/js/content.js';
            script.async = true;
            document.body.appendChild(script);
            document.body.removeChild(script);
        })();
        
    }, []);


    const handleSelectAll = e => {


        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map(li => li.sno));
        if (isCheckAll) {
            setIsCheck([]);
        }

    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    const fecthCollection = async () => {
        let response = await Util.formSubmit(`${global.MainClient}/collection`, "POST", {
            "token": cookies.get(global.CookiesVariable)
        });

        if (response.status == "TRUE") {
            setList(response.data)
        }
    }

    return (
        <div className='content-wrapper' style={{ minHeight: 856.413 }}>

            <section class="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Collection Details</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Collection</li>
                            </ol>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* /.card */}
                                <div className="card">
                                    <form action="#" method="post" id="demoForm">
                                        <div className="card-header">
                                            <div className="form-group">
                                                <Link to="/collection/create" className="btn btn-primary">
                                                    New <i className="fa fa-plus-circle" />
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <table id="example1" class="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <div align="center">

                                                                <Checkbox
                                                                    type="checkbox"
                                                                    id="selectAll"
                                                                    handleClick={handleSelectAll}
                                                                    isChecked={isCheckAll}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th>#</th>
                                                        <th>CODE</th>
                                                        <th>Name</th>
                                                        <th>Reference</th>
                                                        <th>Contact No.</th>
                                                        <th>Account No.</th>
                                                        <th>Collection Type</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {list.map((data, i) => {
                                                        let k = i;
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <div align="center">
                                                                        <Checkbox
                                                                            key={data.sno}
                                                                            type="checkbox"
                                                                            id={data.sno}
                                                                            handleClick={handleClick}
                                                                            isChecked={isCheck.includes(data.sno)}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <label> {++k}</label>
                                                                    <div class="btn-group">
                                                                        <button type="button" class="btn btn-xs btn-outline-secondary dropdown-toggle dropdown-hover dropdown-icon" data-toggle="dropdown">
                                                                            <span class="sr-only">Toggle Dropdown</span>
                                                                        </button>
                                                                        <div class="dropdown-menu" role="menu">

                                                                            <Link class="dropdown-item" to={`/collection/edit/${data.sno}`}>Edit</Link>

                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {data["userid"]}
                                                                </td>
                                                                <td>{data.collectionName}</td>
                                                                <td>{data.reference}</td>
                                                                <td>{data.contactNo}</td>
                                                                <td>{data.accountNo}</td>
                                                                <td>{data.collectionType}</td>
                                                                <td>
                                                                    <div>
                                                                        {
                                                                            data.living_status ? <span class="float-center badge bg-success">Active</span> : <span class="float-center badge bg-danger">InActive</span>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>






                                    </form>
                                </div>

                            </div>

                        </div>

                    </div>

                </section>




            </section>

        </div>
    );
}

export default Collection;
