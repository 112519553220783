import React, { useState, useEffect } from 'react';
import { LedgerHelper } from '../OLDconst/LedgerConst';
import Util from '../libs/Util';
import Cookies from 'universal-cookie';


// let lenaTot = 0;
// let denaTot = 0;
// let clearTot = 0;

const Mainledger = (props) => {

    const cookies = new Cookies();

    const [getState] = useState(LedgerHelper[props.clientType]);
    const [denaList, setDenaList] = useState([]);
    const [lenaList, setLenaList] = useState([]);
    const [clearList, setClearList] = useState([]);



    const [lenaTot, setLenaTot] = useState(0);
    const [denaTot, setDenaTot] = useState(0);
    const [clearTot, setClearTot] = useState(0);




    useEffect(() => {
        getList();
    }, []);

    const getList = async () => {

        let response = await Util.formSubmit(getState.getUrl, "POST", {
            "token": cookies.get(global.CookiesVariable),
        });

        if (response.status === "TRUE") {

            let lenaTot = 0;
            let denaTot = 0;
            let clearTot = 0;

            for (const iterator of response.data.lena) {
                lenaTot += iterator.Amount;
            }

            for (const iterator of response.data.dena) {
                denaTot += iterator.Amount;
            }

            for (const iterator of response.data.clear) {
                clearTot += iterator.Amount;
            }


            setLenaTot(lenaTot);
            setDenaTot(denaTot);
            setClearTot(clearTot);

            setDenaList(response.data.dena)
            setLenaList(response.data.lena)
            setClearList(response.data.clear)

        }
    }




    return (
        <div className='content-wrapper'>
            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">{`${getState.title} Ledger`}</h1>
                        </div>
                    </div>
                </div>
            </div>

            <section class="content">
                <div class="container-fluid">
                    <div className='card'>
                        <div className="card-body" >
                            <div className="row gx-center" style={{ marginTop: 24 }}>


                                <div className="col col-xs-12 col-sm-12 col-md-4 col-xl-4">
                                    <div className="row gx-center"
                                        style={{
                                            fontSize: 24,
                                            backgroundColor: "rgb(16, 191, 53)",
                                            color: "rgb(255, 255, 255)",
                                            margin: 0,
                                            borderRadius: 4
                                        }}>
                                        <div
                                            className="col col-xs-6 col-sm-6 col-md-6 col-xl-6">
                                            <div>Lena</div>
                                        </div>
                                        <div className="col col-xs-6 col-sm-6 col-md-6 col-xl-6"
                                            style={{ textAlign: "right" }}>
                                            <div>{lenaTot}</div>
                                        </div>
                                    </div>

                                    <table class="table table-bordered table-hover">
                                        <thead>
                                            <tr>

                                                <th>User Detail</th>
                                                <th>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lenaList.map(({ Amount, user }) => {
                                                return (
                                                    <tr>
                                                        <td>{user}</td>
                                                        <td>{Amount}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                </div>


                                <div className="col col-xs-12 col-sm-12 col-md-4 col-xl-4">
                                    <div className="row gx-center"
                                        style={{
                                            fontSize: 24,
                                            backgroundColor: "rgb(222, 77, 77)",
                                            color: "rgb(255, 255, 255)",
                                            margin: 0,
                                            borderRadius: 4
                                        }}>
                                        <div
                                            className="col col-xs-6 col-sm-6 col-md-6 col-xl-6">
                                            <div>Dena</div>
                                        </div>
                                        <div className="col col-xs-6 col-sm-6 col-md-6 col-xl-6"
                                            style={{ textAlign: "right" }}>
                                            <div>{denaTot}</div>
                                        </div>
                                    </div>
                                    <table class="table table-bordered table-hover">
                                        <thead>
                                            <tr>

                                                <th>User Detail</th>
                                                <th>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {denaList.map(({ Amount, user }) => {
                                                return (
                                                    <tr>
                                                        <td>{user}</td>
                                                        <td>{Amount}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                </div>


                                <div className="col col-xs-12 col-sm-12 col-md-4 col-xl-4">
                                    <div className="row gx-center"
                                        style={{
                                            fontSize: 24,
                                            backgroundColor: "rgb(92, 181, 245)",
                                            color: "rgb(255, 255, 255)",
                                            margin: 0,
                                            borderRadius: 4
                                        }}
                                    >
                                        <div
                                            className="col col-xs-6 col-sm-6 col-md-6 col-xl-6">
                                            <div>Clear</div>
                                        </div>
                                        <div className="col col-xs-6 col-sm-6 col-md-6 col-xl-6"
                                            style={{ textAlign: "right" }}>
                                            <div>{clearTot}</div>
                                        </div>
                                    </div>
                                    <table class="table table-bordered table-hover">
                                        <thead>
                                            <tr>

                                                <th>User Detail</th>
                                                <th>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {clearList.map(({ Amount, user }) => {
                                                return (
                                                    <tr>
                                                        <td>{user}</td>
                                                        <td>{Amount}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Mainledger;
