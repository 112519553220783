import { ActionTypes } from "../contants/action-types";


export const setSession = (user) => {
    return {
        type: ActionTypes.SESSION,
        payload: user
    };
};


export const setSportPlusMinus = (data) => {
    return {
        type: ActionTypes.SportPlusMinus,
        payload: data
    };
};

export const setIp = (ip) => {
    return {
        type: ActionTypes.ClientIp,
        payload: ip
    };
};