import React, { useState, useEffect } from 'react';
import Util from '../../../../libs/Util';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import Loader from "react-js-loader";


const Msmatchreport = () => {

    const cookies = new Cookies();
    const { id } = useParams();
    let navigate = useNavigate();

    const plusMinusData = useSelector((state) => state.sportDetailsPlusMinus);
    const [matchData, setMatchData] = useState({});
    const [reportList, setReportList] = useState([]);

    // Loader
    const [isloader, setLoader] = useState(true);

    useEffect(() => {
        showDetails();
    }, []);


    const showDetails = async () => {

        if (plusMinusData.length == 0) return navigate(`/Events/${id}/plusMinusSelect`);

        let response = await Util.formSubmit(`${global.MainClient}/cricket/plusminus_details`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "matchId": id,
            "USER": plusMinusData[0].USERS,
            "FANCY": plusMinusData[1].FANCY
        });

        if (response.status === "TRUE") {
            setMatchData(response.data.matchDetails);
            setReportList(response.data.result);
        }
        setLoader(false)
    }

    let gTotal_mAmt = 0;
    let gTotal_sAmt = 0;
    let gTotal_totAmt = 0;
    let gTotal_mComm = 0;
    let gTotal_sComm = 0;
    let gTotal_totComm = 0;
    let gTotal_netAmt = 0;
    let gTotal_share_agent = 0;
    let gTotal_shrAmt = 0;
    let gTotal_fAmt = 0;
    // Super Agent
    let gTotal_mComm_super = 0;
    let gTotal_sComm_super = 0;
    let gTotal_totComm_super = 0;
    let gTotal_netAmt_super = 0;
    let gTotal_share_super = 0;
    let gTotal_shrAmt_super = 0;
    let gTotal_fAmt_super = 0;
    // Master
    let gTotal_mComm_master = 0;
    let gTotal_sComm_master = 0;
    let gTotal_totComm_master = 0;
    let gTotal_netAmt_master = 0;
    let gTotal_share_master = 0;
    let gTotal_shrAmt_master = 0;
    let gTotal_fAmt_master = 0;

    // Sub Admin
    let gTotal_mComm_subadmin = 0;
    let gTotal_sComm_subadmin = 0;
    let gTotal_totComm_subadmin = 0;
    let gTotal_netAmt_subadmin = 0;
    let gTotal_share_subadmin = 0;
    let gTotal_shrAmt_subadmin = 0;
    let gTotal_fAmt_subadmin = 0;
    //  Admin
    let gTotal_mComm_admin = 0;
    let gTotal_sComm_admin = 0;
    let gTotal_totComm_admin = 0;
    let gTotal_netAmt_admin = 0;
    let gTotal_share_admin = 0;
    let gTotal_shrAmt_admin = 0;
    let gTotal_fAmt_admin = 0;


    return (
        <div className='content-wrapper'>
            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Match Report</h1>
                        </div>
                    </div>
                </div>
            </div>

            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <section id='livepos' className="content">
                            <div className="container-fluid">
                                {/* start */}
                                <div className="row">
                                    <div class="msreport">

                                        <div className="card card-default">

                                            <div className="card-header text-bold">
                                                <h5 className="card-title text-white">MATCH CODE : ({matchData.sno}) {matchData.MatchName} {moment(matchData.MatchDateTime).format("DD/MM/YYYY")}</h5>
                                            </div>

                                            <div className="card-body">

                                                {reportList.map((ad_row) => {

                                                    let admin_mAmt = 0;
                                                    let admin_sAmt = 0;
                                                    let admin_totAmt = 0;
                                                    let admin_mComm = 0;
                                                    let admin_sComm = 0;
                                                    let admin_totComm = 0;
                                                    let admin_netAmt = 0;
                                                    let admin_share_agent = 0;
                                                    let admin_shrAmt = 0;
                                                    let admin_fAmt = 0;
                                                    // Super Agent
                                                    let admin_mComm_super = 0;
                                                    let admin_sComm_super = 0;
                                                    let admin_totComm_super = 0;
                                                    let admin_netAmt_super = 0;
                                                    let admin_share_super = 0;
                                                    let admin_shrAmt_super = 0;
                                                    let admin_fAmt_super = 0;
                                                    // Master
                                                    let admin_mComm_master = 0;
                                                    let admin_sComm_master = 0;
                                                    let admin_totComm_master = 0;
                                                    let admin_netAmt_master = 0;
                                                    let admin_share_master = 0;
                                                    let admin_shrAmt_master = 0;
                                                    let admin_fAmt_master = 0;

                                                    // Sub Admin
                                                    let admin_mComm_subadmin = 0;
                                                    let admin_sComm_subadmin = 0;
                                                    let admin_totComm_subadmin = 0;
                                                    let admin_netAmt_subadmin = 0;
                                                    let admin_share_subadmin = 0;
                                                    let admin_shrAmt_subadmin = 0;
                                                    let admin_fAmt_subadmin = 0;
                                                    //  Admin
                                                    let admin_mComm_admin = 0;
                                                    let admin_sComm_admin = 0;
                                                    let admin_totComm_admin = 0;
                                                    let admin_netAmt_admin = 0;
                                                    let admin_share_admin = 0;
                                                    let admin_shrAmt_admin = 0;
                                                    let admin_fAmt_admin = 0;

                                                    return (
                                                        <div>
                                                            <br />
                                                            <div className="card card-success bg-gray-light">

                                                                <div className="card-header text-center">
                                                                    <h2 className="card-title text-bold text-white">Admin : {ad_row.name}</h2>
                                                                </div>

                                                                <div className="card-body">

                                                                    {ad_row.child.map((sua_row) => {


                                                                        let subAdmin_mAmt = 0;
                                                                        let subAdmin_sAmt = 0;
                                                                        let subAdmin_totAmt = 0;
                                                                        let subAdmin_mComm = 0;
                                                                        let subAdmin_sComm = 0;
                                                                        let subAdmin_totComm = 0;
                                                                        let subAdmin_netAmt = 0;
                                                                        let subAdmin_share_agent = 0;
                                                                        let subAdmin_shrAmt = 0;
                                                                        let subAdmin_fAmt = 0;
                                                                        // Super Agent
                                                                        let subAdmin_mComm_super = 0;
                                                                        let subAdmin_sComm_super = 0;
                                                                        let subAdmin_totComm_super = 0;
                                                                        let subAdmin_netAmt_super = 0;
                                                                        let subAdmin_share_super = 0;
                                                                        let subAdmin_shrAmt_super = 0;
                                                                        let subAdmin_fAmt_super = 0;
                                                                        // Master
                                                                        let subAdmin_mComm_master = 0;
                                                                        let subAdmin_sComm_master = 0;
                                                                        let subAdmin_totComm_master = 0;
                                                                        let subAdmin_netAmt_master = 0;
                                                                        let subAdmin_share_master = 0;
                                                                        let subAdmin_shrAmt_master = 0;
                                                                        let subAdmin_fAmt_master = 0;

                                                                        // Sub Admin
                                                                        let subAdmin_mComm_subadmin = 0;
                                                                        let subAdmin_sComm_subadmin = 0;
                                                                        let subAdmin_totComm_subadmin = 0;
                                                                        let subAdmin_netAmt_subadmin = 0;
                                                                        let subAdmin_share_subadmin = 0;
                                                                        let subAdmin_shrAmt_subadmin = 0;
                                                                        let subAdmin_fAmt_subadmin = 0;
                                                                        //  Admin
                                                                        let subAdmin_mComm_admin = 0;
                                                                        let subAdmin_sComm_admin = 0;
                                                                        let subAdmin_totComm_admin = 0;
                                                                        let subAdmin_netAmt_admin = 0;
                                                                        let subAdmin_share_admin = 0;
                                                                        let subAdmin_shrAmt_admin = 0;
                                                                        let subAdmin_fAmt_admin = 0;


                                                                        return (
                                                                            <div>
                                                                                <br />
                                                                                <div className="card card-success bg-gray-light ">

                                                                                    <div className="card-header text-center">
                                                                                        <h2 className="card-title ">Sub admin : {sua_row.name2} </h2>
                                                                                    </div>

                                                                                    <div className="card-body">
                                                                                        {
                                                                                            sua_row.child.map((m_row) => {

                                                                                                let master_mAmt = 0;
                                                                                                let master_sAmt = 0;
                                                                                                let master_totAmt = 0;
                                                                                                let master_mComm = 0;
                                                                                                let master_sComm = 0;
                                                                                                let master_totComm = 0;
                                                                                                let master_netAmt = 0;
                                                                                                let master_share_agent = 0;
                                                                                                let master_shrAmt = 0;
                                                                                                let master_fAmt = 0;

                                                                                                // Super Agent
                                                                                                let master_mComm_super = 0;
                                                                                                let master_sComm_super = 0;
                                                                                                let master_totComm_super = 0;
                                                                                                let master_netAmt_super = 0;
                                                                                                let master_share_super = 0;
                                                                                                let master_shrAmt_super = 0;
                                                                                                let master_fAmt_super = 0;


                                                                                                // Master
                                                                                                let master_mComm_master = 0;
                                                                                                let master_sComm_master = 0;
                                                                                                let master_totComm_master = 0;
                                                                                                let master_netAmt_master = 0;
                                                                                                let master_share_master = 0;
                                                                                                let master_shrAmt_master = 0;
                                                                                                let master_fAmt_master = 0;

                                                                                                // Sub Admin
                                                                                                let master_mComm_subadmin = 0;
                                                                                                let master_sComm_subadmin = 0;
                                                                                                let master_totComm_subadmin = 0;
                                                                                                let master_netAmt_subadmin = 0;
                                                                                                let master_share_subadmin = 0;
                                                                                                let master_shrAmt_subadmin = 0;
                                                                                                let master_fAmt_subadmin = 0;


                                                                                                //  Admin
                                                                                                let master_mComm_admin = 0;
                                                                                                let master_sComm_admin = 0;
                                                                                                let master_totComm_admin = 0;
                                                                                                let master_netAmt_admin = 0;
                                                                                                let master_share_admin = 0;
                                                                                                let master_shrAmt_admin = 0;
                                                                                                let master_fAmt_admin = 0;

                                                                                                return (
                                                                                                    <div>
                                                                                                        <br />
                                                                                                        <div className="card card-success bg-gray-light ">

                                                                                                            <div className="card-header text-center">
                                                                                                                <h2 className="card-title ">Master : {m_row.name2} </h2>
                                                                                                            </div>

                                                                                                            <div className="card-body">
                                                                                                                {m_row.child.map((sa_row) => {

                                                                                                                    let superagent_mAmt = 0;
                                                                                                                    let superagent_sAmt = 0;
                                                                                                                    let superagent_totAmt = 0;
                                                                                                                    let superagent_mComm = 0;
                                                                                                                    let superagent_sComm = 0;
                                                                                                                    let superagent_totComm = 0;
                                                                                                                    let superagent_netAmt = 0;
                                                                                                                    let superagent_share_agent = 0;
                                                                                                                    let superagent_shrAmt = 0;
                                                                                                                    let superagent_fAmt = 0;

                                                                                                                    // Super Agent
                                                                                                                    let superagent_mComm_super = 0;
                                                                                                                    let superagent_sComm_super = 0;
                                                                                                                    let superagent_totComm_super = 0;
                                                                                                                    let superagent_netAmt_super = 0;
                                                                                                                    let superagent_share_super = 0;
                                                                                                                    let superagent_shrAmt_super = 0;
                                                                                                                    let superagent_fAmt_super = 0;


                                                                                                                    // Master
                                                                                                                    let superagent_mComm_master = 0;
                                                                                                                    let superagent_sComm_master = 0;
                                                                                                                    let superagent_totComm_master = 0;
                                                                                                                    let superagent_netAmt_master = 0;
                                                                                                                    let superagent_share_master = 0;
                                                                                                                    let superagent_shrAmt_master = 0;
                                                                                                                    let superagent_fAmt_master = 0;


                                                                                                                    // Super Master
                                                                                                                    let superagent_mComm_master_super = 0;
                                                                                                                    let superagent_sComm_master_super = 0;
                                                                                                                    let superagent_totComm_master_super = 0;
                                                                                                                    let superagent_netAmt_master_super = 0;
                                                                                                                    let superagent_share_master_super = 0;
                                                                                                                    let superagent_shrAmt_master_super = 0;
                                                                                                                    let superagent_fAmt_master_super = 0;


                                                                                                                    // Sub Admin
                                                                                                                    let superagent_mComm_subadmin = 0;
                                                                                                                    let superagent_sComm_subadmin = 0;
                                                                                                                    let superagent_totComm_subadmin = 0;
                                                                                                                    let superagent_netAmt_subadmin = 0;
                                                                                                                    let superagent_share_subadmin = 0;
                                                                                                                    let superagent_shrAmt_subadmin = 0;
                                                                                                                    let superagent_fAmt_subadmin = 0;


                                                                                                                    //  Admin
                                                                                                                    let superagent_mComm_admin = 0;
                                                                                                                    let superagent_sComm_admin = 0;
                                                                                                                    let superagent_totComm_admin = 0;
                                                                                                                    let superagent_netAmt_admin = 0;
                                                                                                                    let superagent_share_admin = 0;
                                                                                                                    let superagent_shrAmt_admin = 0;
                                                                                                                    let superagent_fAmt_admin = 0;

                                                                                                                    return (
                                                                                                                        <div>
                                                                                                                            <br />
                                                                                                                            <div className="card card-success bg-gray-light ">

                                                                                                                                <div className="card-header text-center">
                                                                                                                                    <h2 className="card-title ">SuperAgent : {sa_row.name2} </h2>
                                                                                                                                </div>

                                                                                                                                <div className="card-body">
                                                                                                                                    {sa_row.child.map((a_row) => {

                                                                                                                                        let agent_mAmt = 0;
                                                                                                                                        let agent_sAmt = 0;
                                                                                                                                        let agent_totAmt = 0;
                                                                                                                                        let agent_mComm = 0;
                                                                                                                                        let agent_sComm = 0;
                                                                                                                                        let agent_totComm = 0;
                                                                                                                                        let agent_netAmt = 0;
                                                                                                                                        let agent_share_agent = 0;
                                                                                                                                        let agent_shrAmt = 0;
                                                                                                                                        let agent_fAmt = 0;

                                                                                                                                        // Super Agent
                                                                                                                                        let agent_mComm_super = 0;
                                                                                                                                        let agent_sComm_super = 0;
                                                                                                                                        let agent_totComm_super = 0;
                                                                                                                                        let agent_netAmt_super = 0;
                                                                                                                                        let agent_share_super = 0;
                                                                                                                                        let agent_shrAmt_super = 0;
                                                                                                                                        let agent_fAmt_super = 0;


                                                                                                                                        // Master
                                                                                                                                        let agent_mComm_master = 0;
                                                                                                                                        let agent_sComm_master = 0;
                                                                                                                                        let agent_totComm_master = 0;
                                                                                                                                        let agent_netAmt_master = 0;
                                                                                                                                        let agent_share_master = 0;
                                                                                                                                        let agent_shrAmt_master = 0;
                                                                                                                                        let agent_fAmt_master = 0;


                                                                                                                                        // Super Master
                                                                                                                                        let agent_mComm_master_super = 0;
                                                                                                                                        let agent_sComm_master_super = 0;
                                                                                                                                        let agent_totComm_master_super = 0;
                                                                                                                                        let agent_netAmt_master_super = 0;
                                                                                                                                        let agent_share_master_super = 0;
                                                                                                                                        let agent_shrAmt_master_super = 0;
                                                                                                                                        let agent_fAmt_master_super = 0;


                                                                                                                                        // Sub Admin
                                                                                                                                        let agent_mComm_subadmin = 0;
                                                                                                                                        let agent_sComm_subadmin = 0;
                                                                                                                                        let agent_totComm_subadmin = 0;
                                                                                                                                        let agent_netAmt_subadmin = 0;
                                                                                                                                        let agent_share_subadmin = 0;
                                                                                                                                        let agent_shrAmt_subadmin = 0;
                                                                                                                                        let agent_fAmt_subadmin = 0;


                                                                                                                                        //  Admin
                                                                                                                                        let agent_mComm_admin = 0;
                                                                                                                                        let agent_sComm_admin = 0;
                                                                                                                                        let agent_totComm_admin = 0;
                                                                                                                                        let agent_netAmt_admin = 0;
                                                                                                                                        let agent_share_admin = 0;
                                                                                                                                        let agent_shrAmt_admin = 0;
                                                                                                                                        let agent_fAmt_admin = 0;

                                                                                                                                        return (
                                                                                                                                            <div>
                                                                                                                                                <br />
                                                                                                                                                <div className="card card-success bg-gradient-white ">

                                                                                                                                                    <div className="card-header text-center">
                                                                                                                                                        <h2 className="card-title text-bold">Agent : {a_row.name2}</h2>
                                                                                                                                                    </div>

                                                                                                                                                    <div className="card-body">

                                                                                                                                                        <table id="data" className="table table-striped table-bordered">

                                                                                                                                                            <thead>
                                                                                                                                                                <tr>
                                                                                                                                                                    <th colspan="11" className="text-center">Agent
                                                                                                                                                                        PlusMinus
                                                                                                                                                                    </th>
                                                                                                                                                                    <th colspan="7" className="text-center">Super Agent
                                                                                                                                                                        PlusMinus
                                                                                                                                                                    </th>
                                                                                                                                                                    <th colspan="7" className="text-center">Master
                                                                                                                                                                        PlusMinus
                                                                                                                                                                    </th>

                                                                                                                                                                    <th colspan="7" className="text-center">Sub Admin
                                                                                                                                                                        PlusMinus
                                                                                                                                                                    </th>
                                                                                                                                                                    <th colspan="7" className="text-center">Admin
                                                                                                                                                                        PlusMinus
                                                                                                                                                                    </th>
                                                                                                                                                                </tr>
                                                                                                                                                            </thead>

                                                                                                                                                            <thead>

                                                                                                                                                                <tr>
                                                                                                                                                                    <th>CLIENT</th>
                                                                                                                                                                    <th>M AMT</th>
                                                                                                                                                                    <th>S AMT</th>
                                                                                                                                                                    <th>TOT AMT</th>
                                                                                                                                                                    <th>M COM</th>
                                                                                                                                                                    <th>S COM</th>
                                                                                                                                                                    <th>TOT COM</th>
                                                                                                                                                                    <th>NET AMT</th>
                                                                                                                                                                    <th>SHR AMT</th>
                                                                                                                                                                    <th>MOB APP</th>
                                                                                                                                                                    <th>FINAL</th>

                                                                                                                                                                    <th>M COM</th>
                                                                                                                                                                    <th>S COM</th>
                                                                                                                                                                    <th>TOL COM</th>
                                                                                                                                                                    <th>NET AMT</th>
                                                                                                                                                                    <th>SHR AMT</th>
                                                                                                                                                                    <th>MOB APP</th>
                                                                                                                                                                    <th>FINAL</th>

                                                                                                                                                                    <th>M COM</th>
                                                                                                                                                                    <th>S COM</th>
                                                                                                                                                                    <th>TOL COM</th>
                                                                                                                                                                    <th>NET AMT</th>
                                                                                                                                                                    <th>SHR AMT</th>
                                                                                                                                                                    <th>MOB APP</th>
                                                                                                                                                                    <th>FINAL</th>

                                                                                                                                                                    <th>M COM</th>
                                                                                                                                                                    <th>S COM</th>
                                                                                                                                                                    <th>TOL COM</th>
                                                                                                                                                                    <th>NET AMT</th>
                                                                                                                                                                    <th>SHR AMT</th>
                                                                                                                                                                    <th>MOB APP</th>
                                                                                                                                                                    <th>FINAL</th>



                                                                                                                                                                    <th>M COM</th>
                                                                                                                                                                    <th>S COM</th>
                                                                                                                                                                    <th>TOL COM</th>
                                                                                                                                                                    <th>NET AMT</th>
                                                                                                                                                                    <th>SHR AMT</th>
                                                                                                                                                                    <th>MOB APP</th>
                                                                                                                                                                    <th>FINAL</th>


                                                                                                                                                                </tr>
                                                                                                                                                            </thead>

                                                                                                                                                            <tbody>

                                                                                                                                                                {
                                                                                                                                                                    a_row.child.map((c_row) => {

                                                                                                                                                                        let mAmt = c_row.matchAmt;
                                                                                                                                                                        let sAmt = c_row.sessAmt;
                                                                                                                                                                        let totAmt = mAmt + sAmt;
                                                                                                                                                                        let mComm = (c_row.sharecommAmt.agent.M * 100) / (100 - c_row.sharecomm.agent.share);
                                                                                                                                                                        let sComm = (c_row.sharecommAmt.agent.S * 100) / (100 - c_row.sharecomm.agent.share);
                                                                                                                                                                        let totComm = mComm + sComm;
                                                                                                                                                                        let netAmt = totAmt - totComm;
                                                                                                                                                                        let share_agent = c_row.sharecomm.agent.share;
                                                                                                                                                                        let shrAmt = (netAmt * share_agent) / 100;
                                                                                                                                                                        let fAmt = netAmt - shrAmt;

                                                                                                                                                                        // Super Agent
                                                                                                                                                                        let mComm_super = (c_row.sharecommAmt.superagent.M * 100) / (100 - c_row.sharecomm.superagent.share);
                                                                                                                                                                        let sComm_super = (c_row.sharecommAmt.superagent.S * 100) / (100 - c_row.sharecomm.superagent.share);
                                                                                                                                                                        let totComm_super = mComm_super + sComm_super;
                                                                                                                                                                        let netAmt_super = totAmt - totComm_super;
                                                                                                                                                                        let share_super = c_row.sharecomm.superagent.share;
                                                                                                                                                                        let shrAmt_super = (netAmt_super * share_super) / 100;
                                                                                                                                                                        let fAmt_super = netAmt_super - shrAmt_super;


                                                                                                                                                                        // Master
                                                                                                                                                                        let mComm_master = (c_row.sharecommAmt.master.M * 100) / (100 - c_row.sharecomm.master.share);
                                                                                                                                                                        let sComm_master = (c_row.sharecommAmt.master.S * 100) / (100 - c_row.sharecomm.master.share);
                                                                                                                                                                        let totComm_master = mComm_master + sComm_master;
                                                                                                                                                                        let netAmt_master = totAmt - totComm_master;
                                                                                                                                                                        let share_master = c_row.sharecomm.master.share;
                                                                                                                                                                        let shrAmt_master = (netAmt_master * share_master) / 100;
                                                                                                                                                                        let fAmt_master = netAmt_master - shrAmt_master;





                                                                                                                                                                        // Sub Admin
                                                                                                                                                                        let mComm_subadmin = (c_row.sharecommAmt.subadmin.M * 100) / (100 - c_row.sharecomm.subadmin.share);
                                                                                                                                                                        let sComm_subadmin = (c_row.sharecommAmt.subadmin.S * 100) / (100 - c_row.sharecomm.subadmin.share);
                                                                                                                                                                        let totComm_subadmin = mComm_subadmin + sComm_subadmin;
                                                                                                                                                                        let netAmt_subadmin = totAmt - totComm_subadmin;
                                                                                                                                                                        let share_subadmin = c_row.sharecomm.subadmin.share;
                                                                                                                                                                        let shrAmt_subadmin = (netAmt_subadmin * share_subadmin) / 100;
                                                                                                                                                                        let fAmt_subadmin = netAmt_subadmin - shrAmt_subadmin;


                                                                                                                                                                        //  Admin
                                                                                                                                                                        let mComm_admin = (c_row.sharecommAmt.admin.M * 100) / (100 - c_row.sharecomm.admin.share);
                                                                                                                                                                        let sComm_admin = (c_row.sharecommAmt.admin.S * 100) / (100 - c_row.sharecomm.admin.share);
                                                                                                                                                                        let totComm_admin = mComm_admin + sComm_admin;
                                                                                                                                                                        let netAmt_admin = totAmt - totComm_admin;
                                                                                                                                                                        let share_admin = c_row.sharecomm.admin.share;
                                                                                                                                                                        let shrAmt_admin = (netAmt_admin * share_admin) / 100;
                                                                                                                                                                        let fAmt_admin = netAmt_admin - shrAmt_admin;

                                                                                                                                                                        // start footer Agent
                                                                                                                                                                        // Agent
                                                                                                                                                                        agent_mAmt += mAmt;
                                                                                                                                                                        agent_sAmt += sAmt;
                                                                                                                                                                        agent_totAmt += totAmt;
                                                                                                                                                                        agent_mComm += mComm;
                                                                                                                                                                        agent_sComm += sComm;
                                                                                                                                                                        agent_totComm += totComm;
                                                                                                                                                                        agent_netAmt += netAmt;
                                                                                                                                                                        agent_share_agent += share_agent;
                                                                                                                                                                        agent_shrAmt += shrAmt;
                                                                                                                                                                        agent_fAmt += fAmt;

                                                                                                                                                                        // Super Agent
                                                                                                                                                                        agent_mComm_super += mComm_super;
                                                                                                                                                                        agent_sComm_super += sComm_super;
                                                                                                                                                                        agent_totComm_super += totComm_super;
                                                                                                                                                                        agent_netAmt_super += netAmt_super;
                                                                                                                                                                        agent_share_super += share_super;
                                                                                                                                                                        agent_shrAmt_super += shrAmt_super;
                                                                                                                                                                        agent_fAmt_super += fAmt_super;


                                                                                                                                                                        // Master
                                                                                                                                                                        agent_mComm_master += mComm_master;
                                                                                                                                                                        agent_sComm_master += sComm_master;
                                                                                                                                                                        agent_totComm_master += totComm_master;
                                                                                                                                                                        agent_netAmt_master += netAmt_master;
                                                                                                                                                                        agent_share_master += share_master;
                                                                                                                                                                        agent_shrAmt_master += shrAmt_master;
                                                                                                                                                                        agent_fAmt_master += fAmt_master;





                                                                                                                                                                        // Sub Admin
                                                                                                                                                                        agent_mComm_subadmin += mComm_subadmin;
                                                                                                                                                                        agent_sComm_subadmin += sComm_subadmin;
                                                                                                                                                                        agent_totComm_subadmin += totComm_subadmin;
                                                                                                                                                                        agent_netAmt_subadmin += netAmt_subadmin;
                                                                                                                                                                        agent_share_subadmin += share_subadmin;
                                                                                                                                                                        agent_shrAmt_subadmin += shrAmt_subadmin;
                                                                                                                                                                        agent_fAmt_subadmin += fAmt_subadmin;


                                                                                                                                                                        //  Admin
                                                                                                                                                                        agent_mComm_admin += mComm_admin;
                                                                                                                                                                        agent_sComm_admin += sComm_admin;
                                                                                                                                                                        agent_totComm_admin += totComm_admin;
                                                                                                                                                                        agent_netAmt_admin += netAmt_admin;
                                                                                                                                                                        agent_share_admin += share_admin;
                                                                                                                                                                        agent_shrAmt_admin += shrAmt_admin;
                                                                                                                                                                        agent_fAmt_admin += fAmt_admin;

                                                                                                                                                                        // start Footer SuperAgent
                                                                                                                                                                        //  SuperAgent
                                                                                                                                                                        superagent_mAmt += mAmt;
                                                                                                                                                                        superagent_sAmt += sAmt;
                                                                                                                                                                        superagent_totAmt += totAmt;
                                                                                                                                                                        superagent_mComm += mComm;
                                                                                                                                                                        superagent_sComm += sComm;
                                                                                                                                                                        superagent_totComm += totComm;
                                                                                                                                                                        superagent_netAmt += netAmt;
                                                                                                                                                                        superagent_share_agent += share_agent;
                                                                                                                                                                        superagent_shrAmt += shrAmt;
                                                                                                                                                                        superagent_fAmt += fAmt;
                                                                                                                                                                        // Super Agent
                                                                                                                                                                        superagent_mComm_super += mComm_super;
                                                                                                                                                                        superagent_sComm_super += sComm_super;
                                                                                                                                                                        superagent_totComm_super += totComm_super;
                                                                                                                                                                        superagent_netAmt_super += netAmt_super;
                                                                                                                                                                        superagent_share_super += share_super;
                                                                                                                                                                        superagent_shrAmt_super += shrAmt_super;
                                                                                                                                                                        superagent_fAmt_super += fAmt_super;
                                                                                                                                                                        // Master
                                                                                                                                                                        superagent_mComm_master += mComm_master;
                                                                                                                                                                        superagent_sComm_master += sComm_master;
                                                                                                                                                                        superagent_totComm_master += totComm_master;
                                                                                                                                                                        superagent_netAmt_master += netAmt_master;
                                                                                                                                                                        superagent_share_master += share_master;
                                                                                                                                                                        superagent_shrAmt_master += shrAmt_master;
                                                                                                                                                                        superagent_fAmt_master += fAmt_master;

                                                                                                                                                                        // Sub Admin
                                                                                                                                                                        superagent_mComm_subadmin += mComm_subadmin;
                                                                                                                                                                        superagent_sComm_subadmin += sComm_subadmin;
                                                                                                                                                                        superagent_totComm_subadmin += totComm_subadmin;
                                                                                                                                                                        superagent_netAmt_subadmin += netAmt_subadmin;
                                                                                                                                                                        superagent_share_subadmin += share_subadmin;
                                                                                                                                                                        superagent_shrAmt_subadmin += shrAmt_subadmin;
                                                                                                                                                                        superagent_fAmt_subadmin += fAmt_subadmin;
                                                                                                                                                                        //  Admin
                                                                                                                                                                        superagent_mComm_admin += mComm_admin;
                                                                                                                                                                        superagent_sComm_admin += sComm_admin;
                                                                                                                                                                        superagent_totComm_admin += totComm_admin;
                                                                                                                                                                        superagent_netAmt_admin += netAmt_admin;
                                                                                                                                                                        superagent_share_admin += share_admin;
                                                                                                                                                                        superagent_shrAmt_admin += shrAmt_admin;
                                                                                                                                                                        superagent_fAmt_admin += fAmt_admin;

                                                                                                                                                                        // Start Master footer
                                                                                                                                                                        // Master 
                                                                                                                                                                        master_mAmt += mAmt;
                                                                                                                                                                        master_sAmt += sAmt;
                                                                                                                                                                        master_totAmt += totAmt;
                                                                                                                                                                        master_mComm += mComm;
                                                                                                                                                                        master_sComm += sComm;
                                                                                                                                                                        master_totComm += totComm;
                                                                                                                                                                        master_netAmt += netAmt;
                                                                                                                                                                        master_share_agent += share_agent;
                                                                                                                                                                        master_shrAmt += shrAmt;
                                                                                                                                                                        master_fAmt += fAmt;
                                                                                                                                                                        // Super Agent
                                                                                                                                                                        master_mComm_super += mComm_super;
                                                                                                                                                                        master_sComm_super += sComm_super;
                                                                                                                                                                        master_totComm_super += totComm_super;
                                                                                                                                                                        master_netAmt_super += netAmt_super;
                                                                                                                                                                        master_share_super += share_super;
                                                                                                                                                                        master_shrAmt_super += shrAmt_super;
                                                                                                                                                                        master_fAmt_super += fAmt_super;
                                                                                                                                                                        // Master
                                                                                                                                                                        master_mComm_master += mComm_master;
                                                                                                                                                                        master_sComm_master += sComm_master;
                                                                                                                                                                        master_totComm_master += totComm_master;
                                                                                                                                                                        master_netAmt_master += netAmt_master;
                                                                                                                                                                        master_share_master += share_master;
                                                                                                                                                                        master_shrAmt_master += shrAmt_master;
                                                                                                                                                                        master_fAmt_master += fAmt_master;

                                                                                                                                                                        // Sub Admin
                                                                                                                                                                        master_mComm_subadmin += mComm_subadmin;
                                                                                                                                                                        master_sComm_subadmin += sComm_subadmin;
                                                                                                                                                                        master_totComm_subadmin += totComm_subadmin;
                                                                                                                                                                        master_netAmt_subadmin += netAmt_subadmin;
                                                                                                                                                                        master_share_subadmin += share_subadmin;
                                                                                                                                                                        master_shrAmt_subadmin += shrAmt_subadmin;
                                                                                                                                                                        master_fAmt_subadmin += fAmt_subadmin;
                                                                                                                                                                        //  Admin
                                                                                                                                                                        master_mComm_admin += mComm_admin;
                                                                                                                                                                        master_sComm_admin += sComm_admin;
                                                                                                                                                                        master_totComm_admin += totComm_admin;
                                                                                                                                                                        master_netAmt_admin += netAmt_admin;
                                                                                                                                                                        master_share_admin += share_admin;
                                                                                                                                                                        master_shrAmt_admin += shrAmt_admin;
                                                                                                                                                                        master_fAmt_admin += fAmt_admin;



                                                                                                                                                                        // Start Sub Admin footer
                                                                                                                                                                        // agnet 
                                                                                                                                                                        subAdmin_mAmt += mAmt;
                                                                                                                                                                        subAdmin_sAmt += sAmt;
                                                                                                                                                                        subAdmin_totAmt += totAmt;
                                                                                                                                                                        subAdmin_mComm += mComm;
                                                                                                                                                                        subAdmin_sComm += sComm;
                                                                                                                                                                        subAdmin_totComm += totComm;
                                                                                                                                                                        subAdmin_netAmt += netAmt;
                                                                                                                                                                        subAdmin_share_agent += share_agent;
                                                                                                                                                                        subAdmin_shrAmt += shrAmt;
                                                                                                                                                                        subAdmin_fAmt += fAmt;
                                                                                                                                                                        // Super Agent
                                                                                                                                                                        subAdmin_mComm_super += mComm_super;
                                                                                                                                                                        subAdmin_sComm_super += sComm_super;
                                                                                                                                                                        subAdmin_totComm_super += totComm_super;
                                                                                                                                                                        subAdmin_netAmt_super += netAmt_super;
                                                                                                                                                                        subAdmin_share_super += share_super;
                                                                                                                                                                        subAdmin_shrAmt_super += shrAmt_super;
                                                                                                                                                                        subAdmin_fAmt_super += fAmt_super;
                                                                                                                                                                        // Master
                                                                                                                                                                        subAdmin_mComm_master += mComm_master;
                                                                                                                                                                        subAdmin_sComm_master += sComm_master;
                                                                                                                                                                        subAdmin_totComm_master += totComm_master;
                                                                                                                                                                        subAdmin_netAmt_master += netAmt_master;
                                                                                                                                                                        subAdmin_share_master += share_master;
                                                                                                                                                                        subAdmin_shrAmt_master += shrAmt_master;
                                                                                                                                                                        subAdmin_fAmt_master += fAmt_master;

                                                                                                                                                                        // Sub Admin
                                                                                                                                                                        subAdmin_mComm_subadmin += mComm_subadmin;
                                                                                                                                                                        subAdmin_sComm_subadmin += sComm_subadmin;
                                                                                                                                                                        subAdmin_totComm_subadmin += totComm_subadmin;
                                                                                                                                                                        subAdmin_netAmt_subadmin += netAmt_subadmin;
                                                                                                                                                                        subAdmin_share_subadmin += share_subadmin;
                                                                                                                                                                        subAdmin_shrAmt_subadmin += shrAmt_subadmin;
                                                                                                                                                                        subAdmin_fAmt_subadmin += fAmt_subadmin;
                                                                                                                                                                        //  Admin
                                                                                                                                                                        subAdmin_mComm_admin += mComm_admin;
                                                                                                                                                                        subAdmin_sComm_admin += sComm_admin;
                                                                                                                                                                        subAdmin_totComm_admin += totComm_admin;
                                                                                                                                                                        subAdmin_netAmt_admin += netAmt_admin;
                                                                                                                                                                        subAdmin_share_admin += share_admin;
                                                                                                                                                                        subAdmin_shrAmt_admin += shrAmt_admin;
                                                                                                                                                                        subAdmin_fAmt_admin += fAmt_admin;

                                                                                                                                                                        // Start  Admin footer
                                                                                                                                                                        // agnet 
                                                                                                                                                                        admin_mAmt += mAmt;
                                                                                                                                                                        admin_sAmt += sAmt;
                                                                                                                                                                        admin_totAmt += totAmt;
                                                                                                                                                                        admin_mComm += mComm;
                                                                                                                                                                        admin_sComm += sComm;
                                                                                                                                                                        admin_totComm += totComm;
                                                                                                                                                                        admin_netAmt += netAmt;
                                                                                                                                                                        admin_share_agent += share_agent;
                                                                                                                                                                        admin_shrAmt += shrAmt;
                                                                                                                                                                        admin_fAmt += fAmt;
                                                                                                                                                                        // Super Agent
                                                                                                                                                                        admin_mComm_super += mComm_super;
                                                                                                                                                                        admin_sComm_super += sComm_super;
                                                                                                                                                                        admin_totComm_super += totComm_super;
                                                                                                                                                                        admin_netAmt_super += netAmt_super;
                                                                                                                                                                        admin_share_super += share_super;
                                                                                                                                                                        admin_shrAmt_super += shrAmt_super;
                                                                                                                                                                        admin_fAmt_super += fAmt_super;
                                                                                                                                                                        // Master
                                                                                                                                                                        admin_mComm_master += mComm_master;
                                                                                                                                                                        admin_sComm_master += sComm_master;
                                                                                                                                                                        admin_totComm_master += totComm_master;
                                                                                                                                                                        admin_netAmt_master += netAmt_master;
                                                                                                                                                                        admin_share_master += share_master;
                                                                                                                                                                        admin_shrAmt_master += shrAmt_master;
                                                                                                                                                                        admin_fAmt_master += fAmt_master;

                                                                                                                                                                        // Sub Admin
                                                                                                                                                                        admin_mComm_subadmin += mComm_subadmin;
                                                                                                                                                                        admin_sComm_subadmin += sComm_subadmin;
                                                                                                                                                                        admin_totComm_subadmin += totComm_subadmin;
                                                                                                                                                                        admin_netAmt_subadmin += netAmt_subadmin;
                                                                                                                                                                        admin_share_subadmin += share_subadmin;
                                                                                                                                                                        admin_shrAmt_subadmin += shrAmt_subadmin;
                                                                                                                                                                        admin_fAmt_subadmin += fAmt_subadmin;
                                                                                                                                                                        //  Admin
                                                                                                                                                                        admin_mComm_admin += mComm_admin;
                                                                                                                                                                        admin_sComm_admin += sComm_admin;
                                                                                                                                                                        admin_totComm_admin += totComm_admin;
                                                                                                                                                                        admin_netAmt_admin += netAmt_admin;
                                                                                                                                                                        admin_share_admin += share_admin;
                                                                                                                                                                        admin_shrAmt_admin += shrAmt_admin;
                                                                                                                                                                        admin_fAmt_admin += fAmt_admin;


                                                                                                                                                                        // footer  Grand Total
                                                                                                                                                                        // agent
                                                                                                                                                                        gTotal_mAmt += mAmt;
                                                                                                                                                                        gTotal_sAmt += sAmt;
                                                                                                                                                                        gTotal_totAmt += totAmt;
                                                                                                                                                                        gTotal_mComm += mComm;
                                                                                                                                                                        gTotal_sComm += sComm;
                                                                                                                                                                        gTotal_totComm += totComm;
                                                                                                                                                                        gTotal_netAmt += netAmt;
                                                                                                                                                                        gTotal_share_agent += share_agent;
                                                                                                                                                                        gTotal_shrAmt += shrAmt;
                                                                                                                                                                        gTotal_fAmt += fAmt;
                                                                                                                                                                        // Super Agent
                                                                                                                                                                        gTotal_mComm_super += mComm_super;
                                                                                                                                                                        gTotal_sComm_super += sComm_super;
                                                                                                                                                                        gTotal_totComm_super += totComm_super;
                                                                                                                                                                        gTotal_netAmt_super += netAmt_super;
                                                                                                                                                                        gTotal_share_super += share_super;
                                                                                                                                                                        gTotal_shrAmt_super += shrAmt_super;
                                                                                                                                                                        gTotal_fAmt_super += fAmt_super;
                                                                                                                                                                        // Master
                                                                                                                                                                        gTotal_mComm_master += mComm_master;
                                                                                                                                                                        gTotal_sComm_master += sComm_master;
                                                                                                                                                                        gTotal_totComm_master += totComm_master;
                                                                                                                                                                        gTotal_netAmt_master += netAmt_master;
                                                                                                                                                                        gTotal_share_master += share_master;
                                                                                                                                                                        gTotal_shrAmt_master += shrAmt_master;
                                                                                                                                                                        gTotal_fAmt_master += fAmt_master;

                                                                                                                                                                        // Sub Admin
                                                                                                                                                                        gTotal_mComm_subadmin += mComm_subadmin;
                                                                                                                                                                        gTotal_sComm_subadmin += sComm_subadmin;
                                                                                                                                                                        gTotal_totComm_subadmin += totComm_subadmin;
                                                                                                                                                                        gTotal_netAmt_subadmin += netAmt_subadmin;
                                                                                                                                                                        gTotal_share_subadmin += share_subadmin;
                                                                                                                                                                        gTotal_shrAmt_subadmin += shrAmt_subadmin;
                                                                                                                                                                        gTotal_fAmt_subadmin += fAmt_subadmin;
                                                                                                                                                                        //  Admin
                                                                                                                                                                        gTotal_mComm_admin += mComm_admin;
                                                                                                                                                                        gTotal_sComm_admin += sComm_admin;
                                                                                                                                                                        gTotal_totComm_admin += totComm_admin;
                                                                                                                                                                        gTotal_netAmt_admin += netAmt_admin;
                                                                                                                                                                        gTotal_share_admin += share_admin;
                                                                                                                                                                        gTotal_shrAmt_admin += shrAmt_admin;
                                                                                                                                                                        gTotal_fAmt_admin += fAmt_admin;


                                                                                                                                                                        return (
                                                                                                                                                                            <tr>

                                                                                                                                                                                {/* Agent */}
                                                                                                                                                                                <td>{c_row.name2}</td>
                                                                                                                                                                                <td> {parseFloat(mAmt).toFixed(2)} </td>
                                                                                                                                                                                <td> {parseFloat(sAmt).toFixed(2)} </td>
                                                                                                                                                                                <td>{parseFloat(totAmt).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(mComm).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(sComm).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(totComm).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(netAmt).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(shrAmt).toFixed(2)}</td>
                                                                                                                                                                                <td>0.00</td>
                                                                                                                                                                                <td>{parseFloat(fAmt).toFixed(2)}</td>


                                                                                                                                                                                {/* Super agent */}
                                                                                                                                                                                <td>{parseFloat(mComm_super).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(sComm_super).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(totComm_super).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(netAmt_super).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(shrAmt_super).toFixed(2)}</td>
                                                                                                                                                                                <td>0.00</td>
                                                                                                                                                                                <td>{parseFloat(fAmt_super).toFixed(2)}</td>

                                                                                                                                                                                {/* master */}
                                                                                                                                                                                <td>{parseFloat(mComm_master).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(sComm_master).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(totComm_master).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(netAmt_master).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(shrAmt_master).toFixed(2)}</td>
                                                                                                                                                                                <td>0.00</td>
                                                                                                                                                                                <td>{parseFloat(fAmt_master).toFixed(2)}</td>



                                                                                                                                                                                {/* Sub Admin */}
                                                                                                                                                                                <td>{parseFloat(mComm_subadmin).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(sComm_subadmin).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(totComm_subadmin).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(netAmt_subadmin).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(shrAmt_subadmin).toFixed(2)}</td>
                                                                                                                                                                                <td>0.00</td>
                                                                                                                                                                                <td>{parseFloat(fAmt_subadmin).toFixed(2)}</td>

                                                                                                                                                                                {/* Admin */}
                                                                                                                                                                                <td> {parseFloat(mComm_admin).toFixed(2)} </td>
                                                                                                                                                                                <td>{parseFloat(sComm_admin).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(totComm_admin).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(netAmt_admin).toFixed(2)}</td>
                                                                                                                                                                                <td>{parseFloat(shrAmt_admin).toFixed(2)}</td>
                                                                                                                                                                                <td>0.00</td>
                                                                                                                                                                                <td>{parseFloat(fAmt_admin).toFixed(2)}</td>
                                                                                                                                                                            </tr>
                                                                                                                                                                        );

                                                                                                                                                                    })
                                                                                                                                                                }

                                                                                                                                                            </tbody>
                                                                                                                                                        </table>

                                                                                                                                                    </div>

                                                                                                                                                    <div className="card-footer">
                                                                                                                                                        <div className='table table-striped table-bordered'>
                                                                                                                                                            <tfoot className='bg-gradient-white'>

                                                                                                                                                                <tr>
                                                                                                                                                                    {/*  Agent Plisminus */}
                                                                                                                                                                    <th>TOTAL</th>
                                                                                                                                                                    <th>{parseFloat(agent_mAmt).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_sAmt).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_totAmt).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_mComm).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_sComm).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_totComm).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_netAmt).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_shrAmt).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_fAmt).toFixed(2)}</th>
                                                                                                                                                                    {/* Super Agent Plisminus */}
                                                                                                                                                                    <th>{parseFloat(agent_mComm_super).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_sComm_super).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_totComm_super).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_netAmt_super).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_shrAmt_super).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_fAmt_super).toFixed(2)}</th>
                                                                                                                                                                    {/* Master Plisminus */}
                                                                                                                                                                    <th>{parseFloat(agent_mComm_master).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_sComm_master).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_totComm_master).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_netAmt_master).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_shrAmt_master).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_fAmt_master).toFixed(2)}</th>

                                                                                                                                                                    {/* Sub Admin Plisminus */}
                                                                                                                                                                    <th>{parseFloat(agent_mComm_subadmin).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_sComm_subadmin).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_totComm_subadmin).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_netAmt_subadmin).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_shrAmt_subadmin).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_fAmt_subadmin).toFixed(2)}</th>
                                                                                                                                                                    {/* Admin Plisminus */}
                                                                                                                                                                    <th>{parseFloat(agent_mComm_admin).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_sComm_admin).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_totComm_admin).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_netAmt_admin).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_shrAmt_admin).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                                                                    <th>{parseFloat(agent_fAmt_admin).toFixed(2)}</th>

                                                                                                                                                                </tr>
                                                                                                                                                            </tfoot>

                                                                                                                                                        </div>
                                                                                                                                                    </div>

                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        );

                                                                                                                                    })}
                                                                                                                                </div>

                                                                                                                                <div className="card-footer">

                                                                                                                                    <table className="table table-striped table-bordered">
                                                                                                                                        <thead className="bg-gradient-white">
                                                                                                                                            <tr>
                                                                                                                                                {/* agent total */}
                                                                                                                                                <th>SA TOTAL</th>
                                                                                                                                                <th>{parseFloat(superagent_mAmt).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_sAmt).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_totAmt).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_mComm).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_sComm).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_totComm).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_netAmt).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_shrAmt).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_fAmt).toFixed(2)}</th>
                                                                                                                                                {/* Super agent total */}
                                                                                                                                                <th>{parseFloat(superagent_mComm_super).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_sComm_super).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_totComm_super).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_netAmt_super).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_shrAmt_super).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_fAmt_super).toFixed(2)}</th>
                                                                                                                                                {/* Master */}
                                                                                                                                                <th>{parseFloat(superagent_mComm_master).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_sComm_master).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_totComm_master).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_netAmt_master).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_shrAmt_master).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_fAmt_master).toFixed(2)}</th>

                                                                                                                                                {/* Sub Admin */}
                                                                                                                                                <th>{parseFloat(superagent_mComm_subadmin).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_sComm_subadmin).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_totComm_subadmin).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_netAmt_subadmin).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_shrAmt_subadmin).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_fAmt_subadmin).toFixed(2)}</th>

                                                                                                                                                {/* Sub Admin */}
                                                                                                                                                <th>{parseFloat(superagent_mComm_admin).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_sComm_admin).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_totComm_admin).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_netAmt_admin).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_shrAmt_admin).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                                                <th>{parseFloat(superagent_fAmt_admin).toFixed(2)}</th>

                                                                                                                                            </tr>
                                                                                                                                        </thead>
                                                                                                                                    </table>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    );

                                                                                                                })}
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <div className="card-footer">

                                                                                                                    <table className="table table-striped table-bordered">
                                                                                                                        <thead className="bg-gradient-white">
                                                                                                                            {/* agent total */}
                                                                                                                            <th>M TOTAL</th>
                                                                                                                            <th>{parseFloat(master_mAmt).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_sAmt).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_totAmt).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_mComm).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_sComm).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_totComm).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_netAmt).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_shrAmt).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_fAmt).toFixed(2)}</th>
                                                                                                                            {/* Super agent total */}
                                                                                                                            <th>{parseFloat(master_mComm_super).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_sComm_super).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_totComm_super).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_netAmt_super).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_shrAmt_super).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_fAmt_super).toFixed(2)}</th>
                                                                                                                            {/* Master */}
                                                                                                                            <th>{parseFloat(master_mComm_master).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_sComm_master).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_totComm_master).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_netAmt_master).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_shrAmt_master).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_fAmt_master).toFixed(2)}</th>

                                                                                                                            {/* Sub Admin */}
                                                                                                                            <th>{parseFloat(master_mComm_subadmin).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_sComm_subadmin).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_totComm_subadmin).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_netAmt_subadmin).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_shrAmt_subadmin).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_fAmt_subadmin).toFixed(2)}</th>

                                                                                                                            {/* Sub Admin */}
                                                                                                                            <th>{parseFloat(master_mComm_admin).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_sComm_admin).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_totComm_admin).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_netAmt_admin).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_shrAmt_admin).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                                            <th>{parseFloat(master_fAmt_admin).toFixed(2)}</th>
                                                                                                                        </thead>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </div>

                                                                                    <div>
                                                                                        <div className="card-footer">

                                                                                            <table className="table table-striped table-bordered">
                                                                                                <thead className="bg-gradient-white">
                                                                                                    {/* agent total */}
                                                                                                    <th>SUB A TOTAL</th>
                                                                                                    <th>{parseFloat(subAdmin_mAmt).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_sAmt).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_totAmt).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_mComm).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_sComm).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_totComm).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_netAmt).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_shrAmt).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_fAmt).toFixed(2)}</th>
                                                                                                    {/* Super agent total */}
                                                                                                    <th>{parseFloat(subAdmin_mComm_super).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_sComm_super).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_totComm_super).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_netAmt_super).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_shrAmt_super).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_fAmt_super).toFixed(2)}</th>
                                                                                                    {/* Master */}
                                                                                                    <th>{parseFloat(subAdmin_mComm_master).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_sComm_master).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_totComm_master).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_netAmt_master).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_shrAmt_master).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_fAmt_master).toFixed(2)}</th>

                                                                                                    {/* Sub Admin */}
                                                                                                    <th>{parseFloat(subAdmin_mComm_subadmin).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_sComm_subadmin).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_totComm_subadmin).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_netAmt_subadmin).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_shrAmt_subadmin).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_fAmt_subadmin).toFixed(2)}</th>

                                                                                                    {/* Sub Admin */}
                                                                                                    <th>{parseFloat(subAdmin_mComm_admin).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_sComm_admin).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_totComm_admin).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_netAmt_admin).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_shrAmt_admin).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                                    <th>{parseFloat(subAdmin_fAmt_admin).toFixed(2)}</th>
                                                                                                </thead>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );

                                                                    })}

                                                                </div>

                                                                <div>
                                                                    <div className="card-footer">

                                                                        <table className="table table-striped table-bordered">
                                                                            <thead className="bg-gradient-white">
                                                                                {/* agent total */}
                                                                                <th>AD TOTAL</th>
                                                                                <th>{parseFloat(gTotal_mAmt).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_mAmt).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_sAmt).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_totAmt).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_mComm).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_sComm).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_totComm).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_netAmt).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_shrAmt).toFixed(2)}</th>
                                                                                <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_fAmt).toFixed(2)}</th>
                                                                                {/* Super agent total */}
                                                                                <th>{parseFloat(gTotal_mComm_super).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_sComm_super).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_totComm_super).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_netAmt_super).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_shrAmt_super).toFixed(2)}</th>
                                                                                <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_fAmt_super).toFixed(2)}</th>
                                                                                {/* Master */}
                                                                                <th>{parseFloat(gTotal_mComm_master).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_sComm_master).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_totComm_master).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_netAmt_master).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_shrAmt_master).toFixed(2)}</th>
                                                                                <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_fAmt_master).toFixed(2)}</th>

                                                                                {/* Sub Admin */}
                                                                                <th>{parseFloat(gTotal_mComm_subadmin).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_sComm_subadmin).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_totComm_subadmin).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_netAmt_subadmin).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_shrAmt_subadmin).toFixed(2)}</th>
                                                                                <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_fAmt_subadmin).toFixed(2)}</th>

                                                                                {/* Sub Admin */}
                                                                                <th>{parseFloat(gTotal_mComm_admin).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_sComm_admin).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_totComm_admin).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_netAmt_admin).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_shrAmt_admin).toFixed(2)}</th>
                                                                                <th>{parseFloat(0.00).toFixed(2)}</th>
                                                                                <th>{parseFloat(gTotal_fAmt_admin).toFixed(2)}</th>
                                                                            </thead>
                                                                        </table>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                        </div>
                                                    );
                                                })}


                                            </div>




                                        </div>

                                    </div>

                                </div>
                                {/* end */}
                            </div>
                        </section>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Msmatchreport;
