import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import Util from '../../../../libs/Util';
import moment from "moment";


const DisplaySessionBets = () => {


    const cookies = new Cookies();
    const { id } = useParams();


    const [allClient, setAllClient] = useState([]);
    const [fancyBetsList, setFancyBetsList] = useState([]);
    const [fancy, setFancy] = useState({});

    const [clientIds, setClientIds] = useState("ALL");
    const [sessionIds, setSessionIds] = useState("ALL");

    const [declareRun, setDeclareRun] = useState("Declare @ 0");
    const [plusMinus, setPlusMinus] = useState("0");
    const [amtTotal, setAmtTotal] = useState("0.00");
    const [runPos, setRunPos] = useState([]);


    useEffect(() => {
        fetchClient("ALL", "ALL");
    }, []);


    const fetchClient = async (sessi, cli) => {

        let response = await Util.formSubmit(`${global.MainClient}/cricket/show_fancy_bets`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "matchId": id,
            "clientId": cli,
            "sessionId": sessi
        });

        if (response.status == "TRUE") {
            setAllClient(response.clients)
            setFancyBetsList(response.data.data)
            setFancy(response.data.fancy);
            setDeclareRun(response.data.declareRun);
            setPlusMinus(response.data.plusMinus);
            setRunPos(response.data.runpos);
            setAmtTotal(response.data.amtTotal);
        }
    }



    const handleSession = (event) => {
        setSessionIds(event.target.value)
        // fetchClient(event.target.value, clientIds);

    }

    const handleClient = (event) => {
        setClientIds(event.target.value)
        // fetchClient(sessionIds, event.target.value);
    }



    const handleForm = async (e) => {
        e.preventDefault();
        fetchClient(sessionIds, clientIds);
    }

    return (
        <div className='content-wrapper'>
            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Session Bets</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section class="content">
                <div class="container-fluid ">
                    <div class="row">
                        <div class="col-12">


                            <div class="card">


                                <form onSubmit={handleForm.bind(this)} id="myForm" method="post">

                                    <div class="card-header ">


                                        <div class="form-row">

                                            <table class="table table-bordered table-striped">
                                                <thead class="text-bold">

                                                    <tr>
                                                        <th>Client</th>
                                                        <th class="col-lg-4" style={{ minWidth: 240 }}>
                                                            <select class="form-control custom-select" required="" id="session" placeholder="Select Client" name="remark" onChange={handleClient}>
                                                                <option value="ALL">ALL Client</option>
                                                                {allClient.map(({ client_id, name }) => <option value={client_id}>{client_id} {name}</option>)}
                                                            </select>
                                                        </th>
                                                        <th>

                                                        </th>

                                                        <th>Declare @ {declareRun}</th>


                                                    </tr>


                                                    <tr>
                                                        <th>Session</th>
                                                        <th class="col-lg-4" style={{ minWidth: 240 }}>
                                                            <select class="form-control custom-select" required="" id="session" placeholder="Select Client" name="remark" onChange={handleSession}>
                                                                <option value="ALL">All Sessions</option>
                                                                {/* {Object.keys(fancy).map((key) => <option value={key}>{fancy[key]}</option>)} */}
                                                                {
                                                                    Object.keys(fancy).map(function (row1) {
                                                                        return (
                                                                            <option value={row1}>{fancy[row1]}</option>
                                                                        );
                                                                    })
                                                                }
                                                            </select>
                                                        </th>
                                                        <th>
                                                            <input type="submit" class="btn-primary " id="btn" name="submit" value="View" />
                                                        </th>

                                                        <th class={plusMinus > 0 ? "text-blue" : "text-blue"}>{plusMinus}</th>

                                                    </tr>


                                                </thead>
                                            </table>


                                        </div>


                                    </div>


                                    <div class="card-body">
                                        <div>



                                            <div class="row">

                                                <div class="col-md-10">
                                                    <table class="table table-striped table-bordered">

                                                        <thead>

                                                            <tr>
                                                                <th>#</th>
                                                                <th>Client</th>
                                                                <th>Session</th>
                                                                <th>Rate</th>
                                                                <th>Amount</th>
                                                                <th>Runs</th>
                                                                <th style={{ width: 40 }}>Mode</th>
                                                                <th>No</th>
                                                                <th>Yes</th>
                                                                <th>Date &amp; Time</th>
                                                            </tr>
                                                            <tr style={{ 'text-align': 'center' }}>
                                                                <th className='text-indigo' colSpan={3}>Total Amount</th>
                                                                <th colSpan={4}>{amtTotal}</th>
                                                                <th className='text-blue' colSpan={3}>0.00</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                fancyBetsList.map((row, i) => {
                                                                    let j = ++i;
                                                                    return (
                                                                        <tr>
                                                                            <td>{j}</td>
                                                                            <td>{row.client_id} {row.name}</td>
                                                                            <td>{fancy[row.Session_id]}</td>
                                                                            <td>{row.bitrate}</td>
                                                                            <td>{Math.abs(row.Amount)}</td>
                                                                            <td>{row.run}</td>
                                                                            <td>{row.Mode}</td>
                                                                            <td>{row.Amount1}</td>
                                                                            <td>{row.Amount2}</td>
                                                                            <td>{row.datetime}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div class="col-md-2">
                                                    <table class="table table-bordered table-striped ">

                                                        <thead>
                                                            <tr>
                                                                <th>RUNS</th>
                                                                <th>Amount</th>
                                                            </tr>

                                                        </thead>

                                                        <tbody>
                                                            {
                                                                runPos.map((row, i) => {
                                                                    let j = ++i;
                                                                    return (
                                                                        <tr>
                                                                            <td style={{ color: row.position > 0 ? "blue" : "red" }}>{row.run}</td>
                                                                            <td style={{ color: row.position > 0 ? "blue" : "red" }}>{row.position}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>



                                        </div>


                                    </div>
                                </form>

                            </div>


                        </div>
                    </div>
                </div >
            </section >
        </div >
    );
}

export default DisplaySessionBets;
