import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formHelper } from "../OLDconst/FormConst";
import Util from "../libs/Util";
import { useSelector } from "react-redux";

const MainEditClientForm = (props) => {
  let cookies = new Cookies();
  const { id } = useParams();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.session);
  const clientIp = useSelector((state) => state.ip);

  const [formField, setFormField] = useState({
    name: "",
    reference: "",
    myCoin: "",
    coins: "",
    password: "",
    matchShare: "",
    myMatchShare: "",
    mobShare: "",
    myMobShare: "",
    matchComm: "0",
    myMatchComm: "",
    sessComm: "0",
    mySessComm: "",
    casinoShare: "0",
    myCasinoShare: "0",
    casinoComm: "0",
    myCasinoComm: "0",
    matkaShare: "0",
    myMatkaShare: "0",
    matkaComm: "0",
    myMatkaComm: "0",
    vShare: "false",
    token: cookies.get(global.CookiesVariable),
    mUserId: id,
    auth_for: id,
    flatShare: "",
    clientIp: clientIp,
    clientOnShare: "",
    agentShareonClient: "",
  });

  const [shcm, setShcm] = useState([]);
  const [defaultShcm, setDefaultShcm] = useState([]);
  const [errorShcm, setErrorShcm] = useState([]);



  const [flatShareStatus, setFlatShareStatus] = useState(null);
  const [formPreData] = useState(formHelper[props.clientType]);
  const [userMain, setUserMain] = useState(null);
  const [doesEditForm] = useState(props.type);

  useEffect(() => {
    fetchDetails();
  }, []);

  const handleGeneratePassword = () =>
    setFormField({ ...formField, password: generatePassword() });

  const handleField = (e) =>
    setFormField((prev) => ({ ...prev, [e.target.id]: e.target.value }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormField((formField) => ({
      ...formField,
      token: cookies.get(global.CookiesVariable),
      mUserId: userMain,
      auth_for: id,
      
    }));

    let retData = await Util.formSubmit(
      formPreData.editLink,
      "POST",
      {...formField,shareComm: shcm}
    );

    if (retData.userid != "undefined") navigate(formPreData.redirectLink);
  };

  const auther = async (parentId) => {
    setUserMain(id);

    let autho = await Util.formSubmit(formPreData.authUrl, "POST", {
      token: cookies.get(global.CookiesVariable),
      auth_for: parentId,
    });

    setFormField((formField) => ({
      ...formField,
      myCoin: autho.coins,
      myMatchShare: autho.share,
      myCasinoShare: autho.casinoShare,
      myMatkaShare: autho.matkaShare,
      myMobShare: autho.mobShare,
      myMatchComm: autho.mcomm,
      mySessComm: autho.scomm,
      myCasinoComm: autho.casinoComm,
      myMatkaComm: autho.matkaComm,
      flatShare: autho.flatShare,
    }));
  };

  const fetchDetails = async () => {
    let response = await Util.formSubmit(
      formPreData.editClientDetails,
      "POST",
      {
        token: cookies.get(global.CookiesVariable),
        auth_for: id,
      }
    );

    if (response.status == "TRUE") {
      await auther(response.data.parent[0].userid);

      let res = response.data.self[0];
      let parent = response.data.parent[0];

      if (props.clientType === "agent") {
        setFormField((formField) => ({
          ...formField,
          name: res.username,
          reference: res.reference,
          password: res.password,
          matchShare: res.m_share,
          casinoShare: res.ca_share,
          matkaShare: res.matka_share,
          matchComm: res.m_comm,
          casinoComm: res.ca_comm,
          matkaComm: res.matka_comm,
          mobShare: res.mob_share,
          sessComm: res.s_comm,
          agentShareonClient: res.m_share,
          flatShare: res.flatShare,
        }));
      } else {
        setFormField((formField) => ({
          ...formField,
          name: res.username,
          reference: res.reference,
          password: res.password,
          matchShare: res.m_share,
          matkaShare: res.matka_share,
          casinoShare: res.ca_share,
          matchComm: res.m_comm,
          casinoComm: res.ca_comm,
          matkaComm: res.matka_comm,
          mobShare: res.mob_share,
          sessComm: res.s_comm,
          agentShareonClient: res.m_share,
        }));
      }

      setShcm([...res.shcm]);
      setDefaultShcm([...parent.shcm]);

      setErrorShcm(
        Array.from(Array(parent.shcm.length), (_, i) => ({
          errorComm: false,
          msgComm: "",
          errorShare: false,
          msgShare: "",
        }))
      );

    }
  };

  const handleCommissionType = (e) => {
    if (e.target.value === "NO_COMM") {
      setFormField((formField) => ({
        ...formField,
        matchComm: 0,
        sessComm: 0,
        casinoComm: 0,
        matkaComm: 0,
      }));
    }

    if (e.target.value === "BET_BY_BET") {
      setFormField((formField) => ({
        ...formField,
        matchComm: formField.myMatchComm,
        sessComm: formField.mySessComm,
        casinoComm: formField.myCasinoComm,
        matkaComm: formField.myMatkaComm,
      }));
    }
  };

  function generatePassword() {
    let digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
  }

  const changeComm = (index, eve) => {
    let temp_shcm = shcm;
    let error_shcm = errorShcm;
    let freshComm = isNaN(eve.target.value) ? 0 : eve.target.value;
    let prevComm = defaultShcm[index].comm;

    if (parseFloat(freshComm) > parseFloat(prevComm) || parseFloat(freshComm) < 0) {
      //alert("Comm should not more than " + prevComm);
      error_shcm[index].errorComm = true;
      error_shcm[index].msgComm = "Comm should be between 0 to  " + prevComm;
      freshComm = prevComm;
    } else {
      error_shcm[index].errorComm = false;
      error_shcm[index].msgComm = "";
    }

    temp_shcm[index].comm = freshComm;
    temp_shcm[index].j_rate = parseInt(
      100 - (temp_shcm[index].j_rate / 100) * freshComm
    ); //.toFixed(1);
    temp_shcm[index].h_rate = parseFloat(
      10 - (temp_shcm[index].h_rate / 100) * freshComm
    ).toFixed(1);

    //console.log(temp_shcm);

    setShcm([...temp_shcm]);
    setErrorShcm([...errorShcm]);
  };

  const changeShare = (index, eve) => {
    let temp_shcm = shcm;
    let error_shcm = errorShcm;

    let prevShare = defaultShcm[index].share;
    let freshShare = eve.target.value;

    // console.log(freshShare,prevShare);
    // console.log(parseFloat(freshShare) > parseFloat(prevShare));
    if ((parseFloat(freshShare) > parseFloat(prevShare)) || parseFloat(freshShare) < 0) {
      error_shcm[index].errorShare = true;
      error_shcm[index].msgShare = "Share should not more than " + prevShare;

      freshShare = prevShare;
    } else {
      error_shcm[index].errorShare = false;
      error_shcm[index].msgShare = "";
    }

    temp_shcm[index].share = freshShare;

    //  console.log(temp_shcm);

    setShcm([...temp_shcm]);
    setErrorShcm([...errorShcm]);
  };

  return (
    <div className="content-wrapper" style={{ minHeight: 856.413 }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{`${doesEditForm != undefined ? "" : "Create"} ${
                formPreData.downLine
              }`}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">New Client</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">General</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      data-toggle="tooltip"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="code">Code</label>
                    <input
                      type="text"
                      id="code"
                      placeholder
                      readOnly
                      className="form-control"
                      name="code"
                      value={id}
                      defaultValue={id}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      placeholder="Name"
                      required
                      value={formField.name}
                      onChange={handleField}
                    />
                    <small className="form-text text-muted">
                      Minimum 3 Letters Required *
                    </small>
                  </div>

                  <div class="form-group">
                    <label for="name">Reference</label>
                    <input
                      type="text"
                      id="reference"
                      class="form-control"
                      placeholder="Reference"
                      required
                      name="reference"
                      value={formField.reference}
                      onChange={handleField}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div className="input-group ">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Password"
                        id="password"
                        required
                        name="password"
                        defaultValue={formField.password}
                        value={formField.password}
                        onChange={handleField}
                      />
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-info btn-flat"
                          onClick={handleGeneratePassword}
                        >
                          Generate Password
                        </button>
                      </span>
                    </div>
                  </div>

                  {props.clientType === "agent" ? (
                    <div className="form-group">
                      <label htmlFor="flatShare">Flat Share</label>
                      <select
                        id="flatShare"
                        className="form-control"
                        onChange={handleField}
                      >
                        <option
                          value="YES"
                          selected={
                            formField.flatShare === "YES" ? true : false
                          }
                        >
                          YES
                        </option>
                        <option
                          value="NO"
                          selected={formField.flatShare === "NO" ? true : false}
                        >
                          NO
                        </option>
                      </select>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card card-warning">
                <div className="card-header">
                  <h3 className="card-title">Share and Commission</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      data-toggle="tooltip"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                <div className="row">
                    <div className="col-3">
                      <label> Game Name </label>
                    </div>
                    <div className="col-3">
                      <label>J. Rate</label>
                    </div>
                    <div className="col-2">
                      <label>H. Rate</label>
                    </div>
                    <div className="col-2">
                      <label>Share</label>
                    </div>
                    <div className="col-2">
                      <label>Comm.</label>
                    </div>
                  </div>
                  {shcm.length > 0 &&
                    shcm.map((row, i) => {
                      return (
                        <>
                          <div className="row" key={i}>
                            <div className="col-3">
                              <input
                                className="form-control"
                                readOnly
                                value={row.matka_name}
                              />
                            </div>
                            <div className="col-3">
                              <input
                                className="form-control"
                                value={row.j_rate}
                                readOnly
                              />
                            </div>
                            <div className="col-2">
                              <input
                                className="form-control"
                                value={row.h_rate}
                                readOnly
                              />
                            </div>
                            <div className="col-2">
                              <input
                                className="form-control"
                                defaultValue={row.share}
                                onChange={(event) => {
                                  changeShare(i, event);
                                }}
                              />
                            </div>
                            <div className="col-2">
                              <input
                                className="form-control"
                                defaultValue={row.comm}
                                onChange={(event) => {
                                  changeComm(i, event);
                                }}
                              />
                            </div>
                          </div>
                          {(errorShcm[i]?.errorComm ||
                            errorShcm[i]?.errorShare) && (
                            <div className="row">
                              <div className="col-md-12">
                                <span
                                  className="error"
                                  style={{ color: "red" }}
                                >
                                  {errorShcm[i]?.msgComm +
                                    "," +
                                    errorShcm[i]?.msgShare}
                                </span>
                              </div>
                            </div>
                          )}
                          <br />
                        </>
                      );
                    })}
               
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <a href="/client" className="btn btn-secondary">
                Cancel
              </a>
              <button
                type="submit"
                className="btn btn-success float-right"
                id="btn"
              >
                Update {formPreData.downLine}
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default MainEditClientForm;
