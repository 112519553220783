import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate, Link } from 'react-router-dom';
import Util from '../libs/Util';
import { setSession } from '../appRedux/actions/Auth';
import { useDispatch, useSelector } from 'react-redux';
import ls from 'local-storage';

const Header = () => {

    const cookies = new Cookies();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector(state => state.session);

    useEffect(() => {

        isUser();
        let userTimeOut = setInterval(() => { isUser(); }, 15000);

        return () => {
            clearInterval(userTimeOut);
        };

    }, []);


    const isUser = async () => {

        let userinfo = await Util.formSubmit(`${global.MainClient}/user`, "POST", { "token": cookies.get(global.CookiesVariable) });
        if (userinfo.status === "TRUE") {
            ls.set("session", true)
            dispatch(setSession(userinfo.data))
        } else {
            cookies.remove(global.CookiesVariable, { path: '/' });
            navigation('/login')
            ls.set("session", null)
        }

    }



    const handleLogout = async () => {
        cookies.remove(global.CookiesVariable, { path: "/" });
        navigation('/login')
    }


    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#" role="button">
                        <i className="fas fa-bars"></i>
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown user-menu">
                    <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">

                        <img src="https://adminlte.io/themes/v3/dist/img/user2-160x160.jpg" class="user-image img-circle elevation-2" alt="User Image" />
                        <span class="d-none d-md-inline">{userData.userid}</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">

                        <li class="user-header bg-primary">
                            <img src="https://adminlte.io/themes/v3/dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image" />
                            <p>{userData?.username}</p>
                        </li>

                        <li class="user-footer">
                            <Link to="/profile" class="btn btn-default btn-flat">Profile</Link>
                            <a href="#" class="btn btn-default btn-flat float-right" onClick={() => handleLogout()}>
                                Sign out
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
}

export default Header;
