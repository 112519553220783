import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import "../config/GlobalVariable";
import { Modal } from "react-bootstrap";
import { dashboardConst } from '../OLDconst/DashboardConst';
import { setIp } from '../appRedux/actions/Auth';
import axios from 'axios'
import { useDispatch } from 'react-redux';




const Dashboard = () => {


    const dispatch = useDispatch();


    const [show, setShow] = useState(false);

    const [modelType, setModelType] = useState({
        type: 'master',
        count: '0'
    });


    useEffect(() => {
        getMyIp4();
    }, []);

    const getMyIp4 = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        dispatch(setIp(res.data.IPv4));
    }


    const handleClose = () => {
        setShow(false)
    };
    const handleShow = (type) => {
        setModelType({ ...modelType, type: type });
        setShow(true);
    };

    return (
        <div className='content-wrapper'>
            {/* Header */}

            <br />
            <section class="content">
                <div class="container-fluid">
                    <div class="row">

                        {/* <div class="col-lg-3 col-6" onclick={() => { showModel('master') }}> */}
                        <div class="col-lg-3 col-6" onClick={() => handleShow('master')}>
                            <div class="small-box bg-primary">
                                <div class="inner">
                                    <h3>1</h3>
                                    <p>Master Details</p>
                                </div>
                                <div class="icon">
                                    <i class="ion ion-bag"></i>
                                </div>
                                <Link to="#" class="small-box-footer">More Info <i class="fas fa-arrow-circle-right"></i></Link>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6" onClick={() => handleShow('game')}>
                            <div class="small-box bg-success">
                                <div class="inner">
                                    <h3>2</h3>
                                    <p>Games Details</p>
                                </div>
                                <div class="icon">
                                    <i class="ion ion-bag"></i>
                                </div>
                                <Link to="#" class="small-box-footer">More Info <i class="fas fa-arrow-circle-right"></i></Link>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6" onClick={() => handleShow('cash')} >

                            <div class="small-box bg-warning">
                                <div class="inner">
                                    <h3>3</h3>
                                    <p>Cash Transaction</p>


                                </div>
                                <div class="icon">
                                    <i class="ion ion-bag"></i>
                                </div>
                                <Link to="#" class="small-box-footer">More Info <i class="fas fa-arrow-circle-right"></i></Link>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6" onClick={() => handleShow('ledger')}>

                            <div class="small-box bg-danger">
                                <div class="inner">
                                    <h3>4</h3>
                                    <p>Ledger Details</p>


                                </div>
                                <div class="icon">
                                    <i class="ion ion-bag"></i>
                                </div>
                                <Link to="#" class="small-box-footer">More Info <i class="fas fa-arrow-circle-right"></i></Link>
                            </div>
                        </div>




                    </div>




                    <Modal show={show} onHide={handleClose}>
                        <Modal.Body>
                            <div className='row'>
                                {
                                    dashboardConst[modelType.type].map((row, i) => {
                                        let k = i;
                                        return (
                                            <div class="col-lg-6 col-6">
                                                <Link to={row.url}>
                                                    <div class={`small-box ${row.color}`}>
                                                        <div class="inner">
                                                            <h3>{++k}</h3>
                                                            <p>{row.name}</p>
                                                        </div>
                                                        <div class="icon">
                                                            <i class="ion ion-bag"></i>
                                                        </div>
                                                        <Link to="#" class="small-box-footer">More Info <i class="fas fa-arrow-circle-right"></i></Link>
                                                    </div>
                                                </Link>
                                            </div>
                                        );
                                    })
                                }

                            </div>
                        </Modal.Body>
                    </Modal>





                </div>
            </section>

        </div>
    );
}

export default Dashboard;
