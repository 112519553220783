import React from "react";
import { useLocation, Routes, Route, Navigate } from "react-router-dom";

// Slef Page
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Setting from "./pages/Setting";

// Start Table Super Details
import TableSubAdmin from "./pages/superDetails/TableSubAdmin";
import TableMaster from "./pages/superDetails/TableMaster";
import TableSuperAgent from "./pages/superDetails/TableSuperAgent";
import TableAgent from "./pages/superDetails/TableAgent";
import TableClient from "./pages/superDetails/TableClient";

// start Form
import FormSubAdmin from "./pages/superDetails/Forms/FormSubAdmin";
import FormMaster from "./pages/superDetails/Forms/FormMaster";
import FormSuperAgent from "./pages/superDetails/Forms/FormSuperAgent";
import FormAgent from "./pages/superDetails/Forms/FormAgent";
import FormClient from "./pages/superDetails/Forms/FormClient";
// end Limit

import EditFormSubAdmin from "./pages/superDetails/editForm/EditFormSubAdmin";
import EditFormMaster from "./pages/superDetails/editForm/EditFormMaster";
import EditFormSuperAgent from "./pages/superDetails/editForm/EditFormSuperAgent";
import EditFormAgent from "./pages/superDetails/editForm/EditFormAgent";
import EditFormClient from "./pages/superDetails/editForm/EditFormClient";

// start Limit
import MainLimit from "./helpers/MainLimit";
// end Limit
// End Table Super Details

// Start Sport Betting
// Sport Details
import SportBetting from "./pages/sportBetting/sportDetails/index";
// Start Sport Details Nav  Pages
import MSPosition from "./pages/sportBetting/sportDetails/arrowPages/MSPosition";
import MSPlusMinus from "./pages/sportBetting/sportDetails/arrowPages/MSPlusMinus";
import MSMatchReport from "./pages/sportBetting/sportDetails/arrowPages/MSMatchReport";
import DisplayMatchSession from "./pages/sportBetting/sportDetails/arrowPages/DisplayMatchSession";
import DisplayMatchBets from "./pages/sportBetting/sportDetails/arrowPages/DisplayMatchBets";
import DisplaySessionBets from "./pages/sportBetting/sportDetails/arrowPages/DisplaySessionBets";
// End Sport Details Nav  Pages

// TeenPatti T20

// End Sport Betting

// Start Ledger
import MyLedger from "./pages/ledger/MyLedger";
import LedgerSubAdmin from "./pages/ledger/LedgerSubAdmin";
import LedgerMaster from "./pages/ledger/LedgerMaster";
import LedgerSuperAgent from "./pages/ledger/LedgerSuperAgent";
import LedgerAgent from "./pages/ledger/LedgerAgent";
import LedgerClient from "./pages/ledger/LedgerClient";
// End Ledger

// Start Transanction
import SubAdminTransanction from "./pages/cashTransanction/SubAdminTransanction";
import MasterTransanction from "./pages/cashTransanction/MasterTransanction";
import SuperAgentTransanction from "./pages/cashTransanction/SuperAgentTransanction";
import AgentTransanction from "./pages/cashTransanction/AgentTransanction";
import ClientTransanction from "./pages/cashTransanction/ClientTransanction";
import CompleteSport from "./pages/sportBetting/sportDetails/Complete";
// End Transanction

import Profile from "./pages/Profile";

// Report
import SubAdminReport from "./pages/reports/SubAdminReport";
import MasterReport from "./pages/reports/MasterReport";
import SuperAgentReport from "./pages/reports/SuperAgentReport";
import AgentReport from "./pages/reports/AgentReport";
import ClientReport from "./pages/reports/ClientReport";

// Login Report
import SubAdminLoginReport from "./pages/loginReport/SubAdminLoginReport";
import MasterLoginReport from "./pages/loginReport/MasterLoginReport";
import SuperAgentLoginReport from "./pages/loginReport/SuperAgentLoginReport";
import AgentLoginReport from "./pages/loginReport/AgentLoginReport";
import ClientLoginReport from "./pages/loginReport/ClientLoginReport";

import ClientPlusMinus from "./pages/ClientPlusMinus";

import Collection from "./pages/Collection";
import CreateCollection from "./pages/CreateCollection";
import EditCollection from "./pages/EditCollection";

// ex
import Layout from "./containers/Layout";
import RequuireAuth from "./containers/RequireAuth";
import MainLayout from "./containers/MainLayout";

//matka
import { Matka } from "./pages/sportBetting/matka";
import { DisplayMatkaBets } from "./pages/sportBetting/matka/DisplayMatkaBets";
import { HkReportPre } from "./pages/sportBetting/matka/HkReportPre";
import { HkReport } from "./pages/sportBetting/matka/HkReport";
import { MatkaPosition } from "./pages/sportBetting/matka/MatkaPosition";

const App = () => {
  let location = useLocation();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route exact path="/" element={<Navigate to="/login" />} />
        <Route index path="login" element={<Login />} />

        <Route path="/" element={<RequuireAuth />}>
          <Route path="/" element={<MainLayout />}>
            <Route path="dashboard" element={<Dashboard />} />

            {/* start Super details */}
            <Route
              path="/client/subadmin/list"
              element={<TableSubAdmin clientType="subadmin" />}
            />
            <Route
              path="/client/master/list"
              element={<TableMaster clientType="master" />}
            />
            <Route
              path="/client/superagent/list"
              element={<TableSuperAgent clientType="superAgent" />}
            />
            <Route
              path="/client/agent/list"
              element={<TableAgent clientType="agent" />}
            />
            <Route
              path="/client/list"
              element={<TableClient clientType="client" />}
            />

            {/* start create form */}
            <Route
              path="/client/create-sub-admin"
              element={<FormSubAdmin clientType="subadmin" />}
            />
            <Route
              path="/client/create-master/:id"
              element={<FormMaster clientType="master" />}
            />
            <Route
              path="/client/create-super-agent/:id"
              element={<FormSuperAgent clientType="superAgent" />}
            />
            <Route
              path="/client/create-agent/:id"
              element={<FormAgent clientType="agent" />}
            />
            <Route
              path="/client/create/:id"
              element={<FormClient clientType="client" />}
            />
            {/* End create form */}

            {/* start edit form */}
            <Route
              exact
              path="/client/update-subadmin/:id"
              element={<EditFormSubAdmin clientType={"subadmin"} type="EDIT" />}
            />
            <Route
              exact
              path="/client/update-master/:id"
              element={<EditFormMaster clientType={"master"} type="EDIT" />}
            />
            <Route
              exact
              path="/client/update-superagent/:id"
              element={
                <EditFormSuperAgent clientType={"superAgent"} type="EDIT" />
              }
            />
            <Route
              exact
              path="/client/update-agent/:id"
              element={<EditFormAgent clientType={"agent"} type="EDIT" />}
            />
            <Route
              exact
              path="/client/update-client/:id"
              element={<EditFormClient clientType={"client"} type="EDIT" />}
            />
            {/* end edit form */}

            {/* Start Limit Mangaer */}
            <Route path="/:user/limit" element={<MainLimit />} />
            {/* end Limit Mangaer */}
            {/* end super details */}

            {/* start Sport details */}
            <Route path="/client/sport-details" element={<SportBetting />} />
            {/* start Arroe Pages Sport details */}
            <Route path="/Events/:id/live-report" element={<MSPosition />} />
            <Route
              path="/Events/:id/plusMinusSelect"
              element={<MSPlusMinus />}
            />
            <Route
              path="/Events/:id/plusMinusSelect/report"
              element={<MSMatchReport />}
            />
            <Route
              path="/Events/:id/displayMatchSession"
              element={<DisplayMatchSession />}
            />
            <Route
              path="/Events/:id/match-slips"
              element={<DisplayMatchBets />}
            />
            <Route
              path="/Events/:id/fancy-slips"
              element={<DisplaySessionBets />}
            />
            {/* end Arroe Pages Sport details */}
            <Route
              path="/client/sport/complete/:id"
              element={<CompleteSport />}
            />
            {/* end Sport details */}

            {/* start Matka Details */}
            <Route path="/matka/:id/bets" element={<DisplayMatkaBets />} />
            <Route
              path="/client/matka-details"
              element={<Matka detailType="inplay" />}
            />
            <Route
              path="/client/matka-complete"
              element={<Matka detailType="complete" />}
            />
            <Route path="/client/:id/:ref/bets" element={<Matka />} />
            <Route path="/matka/:id/:ref/hk" element={<HkReportPre />} />
            <Route path="/matka/:id/:ref/hk/report" element={<HkReport />} />
            <Route
              path="/matka/:id/:ref/position"
              element={<MatkaPosition />}
            />

            {/* end Matka Details */}

            {/* Start Ledger */}
            <Route path="/client/my-ledger" element={<MyLedger />} />
            <Route
              exact
              path="/client/ledger/subadmin"
              element={<LedgerSubAdmin clientType={"subadmin"} />}
            />
            <Route
              exact
              path="/client/ledger/master"
              element={<LedgerMaster clientType={"master"} />}
            />
            <Route
              exact
              path="/client/ledger/superagent"
              element={<LedgerSuperAgent clientType={"superAgent"} />}
            />
            <Route
              exact
              path="/client/ledger/agent"
              element={<LedgerAgent clientType={"agent"} />}
            />
            <Route
              exact
              path="/client/ledger/client"
              element={<LedgerClient clientType={"client"} />}
            />
            {/* End Ledger */}

            {/* Start Transanction */}
            <Route
              exact
              path="/client/txn-subadmin"
              element={<SubAdminTransanction clientType={"subadmin"} />}
            />
            <Route
              exact
              path="/client/txn-master"
              element={<MasterTransanction clientType={"master"} />}
            />
            <Route
              exact
              path="/client/txn-superagent"
              element={<SuperAgentTransanction clientType={"superagent"} />}
            />
            <Route
              exact
              path="/client/txn-agent"
              element={<AgentTransanction clientType={"agent"} />}
            />
            <Route
              exact
              path="/client/txn-client"
              element={<ClientTransanction clientType={"client"} />}
            />
            {/* Start Transanction */}

            {/*  Report */}
            {/* <Route path='/report/limit' element={<LimitReport />} /> */}
            <Route
              path="/report/subadmin"
              element={<SubAdminReport clientType={"subadmin"} />}
            />
            <Route
              path="/report/master"
              element={<MasterReport clientType={"master"} />}
            />
            <Route
              path="/report/superagent"
              element={<SuperAgentReport clientType={"superAgent"} />}
            />
            <Route
              path="/report/agent"
              element={<AgentReport clientType={"agent"} />}
            />
            <Route
              path="/report/client"
              element={<ClientReport clientType={"client"} />}
            />

            {/* Login Report */}
            <Route
              path="/login/report/subadmin"
              element={<SubAdminLoginReport clientType={"subadmin"} />}
            />
            <Route
              path="/login/report/master"
              element={<MasterLoginReport clientType={"master"} />}
            />
            <Route
              path="/login/report/superagent"
              element={<SuperAgentLoginReport clientType={"superAgent"} />}
            />
            <Route
              path="/login/report/agent"
              element={<AgentLoginReport clientType={"agent"} />}
            />
            <Route
              path="/login/report/client"
              element={<ClientLoginReport clientType={"client"} />}
            />

            {/* Setting */}
            <Route path="/setting" element={<Setting />} />

            {/* Profile */}
            <Route path="/profile" element={<Profile />} />

            <Route path="/ledger/client/pm" element={<ClientPlusMinus />} />
            <Route path="/collection" element={<Collection />} />
            <Route path="/collection/create" element={<CreateCollection />} />
            <Route path="/collection/edit/:id" element={<EditCollection />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
