import '../config/GlobalVariable';

export const formHelper = {

    subadmin: {
        downLine: "SubAdmin",
        upLine: "Admin",
        share: 0,
        mobShare: 0,
        mainLine: true,
        mComm: 0,
        sComm: 0,
        createUrl: `${global.MainClient}/create-sub-admin`,
        authUrl: `${global.MainClient}/auth-admin`,
        redirectLink: "/client/subadmin/list",
        editClientDetails: `${global.MainClient}/edit/fetch-sub-admin`,
        editLink: `${global.MainClient}/edit-sub-admin`,
    },

    // superMaster: {
    //     downLine: "Super Master",
    //     upLine: "Sub Admin",
    //     share: 0,
    //     mobShare: 0,
    //     mainLine: false,
    //     mComm: 0,
    //     sComm: 0,
    //     createUrl: `${global.MainClient}/create-super-master`,
    //     authUrl: `${global.MainClient}/auth-sub-admin`,

    //     redirectLink: "/client/supermaster/list",
    //     editClientDetails: `${global.MainClient}/edit/fetch-super-master`,
    //     editLink: `${global.MainClient}/edit-super-master`,
    // },


    master: {
        downLine: "Master",
        upLine: "SubAdmin",
        share: 0,
        mobShare: 0,
        mainLine: false,
        mComm: 0,
        sComm: 0,
        createUrl: `${global.MainClient}/create-master`,
        authUrl: `${global.MainClient}/auth-sub-admin`,

        redirectLink: "/client/master/list",
        editClientDetails: `${global.MainClient}/edit/fetch-master`,
        editLink: `${global.MainClient}/edit-master`,
    },

    superAgent: {
        downLine: "Super Agent",
        upLine: "Master",
        share: 0,
        mobShare: 0,
        mainLine: false,
        mComm: 0,
        sComm: 0,
        createUrl: `${global.MainClient}/create-super-agent`,
        authUrl: `${global.MainClient}/auth-master`,

        redirectLink: "/client/superagent/list",
        editClientDetails: `${global.MainClient}/edit/fetch-super-agent`,
        editLink: `${global.MainClient}/edit-super-agent`,
    },

    agent: {
        downLine: "Agent",
        upLine: "Super Agent",
        share: 0,
        mobShare: 0,
        mainLine: false,
        mComm: 0,
        sComm: 0,
        createUrl: `${global.MainClient}/create-agent`,
        authUrl: `${global.MainClient}/auth-super-agent`,

        redirectLink: "/client/agent/list",
        editClientDetails: `${global.MainClient}/edit/fetch-agent`,
        editLink: `${global.MainClient}/edit-agent`,
    },

    client: {
        downLine: "Client",
        upLine: "Agent",
        share: 0,
        mobShare: 0,
        mainLine: false,
        mComm: 0,
        sComm: 0,
        createUrl: `${global.MainClient}/create-client`,
        authUrl: `${global.MainClient}/auth-agent`,

        redirectLink: "/client/list",
        editClientDetails: `${global.MainClient}/edit/fetch-client`,
        editLink: `${global.MainClient}/edit-client`,
    },

}





