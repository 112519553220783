import React, { useState, useEffect } from 'react';
import { TransactionsConst } from '../OLDconst/TransactionsConst';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import Util from '../libs/Util';
import { useForm } from 'react-hook-form';
import moment from 'moment'
import Select from 'react-select';

const Maintransanction = (props) => {


    const cookies = new Cookies();
    const { client } = useParams();

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [formField, setFormField] = useState({
        client: null,
        collection: 'CA1',
        amount: null,
        payment_type: 'credit',
        remark: null
    });

    const [state, setState] = useState(TransactionsConst[props.clientType]);

    const [clientList, setClientList] = useState([]);
    const [processList, setProcessList] = useState([]);
    const [disButton, setDisButton] = useState(false);
    const [creditTot, setCreditTot] = useState("");
    const [debitTot, setDebitTot] = useState("");

    const [selectClient, setSelectClient] = useState("");

    const [options, setOptions] = useState([]);
    const [dropdownState, setDropdownState] = useState({
        isClearable: true,
        isDisabled: false,
        isLoading: false,
        isRtl: false,
        isSearchable: true,
    });

    const [collection, setCollection] = useState([]);

    useEffect(() => {

        (async()=>{

            const script = document.createElement('script');

            await fetchClient();
            await fecthCollection();
            await loadJsFile(script);
    
            return () => {
                document.body.removeChild(script);
            }

        })();

    }, []);

    const loadJsFile = async (script) => {

        script.src = '/js/content.js';
        script.async = true;
        document.body.appendChild(script);

    }


    const fetchClient = async () => {

        let response = await Util.formSubmit(state.fetchClinet, "POST", {
            "token": cookies.get(global.CookiesVariable),
        });

        // if (response.status == "TRUE") {
        //     setClientList(response.data)
        // }

        if (response.status === "TRUE") {
            let arr = [];
            for (const iterator of response.data) {
                let obj = {};
                obj.label = iterator.userid + " " + iterator.username;
                obj.value = iterator.userid;
                arr.push(obj);
            }
            setOptions(arr);
        }

    }

    const onSubmit = async (e) => {

        e.preventDefault();

        // console.log('data', formField);

        // setDisButton(true);

        let fields = formField;
        fields.token = cookies.get(global.CookiesVariable);

        let response = await Util.formSubmit(state.submitLink, "POST", fields);

        if (response.status == "TRUE") {

            setFormField(
                {
                    client: null,
                    collection: 'CA1',
                    amount: null,
                    payment_type: 'credit',
                    remark: null
                }
            )
            handleOnChange(fields.client);
            reset();
        }
    }

    const handleOnChange = async (userId) => {

        setSelectClient(userId);
        setFormField({ ...formField, "client": userId })

        let response = await Util.formSubmit(state.processLink, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "userId": userId
        });

        if (response.status == "TRUE") {
            setProcessList(response.data.data)
            setCreditTot(response.data.creditTot)
            setDebitTot(response.data.debitTot)
        }
    }

    const deleteEntry = async (ids, userid) => {

        let response = await Util.formSubmit(state.deleteEntry, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "id": ids
        });

        if (response.status == "TRUE") {
            handleOnChange(userid);
        }
    }

    const fecthCollection = async () => {
        let response = await Util.formSubmit(`${global.MainClient}/collection`, "POST", {
            "token": cookies.get(global.CookiesVariable)
        });

        if (response.status == "TRUE") {
            setCollection(response.data)
        }
    }

    const handleField = (e) => setFormField((prev) => ({ ...prev, [e.target.id]: e.target.value }));

    return (
        <div className='content-wrapper'>
            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">{`${state.title} Transanction`}</h1>
                        </div>
                    </div>
                </div>
            </div>


            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {/* /.card */}
                            <div className="card">
                                <form onSubmit={onSubmit}>

                                    <div className="card-header ">
                                        <h4>Client Ledger</h4>

                                        <div className="form-row col-md-9">

                                            <div className="form-group col-md-4">
                                                <label> Client </label>
                                                {/* <select className="form-control select2" id="userId" name="userId" style={{ width: '100%' }}>
                                                    <select className="form-control" ref={clientUseRef} id="userId" name="userId" style={{ width: '100%' }} onChange={(e) => handleOnChange(e.target.value)}>
                                                    <option value={"NULL"} >Select ...  </option>
                                                    {clientList.map(({ userid, username }) => <option value={userid} >{userid} {username}  </option>)}
                                                </select> */}
                                                <Select
                                                    className="basic-single bg-white"
                                                    classNamePrefix="select"
                                                    defaultValue={options[0]}
                                                    isDisabled={dropdownState.isDisabled}
                                                    isLoading={dropdownState.isLoading}
                                                    isClearable={dropdownState.isClearable}
                                                    isRtl={dropdownState.isRtl}
                                                    isSearchable={dropdownState.isSearchable}
                                                    name="client_id"
                                                    options={options}
                                                    onChange={e => handleOnChange(e.value)}
                                                />

                                            </div>

                                            <div className="form-group col-md-4">
                                                <label htmlFor="collection">Collection</label>
                                                <select className="form-control custom-select" required id="collection" placeholder="Select Collection" name="collection">
                                                    {
                                                        collection.map(item => <option value={item.collectionId}>{item.collectionId} {item.collectionName}</option>)
                                                    }

                                                </select>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="amount">Amount</label>
                                                <input type="number"
                                                    className="form-control"
                                                    defaultValue step="any"
                                                    id="amount"
                                                    placeholder="Amount"
                                                    name="amount"
                                                    onChange={handleField} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="type">Payment Type</label>
                                                <select className="form-control custom-select" required id="payment_type" name="paymentType" onChange={handleField} >
                                                    <option value="credit" selected>PAYMENT - DENA</option>
                                                    <option value="debit">RECEIPT - LENA</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="remark">Remark</label>
                                                <input type="text" className="form-control " id="remark" placeholder="Remark" name="remark" onChange={handleField} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label className="control-label text-purple" htmlFor="btn">`</label>
                                                <button className="form-control btn-primary" id="btn" type="submit">Sumbit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">


                                        {
                                            processList.length === 0 ? <div className="alert alert-warning">
                                                <h6>No Record Found</h6>
                                            </div> :
                                                <div>

                                                    <table class="table table-bordered table-striped">
                                                        <thead>

                                                            <tr>
                                                                <th>#</th>
                                                                <th> Date</th>
                                                                <th>Collection Name</th>
                                                                <th>Debit</th>
                                                                <th>Credit</th>
                                                                <th>Balance</th>
                                                                <th>Payment Type</th>
                                                                <th>Remark</th>
                                                            </tr>

                                                            <tr>
                                                                <th></th>
                                                                <th></th>
                                                                <th class="text-blue">Total Amount</th>
                                                                <th>{debitTot}</th>
                                                                <th> {creditTot}</th>
                                                                <th class={(debitTot - creditTot) > 0 ? 'text-blue' : 'text-red'}>{debitTot - creditTot}</th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>

                                                            {processList.map((row, i) => {
                                                                let k = i;
                                                                console.log("aaa", row.CollectionName.substring(row.CollectionName.length, row.CollectionName - 1));
                                                                return (
                                                                    <tr>
                                                                        <td>{++k}</td>
                                                                        <td>{moment(row.datetime).format("DD/MM/YYYY hh:mm:ss a")}</td>
                                                                        <td>{row.CollectionName.substring(0, row.CollectionName.length - 1) === "CA" ? 'CA1 CASH' : row.CollectionName.toUpperCase()}</td>
                                                                        <td>{row.CrDr.toUpperCase() == "DEBIT" ? Math.abs(row.Amount) : 0}</td>
                                                                        <td>{row.CrDr.toUpperCase() == "CREDIT" ? Math.abs(row.Amount) : 0}</td>
                                                                        <td>{row.balance}</td>

                                                                        <td>{row.PaymentType}</td>
                                                                        <td>{row.Remark}</td>

                                                                    </tr>
                                                                );
                                                            })}

                                                        </tbody>
                                                        <tfoot>

                                                        </tfoot>
                                                    </table>

                                                </div>
                                        }













                                    </div>
                                    {/* /.card-body */}
                                </form>
                            </div>
                            {/* /.card */}
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>


        </div>
    );
}

export default Maintransanction;
