import '../config/GlobalVariable';

export const LoginReportConst = {
    admin: {
        title: "Admin",
        cType: "ADMIN",
        fetchClientUrl: `${global.MainClient}/fetch-admin-details`,
        fetchLoginReportUrl: `${global.MainClient}/login/report/admin`,
    },
    subadmin: {
        title: "Sub admin",
        cType: "SUBADMIN",
        fetchClientUrl: `${global.MainClient}/fetch-sub-admin-details`,
        fetchLoginReportUrl: `${global.MainClient}/login/report/subadmin`,
    },
    master: {
        title: "Master",
        cType: "MASTER",
        fetchClientUrl: `${global.MainClient}/fetch-master-details`,
        fetchLoginReportUrl: `${global.MainClient}/login/report/master`,
    },
    superAgent: {
        title: "Super agent",
        cType: "SUPERAGENT",
        fetchClientUrl: `${global.MainClient}/fetch-super-agent-details`,
        fetchLoginReportUrl: `${global.MainClient}/login/report/superagent`,
    },
    agent: {
        title: "agent",
        cType: "AGENT",
        fetchClientUrl: `${global.MainClient}/fetch-agent-details`,
        fetchLoginReportUrl: `${global.MainClient}/login/report/agent`,
    },
    client: {
        title: "Client",
        cType: "CLIENT",
        fetchClientUrl: `${global.MainClient}/fetch-client-details`,
        fetchReportUrl: `${global.MainClient}/login/report/client`,
    }
}























