import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Checkbox from '../../../components/Checkbox';
import Util from '../../../libs/Util';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import { setSportPlusMinus } from '../../../appRedux/actions/Auth';

export const HkReportPre = () => {

  const cookies = new Cookies();
  const { id,ref } = useParams();
  const dispatch = useDispatch();


  const [clientList, setClientList] = useState([]);
  const [isCheckUser, setIsCheckUser] = useState([]);
  const [isCheckAllUser, setIsCheckAllUser] = useState(false);
  const handleSelectAllUser = () => {
    setIsCheckAllUser(!isCheckAllUser);
    setIsCheckUser(clientList.map(li => li.userid));
    if (isCheckAllUser) {
        setIsCheckUser([]);
    }
}

 
  const handleClickUser = ()=>{}

  const fetchClientList = async() =>{

        let response = await Util.formSubmit(`${global.MainClient}/matka/getHkPre`,"POST",{

          "token": cookies.get(global.CookiesVariable),
            "id": id
        });


        if (response.status === "TRUE") {
        
            setClientList(response.data);
        
        }
  }

  const handleShow = async () => {
    dispatch(setSportPlusMinus([{ "USERS": isCheckUser }, { "FANCY": [] }]));
}


  useEffect(()=>{

    (async()=>{

        await fetchClientList();

    })();


  },[]);
  return (
    <div className='content-wrapper'>
            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Matka PlusMinus</h1>
                        </div>
                    </div>
                </div>
            </div>

            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">

                            <div class="card">


                                <div class="card-header text-center">
                                    <div class="container">
                                        <div class="row">

                                            <div class="col">
                                                <h5 class="text-bold text-white">Admin OF : WARRIORS V BOLAND (T-20)</h5>
                                            </div>

                                            <div class="col">

                                                <Link
                                                    to={`/matka/${id}/${ref}/hk/report`}
                                                    onClick={() => { handleShow() }}
                                                >
                                                    <button class="btn btn-primary">Show</button>
                                                </Link>
                                            </div>
                                        </div>

                                    </div>


                                </div>

                                <div class="card-body">


                                    <div class="form-row">

                                      

                                        <div class="col-md-8">
                                            <table align="left" id="clientTable" class="table table-striped  table-hover table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th width="15%">
                                                            <div align="center">

                                                                <Checkbox
                                                                    type="checkbox"
                                                                    handleClick={handleSelectAllUser}
                                                                    
                                                                />
                                                            </div>
                                                        </th>
                                                        <th>
                                                           Sub Admin (CODE NAME)
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {clientList.map((row) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <div align="center">

                                                                        <Checkbox
                                                                            type="checkbox"
                                                                            name={row.username}
                                                                            id={row.userid}
                                                                            handleClick={handleClickUser}
                                                                            isChecked={isCheckUser.includes(row.userid)}
                                                                            
                                                                           
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>{row.userid} {row.username}</td>
                                                            </tr>
                                                        );
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>


                                    </div>

                                </div>


                            </div>


                        </div>

                    </div>
                </div>
            </section>

        </div>
  )
}
