import React from "react";
import { NavLink, Link } from "react-router-dom";

import AdminLTELogo from "../assets/img/AdminLTELogo.png";

const Sidebar = () => {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <Link to="/dashboard" className="brand-link">
        <img
          src={AdminLTELogo}
          alt="Wbt98"
          className="brand-image img-circle elevation-3"
          style={{ opacity: ".8" }}
        />
        <span className="brand-text font-weight-light">{global.siteName}</span>
      </Link>

      <nav className="sidebar">
        <ul
          className="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li class="nav-header">MASTER DETAILS</li>

          <li className="nav-item ml-link">
            <NavLink to="/collection" className="nav-link">
              <i className="nav-icon fas fa-rupee-sign"></i>
              <p>Collection Master</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/client/subadmin/list" className="nav-link">
              <i className="nav-icon fas fa-address-card"></i>
              <p>SubAdmin Master</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/client/master/list" className="nav-link">
              <i className="nav-icon fas fa-address-card"></i>
              <p>MasterAgent Master</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/client/superagent/list" className="nav-link">
              <i className="nav-icon fas fa-address-card"></i>
              <p>SuperAgent Master</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/client/agent/list" className="nav-link">
              <i className="nav-icon fas fa-address-card"></i>
              <p>Agent Master</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/client/list" className="nav-link">
              <i className="nav-icon fas fa-address-card"></i>
              <p>Client Master</p>
            </NavLink>
          </li>

          {/* <li class="nav-header">GAMES</li>

                    <li className="nav-item ml-link">
                        <NavLink to="/client/sport-details" className="nav-link">
                            <i className="nav-icon fas fa-play"></i>
                            <p>InPlay Game</p>
                        </NavLink>
                    </li>

                    <li className="nav-item ml-link">
                        <NavLink to="/client/sport/complete/1" className="nav-link">
                            <i className="nav-icon far fa-stop-circle"></i>
                            <p>Complete Game</p>
                        </NavLink>
                    </li> */}

          <li class="nav-header">GALI MATKA</li>

          <li className="nav-item ml-link">
            <NavLink to="/client/matka-details" className="nav-link">
              <i className="nav-icon fas fa-play"></i>
              <p>Matka Inplay</p>
            </NavLink>
          </li>
          <li className="nav-item ml-link">
            <NavLink to="/client/matka-complete" className="nav-link">
              <i className="nav-icon far fa-stop-circle"></i>
              <p>Matka Complete</p>
            </NavLink>
          </li>

          <li class="nav-header">CASH TRANSACTION</li>

          <li className="nav-item ml-link">
            <NavLink to="/client/txn-client" className="nav-link">
              <i className="fas fa-angle-right nav-icon"></i>
              <p>Debit/Credit Entry (C)</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/client/txn-agent" className="nav-link">
              <i className="fas fa-angle-right nav-icon"></i>
              <p>Debit/Credit Entry (A)</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/client/txn-superagent" className="nav-link">
              <i className="fas fa-angle-right nav-icon"></i>
              <p>Debit/Credit Entry (SA)</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/client/txn-master" className="nav-link">
              <i className="fas fa-angle-right nav-icon"></i>
              <p>Debit/Credit Entry (M)</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/client/txn-subadmin" className="nav-link">
              <i className="fas fa-angle-right nav-icon"></i>
              <p>Debit/Credit Entry (SUB)</p>
            </NavLink>
          </li>

          <li class="nav-header">LEDGER</li>

          <li className="nav-item ml-link">
            <NavLink to="/client/my-ledger" className="nav-link">
              <i className="fas fa-angle-right nav-icon"></i>
              <p>My Ledger</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/ledger/client/pm" className="nav-link">
              <i className="fas fa-angle-right nav-icon"></i>
              <p>Commission Calculator</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/client/ledger/client" className="nav-link">
              <i className="fas fa-angle-right nav-icon"></i>
              <p>All Client Plus/Minus</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/client/ledger/agent" className="nav-link">
              <i className="fas fa-angle-right nav-icon"></i>
              <p>All Agent Plus/Minus</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/client/ledger/superagent" className="nav-link">
              <i className="fas fa-angle-right nav-icon"></i>
              <p>All Super Plus/Minus</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/client/ledger/master" className="nav-link">
              <i className="fas fa-angle-right nav-icon"></i>
              <p>All Master Plus/Minus</p>
            </NavLink>
          </li>

          <li className="nav-item ml-link">
            <NavLink to="/client/ledger/subadmin" className="nav-link">
              <i className="fas fa-angle-right nav-icon"></i>
              <p>All SubAdmin Plus/Minus</p>
            </NavLink>
          </li>

          <li class="nav-header">REPORT</li>

          <li className="nav-item">
            <Link to="#" className="nav-link">
              <i className="nav-icon fas fa-database" />
              <p>
                Data Reports
                <i className="right fas fa-angle-left" />
              </p>
            </Link>
            <ul className="nav nav-treeview">
              <li className="nav-item ml-link">
                <NavLink to="/report/subadmin" className="nav-link">
                  <i className="fas fa-list nav-icon"></i>
                  <p>SubAdmin Report</p>
                </NavLink>
              </li>

              <li className="nav-item ml-link">
                <NavLink to="/report/master" className="nav-link">
                  <i className="fas fa-list nav-icon"></i>
                  <p>Master Report</p>
                </NavLink>
              </li>

              <li className="nav-item ml-link">
                <NavLink to="/report/superagent" className="nav-link">
                  <i className="fas fa-list nav-icon"></i>
                  <p>Super Report</p>
                </NavLink>
              </li>

              <li className="nav-item ml-link">
                <NavLink to="/report/agent" className="nav-link">
                  <i className="fas fa-list nav-icon"></i>
                  <p>Agent Report</p>
                </NavLink>
              </li>

              <li className="nav-item ml-link">
                <NavLink to="/report/client" className="nav-link">
                  <i className="fas fa-list nav-icon"></i>
                  <p>Client Report</p>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link to="#" className="nav-link ">
              <i className="nav-icon fas fa-sign-in-alt" />
              <p>
                Login Reports
                <i className="right fas fa-angle-left" />
              </p>
            </Link>
            <ul className="nav nav-treeview">
              <li className="nav-item ml-link">
                <NavLink to="/login/report/subadmin" className="nav-link">
                  <i className="fas fa-list nav-icon"></i>
                  <p>SubAdmin Login Report</p>
                </NavLink>
              </li>

              <li className="nav-item ml-link">
                <NavLink to="/login/report/master" className="nav-link">
                  <i className="fas fa-list nav-icon"></i>
                  <p>Master Login Report</p>
                </NavLink>
              </li>

              <li className="nav-item ml-link">
                <NavLink to="/login/report/superagent" className="nav-link">
                  <i className="fas fa-list nav-icon"></i>
                  <p>Super Login Report</p>
                </NavLink>
              </li>

              <li className="nav-item ml-link">
                <NavLink to="/login/report/agent" className="nav-link">
                  <i className="fas fa-list nav-icon"></i>
                  <p>Agent Login Report</p>
                </NavLink>
              </li>

              <li className="nav-item ml-link">
                <NavLink to="/login/report/client" className="nav-link">
                  <i className="fas fa-list nav-icon"></i>
                  <p>Client Login Report</p>
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
