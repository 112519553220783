import React, { useState, useEffect } from 'react';
import Util from '../../libs/Util';
import Cookies from 'universal-cookie';
import moment from 'moment'


const Myledger = () => {

    const cookies = new Cookies();

    const [ledgerList, setLedgerList] = useState([]);
    const [creditTot, setCreditTot] = useState("");
    const [debitTot, setDebitTot] = useState("");

    useEffect(() => {
        getList();
    }, []);

    const getList = async () => {


        let response = await Util.formSubmit(`${global.MainClient}/ledger/my`, "POST", {
            "token": cookies.get(global.CookiesVariable),
        });

        if (response.status === "TRUE") {
            setLedgerList(response.data.data);
            setCreditTot(response.data.creditTot)
            setDebitTot(response.data.debitTot)
        }

    }



    return (
        <div className='content-wrapper'>
            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">My Ledger</h1>
                        </div>
                    </div>
                </div>
            </div>
            {/* Card */}
            <section class="content">
                <div class="container-fluid">
                    <div class="card">
                        <div class="card-body">


                            <table id="example1" class="table table-bordered table-striped">
                                <thead>
                                    <tr>

                                        <th>
                                            #
                                        </th>
                                        <th>Date</th>
                                        <th>Collection Name</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Balance</th>
                                        <th>Type</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <th colspan="3" class="text-indigo text-lg text-center">
                                            Total Amount
                                        </th>
                                        <th class="text-gray-dark text-lg"> {debitTot}</th>
                                        <th class="text-gray-dark text-lg"> {creditTot}</th>
                                        <th class={(debitTot - creditTot) > 0 ? 'text-blue text-lg' : 'text-red text-lg'}>{debitTot - creditTot}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>

                                    {ledgerList.map((row, i) => {
                                        let k = i;
                                        return (
                                            <tr>
                                                <td> {++k}</td>
                                                <td>{moment(row.datetime).format("DD/MM/YYYY hh:mm:ss a")}</td>
                                                <td>CA1 CASH</td>
                                                <td> {row.CrDr.toUpperCase() == "DEBIT" ? Math.abs(row.Amount) : 0}</td>
                                                <td>{row.CrDr.toUpperCase() == "CREDIT" ? Math.abs(row.Amount) : 0}</td>
                                                <td>{row.balance}</td>
                                                <td></td>
                                                <td>{row.PaymentType}</td>

                                            </tr>
                                        );
                                    })}


                                </tbody>
                                <tfoot>

                                </tfoot>
                            </table>



                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Myledger;
