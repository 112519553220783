import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import Util from '../../../../libs/Util';
import moment from "moment";

const DisplayMatchBets = () => {

    const cookies = new Cookies();
    const { id } = useParams();

    const [allClient, setAllClient] = useState([]);
    const [matchBetsList, setmatchBetsList] = useState([]);
    const [teamsList, setTeamsList] = useState({});

    const [clientId, setClientId] = useState(0);
    const [mTotal, setMTotal] = useState({});
    const [mTotalShare, setMTotalShare] = useState({});
    const [winner, setWinner] = useState(0);

    useEffect(() => {
        fetchClient();
    }, []);



    const fetchClient = async () => {
        let response = await Util.formSubmit(`${global.MainClient}/cricket/show_match_bets`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "matchId": id,
            "clientId": "ALL"
        });

        if (response.status == "TRUE") {
            setMTotalShare(response.data.mtotal_share);
            setMTotal(response.data.mtotal);
            setWinner(response.data.winner);
            setAllClient(response.clients)
            setmatchBetsList(response.data.data)
            setTeamsList(response.data.teams)
        }
    }

    const clientOnChange = (event) => {
        // fetchMatchBetsList(event.target.value);
        setClientId(event.target.value);
    }



    const fetchMatchBetsList = async (e) => {

        e.preventDefault();

        let response = await Util.formSubmit(`${global.MainClient}/cricket/show_match_bets`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "matchId": id,
            "clientId": clientId
        });

        if (response.status == "TRUE") {
            setMTotalShare(response.data.mtotal_share);
            setMTotal(response.data.mtotal);
            setWinner(response.data.winner);
            setAllClient(response.clients)
            setmatchBetsList(response.data.data)
            setTeamsList(response.data.teams)
        }
    }






    return (
        <div className='content-wrapper'>
            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Match Bets</h1>
                        </div>
                    </div>
                </div>
            </div>

            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">


                            <div class="card">


                                <form onSubmit={fetchMatchBetsList.bind(this)}>

                                    <div class="card-header ">

                                        <div class="form-row col-md-6">

                                            <table class="table table-bordered table-striped">
                                                <thead class="text-bold">
                                                    <tr>
                                                        <th>Won By</th>
                                                        <th class="text-blue">{teamsList[winner]}</th>
                                                    </tr>

                                                    {
                                                        Object.keys(teamsList).map(function (key, i) {
                                                            let count = ++i;
                                                            return (
                                                                <tr>
                                                                    <th>{teamsList[key]}</th>
                                                                    <th class={parseInt(mTotalShare['Amount' + count]) > 0 ? 'text-blue' : 'text-red'} >{mTotalShare['Amount' + count]}</th>
                                                                </tr>
                                                            );
                                                        })
                                                    }

                                                    <tr>
                                                        <th>Client</th>
                                                        <th>
                                                            <select class="form-control custom-select" required="" id="session" placeholder="Select Client" name="remark" onChange={clientOnChange}>
                                                                <option value="ALL">ALL Client</option>

                                                                {allClient.map(({ client_id, name }) => <option value={client_id}>{client_id} {name}</option>)}

                                                            </select>

                                                        </th>
                                                    </tr>
                                                </thead>
                                            </table>

                                            <input type="submit" class="form-control btn-primary" id="btn" name="submit" hidden="" value="Submit" />

                                        </div>


                                    </div>


                                    <div class="card-body">
                                        <div>

                                            <div class="row">

                                                <div class="col-md-12">
                                                    <table class="table table-striped table-bordered">

                                                        <thead>

                                                            <tr>
                                                                <th>#</th>
                                                                <th>Client</th>
                                                                <th>Rate</th>
                                                                <th>Team</th>
                                                                <th>Mode</th>
                                                                <th>Amount</th>
                                                                <th>Date &amp; Time</th>
                                                                {
                                                                    Object.keys(teamsList).map(function (key) {
                                                                        return (
                                                                            <th>{teamsList[key]}</th>
                                                                        );
                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <th class="text-center" colspan="6"></th>
                                                                <th class="text-center">Total Amount</th>


                                                                <th class={mTotal.Amount1 > 0 ? 'text-blue' : 'text-red'}>{mTotal.Amount1}</th>


                                                                <th class={mTotal.Amount2 > 0 ? 'text-blue' : 'text-red'}>{mTotal.Amount2}</th>

                                                            </tr>
                                                            {matchBetsList.map((row, i) => {
                                                                let counter = 0;
                                                                let k = ++i;
                                                                return (
                                                                    <tr>
                                                                        <td>{k}</td>
                                                                        <td>{row.client_id} {row.name}</td>
                                                                        <td>{row.bitrate}</td>
                                                                        <td>{teamsList[row.Team_id]}</td>
                                                                        <td>{row.Mode == "KHAI" ? "LAGAI" : "KHAI"}</td>
                                                                        <td>{row.Amount}</td>
                                                                        <td>{moment(row.datetime).format("DD MMM YYYY h:mm:ss a")}</td>
                                                                        {

                                                                            Object.keys(teamsList).map(function (key) {
                                                                                counter++;
                                                                                return <td>{(row['Amount' + counter]) * -1}</td>
                                                                            })

                                                                        }
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>

                                                        {/* <thead>
                                                           
                                                        </thead> */}

                                                    </table>
                                                </div>

                                            </div>

                                        </div>


                                    </div>
                                </form>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div >
    );
}

export default DisplayMatchBets;
