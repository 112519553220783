import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import name from '../appRedux/contants/';

const RequireAuth = () => {

    const user = useSelector(state => state.session);
    const location = useLocation();

    return (
        user ? <Outlet /> : <Navigate to={'/login'} state={{ from: location }} replace />
    );
}

export default RequireAuth;
