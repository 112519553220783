import { baseUrl } from "../OLDconst/Const";

export default class Util {
  static async formSubmit(path, method, data) {
   // const baseUrl = "//161.97.143.183/matkapro/api/index.php/";
    // const baseUrl = "/api/3.0/index.php/";
    //const baseUrl = "https://api.betguru.biz/index.php/";
    const baseUrl = "https://api.1ex.biz/index.php/";

    let response = await fetch(baseUrl + path, {
      method: method,
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    // console.log(response);
    return response.json();
  }
}
