import '../config/GlobalVariable';
export const LimiHelper = {

    "sub-admin": {
        downLine: "Sub Admin",
        upLine: "Admin",
        fetchUrl: `${global.MainClient}/limit/sub-admin`,
        updateLimitUrl: `${global.MainClient}/limit/sub-admin/update`,
        downLimitName: "Master"
    },

    // "super-master": {
    //     downLine: "Super Master",
    //     upLine: "Sub Admin",
    //     fetchUrl: `${global.MainClient}/limit/super-master`,
    //     updateLimitUrl: `${global.MainClient}/limit/super-master/update`,
    //     downLimitName: "Master"
    // },

    master: {
        downLine: "Master",
        upLine: "Sub Admin",
        fetchUrl: `${global.MainClient}/limit/master`,
        updateLimitUrl: `${global.MainClient}/limit/master/update`,
        downLimitName: "Super Agent"
    },

    "super-agent": {
        downLine: "Super Agent",
        upLine: "Master",
        fetchUrl: `${global.MainClient}/limit/super-agent`,
        updateLimitUrl: `${global.MainClient}/limit/super-agent/update`,
        downLimitName: "Agent"
    },

    agent: {
        downLine: "Agent",
        upLine: "Super Agent",
        fetchUrl: `${global.MainClient}/limit/agent`,
        updateLimitUrl: `${global.MainClient}/limit/agent/update`,
        downLimitName: "Client"
    },

    client: {
        downLine: "Client",
        upLine: "Agent",
        fetchUrl: `${global.MainClient}/limit/client`,
        updateLimitUrl: `${global.MainClient}/limit/client/update`,
        downLimitName: ""
    },

}


