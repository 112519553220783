import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Util from '../libs/Util';

const EditCollection = () => {


    const cookies = new Cookies();
    const navigate = useNavigate();
    const { id } = useParams();

    const [formField, setFormField] = useState({
        code: "Auto Generated",
        name: null,
        reference: null,
        mobile: null,
        account: null,
        collectiontype: "BANK"
    });

    useEffect(() => {
        const fetchCollection = async () => {
            let response = await Util.formSubmit(`${global.MainClient}/collection/fetch`, "POST", {
                "token": cookies.get(global.CookiesVariable),
                "id": id
            });

            if (response.status == "TRUE") {
                setFormField((formField) => ({
                    ...formField,
                    name: response.name,
                    reference: response.reference,
                    mobile: response.mobile,
                    account: response.account,
                    collectiontype: response.collectiontype
                }))
            }
        }

        fetchCollection();
    }, []);

    const handleField = (e) => setFormField((prev) => ({ ...prev, [e.target.id]: e.target.value }));

    const handleSubmit = async (e) => {
        e.preventDefault();

        let response = await Util.formSubmit(`${global.MainClient}/collection/edit`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            ...formField
        });

        if (response.status == "TRUE") {
            navigate('/collection')
            // setClientList(response.data)
        }
    }


    return (
        <div className='content-wrapper' style={{ minHeight: 856.413 }}>
            <section class="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Collection Details</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Collection</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>


            <section className="content">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Edit Collection</h3>
                                    <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                                            <i className="fas fa-minus" /></button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="code">Code</label>
                                        <input type="text"
                                            id="code"
                                            readOnly
                                            className="form-control"
                                            name="code"
                                            defaultValue={formField.code}
                                            value={formField.code}
                                            onChange={handleField}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            className="form-control"
                                            placeholder="Name"
                                            required
                                            name="name"
                                            defaultValue={formField.name}
                                            value={formField.name}
                                            onChange={handleField}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="reference">Reference</label>
                                        <div className="input-group ">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Reference"
                                                id="reference"
                                                name="reference"
                                                defaultValue={formField.reference}
                                                value={formField.reference}
                                                onChange={handleField}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="mobile">Contact No</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="mobile"
                                            placeholder="Mobile No"
                                            required
                                            name="contactno"
                                            defaultValue={formField.mobile}
                                            value={formField.mobile}
                                            onChange={handleField}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="account">Account No</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="account"
                                            placeholder="Account No"
                                            required
                                            name="accountno"
                                            defaultValue={formField.account}
                                            value={formField.account}
                                            onChange={handleField}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="type">Collectiom Type</label>
                                        <select className="form-control" id="type" name="collectiontype" onChange={handleField}>
                                            <option value="BANK">BANK</option>
                                            <option value="COLLECTION">COLLECTION</option>
                                        </select>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <a href="/collection" className="btn btn-secondary">Cancel</a>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary float-right">Create New
                                                    Collection
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        </div>
                    </div>
                </form>
            </section>






        </div>
    );
}

export default EditCollection;
