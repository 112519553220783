export const dashboardConst = {
    master: [
        {
            name: 'Sub admin',
            color: 'bg-success',
            url: '/client/subadmin/list'
        },
        {
            name: 'Master',
            color: 'bg-danger',
            url: '/client/master/list'
        },
        {
            name: 'Super agent',
            color: 'bg-info',
            url: '/client/superagent/list'
        },
        {
            name: 'Agent',
            color: 'bg-dark',
            url: '/client/agent/list'
        },
        {
            name: 'Client',
            color: 'bg-light bg-dark',
            url: '/client/list'
        },
        {
            name: 'Collection',
            color: 'bg-success',
            url: ''
        }
    ],
    game: [
        {
            name: 'InPlay',
            color: 'bg-primary',
            url: '/client/sport-details'
        },
        {
            name: 'Complete',
            color: 'bg-success',
            url: '/client/sport/complete/1'
        }
    ],
    cash: [

        {
            name: 'Debit/Credit(SUB)',
            color: 'bg-success',
            url: '/client/txn-subadmin'
        },
        {
            name: 'Debit/Credit(M)',
            color: 'bg-danger',
            url: '/client/txn-master'
        },
        {
            name: 'Debit/Credit(SA)',
            color: 'bg-info',
            url: '/client/txn-superagent'
        },
        {
            name: 'Debit/Credit(A)',
            color: 'bg-light bg-dark',
            url: '/client/txn-agent'
        },
        {
            name: 'Debit/Credit(C)',
            color: 'bg-danger',
            url: '/client/txn-client'
        },
    ],
    ledger: [
        {
            name: 'My Ledger',
            color: 'bg-primary',
            url: '/client/my-ledger'
        },

        {
            name: 'Sub admin Ledger',
            color: 'bg-warning',
            url: '/client/ledger/subadmin'
        },
        {
            name: 'Master Ledger',
            color: 'bg-info',
            url: '/client/ledger/master'
        },
        {
            name: 'Super agent Ledger',
            color: 'bg-light bg-dark',
            url: '/client/ledger/superagent'
        },
        {
            name: 'Agent Ledger',
            color: 'bg-danger',
            url: '/client/ledger/agent'
        },
        {
            name: 'Client Ledger',
            color: 'bg-danger',
            url: '/client/ledger/client'
        },
    ]
};