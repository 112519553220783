import React, { useEffect, useState } from "react";
import Util from "../../../libs/Util";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import moment from "moment";

export const Matka = ({ detailType = "inplay" }) => {
  var cookies = new Cookies();
  const [sportList, setSportList] = useState([]);

  const fetchMatkaDetails = async () => {
    let response = await Util.formSubmit(
      `${global.MainClient}/matka/list`,
      "POST",
      {
        token: cookies.get(global.CookiesVariable),
        status: detailType,
      }
    );

    // console.log(response);
    if (response.status.toString().toUpperCase() == "TRUE")
      setSportList(response.data);
  };

  useEffect(() => {
    (async () => {
      fetchMatkaDetails();
    })();
  }, [detailType]);

  return (
    <div className="content-wrapper">
      {/* Header */}
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">
                {detailType == "inplay" ? "Matka Inplay" : "Matka Complete"}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Matka List</h3>
                </div>

                <div class="card-body">
                  <table
                    id="example1"
                    className="table table-bordered table-striped "
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>SNo</th>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Open Time</th>
                        <th>Close Time</th>
                        <th>Result Time</th>
                        <th>Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sportList.map((row, index) => {
                        // console.log(row);
                        let k = index;
                        return (
                          <tr>
                            <td>
                              <div align="center">
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn  btn-outline-primary dropdown-toggle dropdown-hover dropdown-icon"
                                    data-toggle="dropdown"
                                  >
                                    <span className="sr-only">
                                      Toggle Dropdown
                                    </span>
                                  </button>
                                  <div className="dropdown-menu" role="menu">
                                    {detailType != "complete" && (
                                      <Link
                                        className="dropdown-item btn"
                                        to={`/matka/${row.sno}/${row.ref_id}/position`}
                                      >
                                        Matka Position{" "}
                                      </Link>
                                    )}
                                    {detailType != "inplay" && (
                                      <Link
                                        className="dropdown-item btn"
                                        to={`/matka/${row.sno}/${row.ref_id}/hk`}
                                      >
                                        Matka H/K{" "}
                                      </Link>
                                    )}
                                    <Link
                                      className="dropdown-item btn"
                                      to={`/matka/${row.sno}/bets`}
                                    >
                                      Matka Bets{" "}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <label>{++k}</label>
                            </td>
                            <td>
                              <span> {row.sno}</span>
                            </td>
                            <td> {row.matka_name}</td>
                            <td>
                              {moment(row.bstime).format("DD-MM-YYYY hh:mm A")}
                            </td>
                            <td>
                              {moment(row.betime).format("DD-MM-YYYY hh:mm A")}
                            </td>
                            <td>
                              {moment(row.result_time).format(
                                "DD-MM-YYYY HH:mm A"
                              )}
                            </td>
                            <td>{row.result}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
