
import React, { useState, useEffect } from 'react';
import { LedgerHelper } from '../OLDconst/LedgerConst';
import Util from '../libs/Util';
import Cookies from 'universal-cookie';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select';


const ClientPlusMinus = (props) => {

    const cookies = new Cookies();

    const [getState] = useState(LedgerHelper['client']);
    const [client, setClient] = useState([]);

    const [fields, setFields] = useState({
        client: "ALL",
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        share: "0",
        mComm: "0",
        sComm: "0"
    });


    const [state, setState] = useState({
        isClearable: true,
        isDisabled: false,
        isLoading: false,
        isRtl: false,
        isSearchable: true,
    });

    const [data, setData] = useState([]);

    useEffect(() => {
        getClient();
    }, []);

    const getClient = async () => {

        let response = await Util.formSubmit(getState.getClient, "POST", {
            "token": cookies.get(global.CookiesVariable)
        });

        if (response.status === "TRUE") {
            let arr = [];
            for (const iterator of response.data) {
                let obj = {};
                obj.label = iterator.userid + " " + iterator.username;
                obj.value = iterator.userid;
                arr.push(obj);
            }
            setClient(arr);
        }

    }

    const loginHandler = async (e) => {
        e.preventDefault();
        let response = await Util.formSubmit(getState.getUrls, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "clientId": fields.client,
            "from": fields.from,
            "to": fields.to,
            "share": fields.share,
            "mComm": fields.mComm,
            "sComm": fields.sComm,
        });

        if (response.status === "TRUE") {
            setData(response.data);
        }
    }

    const stateHandler = (e) => {
        setFields((prev) => ({ ...prev, [e.target.id]: e.target.value }))
    }


    let mAmt = 0;
    let sAmt = 0;
    let totAmt = 0;
    let mComm = 0;
    let sComm = 0;
    let totComm = 0;
    let netAmt = 0;
    let share = 0;
    let finalAmt = 0;



    return (
        <div className='content-wrapper'>
            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">{`${getState.title} Ledger`}</h1>
                        </div>
                    </div>
                </div>
            </div>

            <section class="content">
                <div class="container-fluid">

                    <div class="row">

                        <div class="col-12">

                            <div class="card">

                                <form onSubmit={loginHandler.bind(this)} method="post">

                                    <div class="card-header bg-gradient-purple">

                                        <h4>Client Report</h4>

                                        <div class="form-row">

                                            <div class="form-group col-md-2">
                                                <label for="name">Client</label>
                                                <Select
                                                    className="basic-single bg-white"
                                                    classNamePrefix="select"
                                                    defaultValue={client[0]}
                                                    isDisabled={state.isDisabled}
                                                    isLoading={state.isLoading}
                                                    isClearable={state.isClearable}
                                                    isRtl={state.isRtl}
                                                    isSearchable={state.isSearchable}
                                                    name="client_id"
                                                    options={client}
                                                    onChange={(e) => setFields({ ...fields, client: e.value, idName: e.label })}
                                                />
                                            </div>

                                            <div class="form-group col-md-2">
                                                <div class="form-group">
                                                    <Form.Group controlId="dob">
                                                        <Form.Label>Date From</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name="dateFrom"
                                                            defaultValue={fields.from}
                                                            onChange={(e) => setFields({ ...fields, from: e.target.value })}
                                                        />
                                                    </Form.Group>

                                                </div>
                                            </div>

                                            <div class="form-group col-md-2">
                                                <div class="form-group">
                                                    <Form.Group controlId="dob">
                                                        <Form.Label>Date To</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name="dateTo"
                                                            defaultValue={fields.to}
                                                            onChange={(e) => setFields({ ...fields, to: e.target.value })} />
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-2">
                                                <label for="name">Share</label>
                                                <input
                                                    type="text"
                                                    name="share"
                                                    id="share"
                                                    value={fields.share}
                                                    class="form-control"
                                                    defaultValue={fields.share}
                                                    onChange={stateHandler.bind(this)} />
                                            </div>

                                            <div class="form-group col-md-2">
                                                <label for="name">Match Comm</label>
                                                <input
                                                    type="text"
                                                    name="mComm"
                                                    id="mComm"
                                                    value={fields.mComm}
                                                    class="form-control"
                                                    defaultValue={fields.mComm}
                                                    onChange={stateHandler.bind(this)} />
                                            </div>

                                            <div class="form-group col-md-2">
                                                <label for="name">Session Comm</label>
                                                <input
                                                    type="text"
                                                    name="sComm"
                                                    id="sComm"
                                                    value={fields.sComm}
                                                    class="form-control"
                                                    defaultValue={fields.sComm}
                                                    onChange={stateHandler.bind(this)} />
                                            </div>

                                            <div class="form-group col-md-3">

                                                <label class="control-label text-purple" for="btn">`</label>
                                                <input type="submit" class="form-control btn-primary" id="btn" name="submit" value="Sumbit" />

                                            </div>


                                        </div>


                                    </div>



                                    {/* <div class="card-body">

                                        <div class="alert alert-warning">
                                            <h6>No Record Found</h6>
                                        </div>

                                    </div>
 */}




                                    <div class="card-body">
                                        <div>

                                            <table id="example1" class="table table-bordered table-striped">
                                                <thead>
                                                    <tr>

                                                        <th colspan="2"></th>
                                                        <th colspan="3">Match</th>
                                                        <th colspan="3">Session</th>
                                                        <th colspan="2">Other Amount</th>
                                                        <th></th>
                                                    </tr>

                                                    <tr>

                                                        <th> Date</th>
                                                        <th>Match Name</th>
                                                        <th>Match</th>
                                                        <th>Session</th>
                                                        <th>Total</th>
                                                        <th>Match @ ({fields.mComm}%) Comm</th>
                                                        <th>Session @ ({fields.sComm}%) Comm</th>
                                                        <th>Total Comm</th>
                                                        <th>Net Amt</th>
                                                        <th>Share Amt @ ({fields.share}%)</th>
                                                        <th>Final Amt</th>
                                                    </tr>

                                                    <tr>
                                                        {/* <th class="text-right" colspan="10">Opening Balance</th>
                                                        <th></th> */}
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {

                                                        Object.keys(data).map(function (key) {

                                                            let keyDate = moment(key).format("DD-MM-YYYY")

                                                            let datemAmt = 0;
                                                            let datesAmt = 0;
                                                            let datetotAmt = 0;
                                                            let datemComm = 0;
                                                            let datesComm = 0;
                                                            let datetotComm = 0;
                                                            let datenetAmt = 0;
                                                            let dateshare = 0;
                                                            let datefinalAmt = 0;

                                                            return (
                                                                <>
                                                                    <tr style={{ backgroundColor: "#e0ffd5", fontSize: 18, fontWeight: 800 }} >
                                                                        <td colspan='11'>{keyDate}</td>
                                                                    </tr>
                                                                    {
                                                                        data[key].data.map((row) => {


                                                                            mAmt += row.mAmt;
                                                                            sAmt += row.sAmt;
                                                                            totAmt += row.totAmt;
                                                                            mComm += row.mComm;
                                                                            sComm += row.sComm;
                                                                            totComm += row.totComm;
                                                                            netAmt += row.netAmt;
                                                                            share += row.shrAmt;
                                                                            finalAmt += row.finalAmt;

                                                                            datemAmt += row.mAmt;
                                                                            datesAmt += row.sAmt;
                                                                            datetotAmt += row.totAmt;
                                                                            datemComm += row.mComm;
                                                                            datesComm += row.sComm;
                                                                            datetotComm += row.totComm;
                                                                            datenetAmt += row.netAmt;
                                                                            dateshare += row.shrAmt;
                                                                            datefinalAmt += row.finalAmt;

                                                                            return (
                                                                                <tr>
                                                                                    <td>{moment(row.dateTime).format("DD-MM-YYYY HH:mm:ss")}</td>
                                                                                    <td>{row.matchName}</td>
                                                                                    <td>{row.mAmt}</td>
                                                                                    <td>{row.sAmt}</td>
                                                                                    <td>{row.totAmt}</td>
                                                                                    <td>{row.mComm}</td>
                                                                                    <td>{row.sComm}</td>
                                                                                    <td>{row.totComm}</td>
                                                                                    <td>{row.netAmt}</td>
                                                                                    <td>{row.shrAmt}</td>
                                                                                    <td>{row.finalAmt}</td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    }


                                                                    <tr class="text text-lg" style={{ backgroundColor: "#ff7e7e" }}>
                                                                        <td>{keyDate}</td>
                                                                        <td>Total</td>
                                                                        <td>{datemAmt}</td>
                                                                        <td>{datesAmt}</td>
                                                                        <td>{datetotAmt}</td>
                                                                        <td>{datemComm}</td>
                                                                        <td>{datesComm}</td>
                                                                        <td>{datetotComm}</td>
                                                                        <td>{datenetAmt}</td>
                                                                        <td>{dateshare}</td>
                                                                        <td>{datefinalAmt}</td>

                                                                    </tr>
                                                                </>
                                                            );
                                                        })
                                                    }

                                                    <tr className="text text-lg text-bold">
                                                        <td>Total</td>
                                                        <td></td>
                                                        <td>{mAmt}</td>
                                                        <td>{sAmt}</td>
                                                        <td>{totAmt}</td>
                                                        <td>{mComm}</td>
                                                        <td>{sComm}</td>
                                                        <td>{totComm}</td>
                                                        <td>{netAmt}</td>
                                                        <td>{share}</td>
                                                        <td>{finalAmt}</td>

                                                    </tr>
                                                </tbody>

                                                <tfoot>

                                                </tfoot>
                                            </table>

                                        </div>


                                    </div>

                                </form>

                            </div>



                        </div>

                    </div>

                </div>
            </section>

        </div>
    );
}

export default ClientPlusMinus;













