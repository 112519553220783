import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Util from '../../../libs/Util';

export const DisplayMatkaBets = () => {

    const cookies = new Cookies();
    const { id } = useParams();

    const [allClient, setAllClient] = useState([]);
    const [mTotal, setMTotal] = useState([]);
    const [matkaBetsList, setMatkaBetsList] = useState([]);
    const [clientId, setClientId] = useState("ALL");
    const [totalAmt, setTotalAmt] = useState(0);
    const [result, setResult] = useState("");

    const fetchClient = async () => {
        let response = await Util.formSubmit(`${global.MainClient}/matka/getclient`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "id": id,
            "clientId": "ALL"
        });

        if (response.status == "TRUE") {

            setAllClient(response.data?.clients);
            setResult(response.data?.result);
           
        }
    }

    const modeName = (name)=>{

        let returner = "";
        switch (name){

            case "jodi":
                returner = "JODI";
                break;
            case "andar":
                returner = "ANDAR HARUF";
                break;
            case "bahar":
                returner = "BAHAR HARUF";
                break;

        }

        return returner;
    }

    const fetchBets = async()=>{

        let response = await Util.formSubmit(`${global.MainClient}/matka/getbets`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "id": id,
            "clientId": clientId
        });

        if (response.status == "TRUE") {

            setMatkaBetsList(response.data);
            setTotalAmt(response.totalAmt);
           
        }
    }

    const onChangeHandler = (event)=>{

        setClientId(event.target.value);
    }
    const onClickButtonHandler = async (event)=>{

        event.preventDefault();

        await fetchBets();
    }

    useEffect(()=>{

        (async()=>{

            await fetchClient();
            await fetchBets();

        })();
    },[]);

  return (
    <div className='content-wrapper'>
    {/* Header */}
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Matka Bets</h1>
                </div>
            </div>
        </div>
    </div>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">


                    <div class="card">


                        <form >

                            <div class="card-header ">

                                <div class="form-row col-md-6">

                                    <table class="table table-bordered table-striped">
                                        <thead class="text-bold">
                                            <tr>
                                                <th>Result</th>
                                                <th class="text-blue">{result}</th>
                                            </tr>

                                           

                                            <tr>
                                                <th>Client</th>
                                                <th>
                                                    <select class="form-control custom-select" required="" id="clientId" placeholder="Select Client" onChange={onChangeHandler} name="remark">
                                                        <option value="ALL">ALL Client</option>

                                                        {allClient.map(({ client_id, name }) => <option value={client_id}>{client_id} {name}</option>)}

                                                    </select>

                                                </th>
                                            </tr>
                                        </thead>
                                    </table>

                                    <input type="submit" class="form-control btn-primary" id="btn" name="submit" onClick={onClickButtonHandler} value="Submit" />

                                </div>


                            </div>


                            <div class="card-body">
                                <div>

                                    <div class="row">

                                        <div class="col-md-12">
                                            <table class="table table-striped table-bordered">

                                                <thead>

                                                    <tr>
                                                        <th>#</th>
                                                        <th>Client</th>
                                                        <th>Bit</th>
                                                        <th>Amount</th>
                                                        <th>Mode</th>
                                                        
                                                        <th>Date &amp; Time</th>
                                                       
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <th className="text-center" colspan="">Total Amount</th>
                                                        <th className="text-right" colspan="3">{totalAmt}</th>
                                                        <th className="text-center" colspan="2"></th>

                                                    </tr>
                                                    {matkaBetsList.map((row, i) => {
                                                        let counter = 0;
                                                        let k = ++i;
                                                        return (
                                                            <tr>
                                                                <td>{k}</td>
                                                                <td>{row.client_id}</td>
                                                                <td>{row.bit}</td>
                                                                <td className='text-right'>{row.amount}</td>
                                                                <td>{modeName(row.bitType)}</td>
                                                                <td>{row.datentime}</td>
                                                             
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>

                                                {/* <thead>
                                                   
                                                </thead> */}

                                            </table>
                                        </div>

                                    </div>

                                </div>


                            </div>
                        </form>

                    </div>

                </div>
            </div>
        </div>
    </section>
</div >
  )
}
