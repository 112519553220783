import React, { useState, useEffect } from 'react';
import { LimiHelper } from '../OLDconst/LimitConst';
import Util from '../libs/Util';
import Cookies from 'universal-cookie';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const Mainlimit = (props) => {



    const cookies = new Cookies();
    const { user } = useParams();
    const clientIp = useSelector(state => state.ip);

    // let query = useQuery();
    var preDatas = {};
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [preData, setPreData] = useState({});
    const [userList, setUserList] = useState([]);
    const [selfLimit, setSelfLimit] = useState([]);

    useEffect(() => {

        (async()=>{

            preDatas = LimiHelper[user];
            setPreData(LimiHelper[user]);
    
            await getClientLimit(preDatas);
            const script = document.createElement('script');
            await loadJsFile(script);
            return () => {
                document.body.removeChild(script);
            }
    

        })();

       
    }, []);


    const getClientLimit = async (preD) => {

        let response = await Util.formSubmit(preD.fetchUrl, "POST", {
            // let response = await Util.formSubmit(preData.fetchUrl, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "parentId": searchParams.get('id')
        });

        if (response.status === "TRUE") {
            if (response.data.redirect === "TRUE") {
                navigate(-1);
                return;
            }
            setUserList(response.data.users);
            setSelfLimit(response.data.selfLimit);
        }
    }


    const loadJsFile = async (script) => {

        script.src = '/js/content.js';
        script.async = true;
        document.body.appendChild(script);

    }

    // Handle Add Limit
    const handleAddLimit = (userId, preLimit, limit) => {


        if (parseInt(preLimit) < parseInt(limit.value)) {
            alert(`Limit Exceed`)
            return;
        }
        updateLimit(userId, limit.value, "ADD");
        limit.value = "";
    }

    // Handle minus Limit
    const handleMinusLimit = (userId, preLimit, limit) => {

        updateLimit(userId, limit.value, "MINUS");
        limit.value = "";
    }

    const updateLimit = async (userId, limit, plusMinus) => {

        let preD = preData;

        let response = await Util.formSubmit(preD.updateLimitUrl, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "userId": userId,
            "limit": limit,
            "plusMinus": plusMinus,
            "parentId": searchParams.get('id'),
            clientIp: clientIp,
        });

        if (response.status === "TRUE") {
            getClientLimit(preD);
        } else {
            alert(response.msg)
        }
    }


    return (
        <div className='content-wrapper'>
            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">{preData.downLine} Limit</h1>
                        </div>
                    </div>
                </div>
            </div>

            <section class="content">
                <div class="container-fluid">

                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div className='card'>
                                <div class="card-body">
                                    <table id="example2" class="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>SNo</th>
                                                <th>Client Name</th>
                                                <th>Limit</th>
                                                <th>Enter Limit</th>
                                                <th>My Limit : {selfLimit}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userList.map(({ userid, username, limit }, i) => {

                                                let k = i;

                                                return (
                                                    <tr role="row" class="odd" >

                                                        <td>{++k}</td>

                                                        <td>{userid} {username}</td>

                                                        <td>{limit}</td>

                                                        <td class="col-lg-4" style={{ minWidth: 120 }}>
                                                            <input
                                                                required=""
                                                                type="number"
                                                                id={`limit${i}`}
                                                                class="form-control"
                                                                step="1"
                                                                name={`limit${i}`} />
                                                        </td>

                                                        <td style={{ minWidth: 150 }}>
                                                            <button class="btn-sm btn-primary" type="submit" name="type" value="Add" onClick={() => handleAddLimit(userid, selfLimit, document.getElementById(`limit${i}`))}>
                                                                Add
                                                            </button>
                                                            &nbsp;&nbsp;
                                                            <button class="btn-sm btn-danger" type="submit" name="type" value="Minus" onClick={() => handleMinusLimit(userid, selfLimit, document.getElementById(`limit${i}`))}> Minus</button>
                                                        </td>

                                                    </tr>
                                                );
                                            })}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Mainlimit;
