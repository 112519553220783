import { ActionTypes } from "../contants/action-types";

const initialState = {
    user: [],
    sportPlusMinus: [],
    ip: ""
}
export const sessionReducers = (state = initialState.user, { type, payload }) => {
    switch (type) {
        case ActionTypes.SESSION:
            return payload;
        default:
            return state;
    }
}


export const sportDetailsPlusMinus = (state = initialState.sportPlusMinus, { type, payload }) => {
    switch (type) {
        case ActionTypes.SportPlusMinus:
            return payload;
        default:
            return state;
    }
}

export const clientIp = (state = initialState.ip, { type, payload }) => {
    switch (type) {
        case ActionTypes.ClientIp:
            return payload;
        default:
            return state;
    }
}