import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Select from 'react-select';
import Cookies from 'universal-cookie';
import Util from '../../../../libs/Util';
import moment from 'moment'

var fetchClientTimeOut = null;

const Displaymatchsession = () => {

    const cookies = new Cookies();
    const { id } = useParams();

    const [state, setState] = useState({ isClearable: true, isDisabled: false, isLoading: false, isRtl: false, isSearchable: true });
    const [option, setOption] = useState([]);
    const [oddList, setOddList] = useState([]);
    const [oddsBetsTeamList, setOddsBetsTeamList] = useState({});
    const [fancyList, setFancyList] = useState([]);

    const [fancyNameList, setFancyNameList] = useState({});

    const [mPlusMinus, setMPlusMinus] = useState(0);
    const [sPlusMinus, setSPlusMinus] = useState(0);

    const [mTotal, setMTotal] = useState({});

    const [mDetails, setMDetails] = useState({});
    const [team, setTeam] = useState({});



    useEffect(() => {
        fetchClient();
    }, []);


    const fetchClient = async () => {
        let response = await Util.formSubmit(`${global.MainClient}/cricket/msdisplay`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "matchId": id
        });

        if (response.status == "TRUE") {

            setMDetails(response.data.mDetails);
            let arr = [];
            for (const iterator of response.data.users) {
                let obj = {};
                obj.value = iterator.userid;
                obj.label = iterator.name2;
                arr.push(obj);
            }
            setOption(arr);
        }
    }



    const handleOnChange = async (value) => {

        let response = await Util.formSubmit(`${global.MainClient}/cricket/msdisplay/process`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "matchId": id,
            "userId": value.value
        });

        if (response.status == "TRUE") {
            setMTotal(response.data.mtotal);
            setFancyNameList(response.data.fancy);
            setMPlusMinus(response.data.m_plusminus);
            setSPlusMinus(response.data.s_plusminus);
            setOddsBetsTeamList(response.data.teams)
            setOddList(response.data.mdata)
            setFancyList(response.data.sdata)
            setTeam(response.data.teams)
        }

    }


    let fancyAmt = 0;

    return (

        <div className='content-wrapper'>

            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Match &amp; Session Bet Details MatchCode : {mDetails.MatchName} ({id})</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section class="content">
                <div class="container-fluid ">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-md-4">
                                    <label>Client</label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={option[0]}
                                        isDisabled={state.isDisabled}
                                        isLoading={state.isLoading}
                                        isClearable={state.isClearable}
                                        isRtl={state.isRtl}
                                        isSearchable={state.isSearchable}
                                        name="client_id"
                                        options={option}
                                        onChange={handleOnChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card-body">

                            <div>
                                <div class="form-row">

                                    <div class="col-md-6">
                                        <table class="table table-striped table-bordered">

                                            <thead>

                                                <tr>
                                                    <th class="text-center bg-secondary" colspan="9">MATCH BETS</th>
                                                </tr>

                                                <tr>
                                                    <th>#</th>
                                                    <th></th>
                                                    <th>Rate</th>
                                                    <th>Mode</th>
                                                    <th>Team</th>
                                                    <th>Amount</th>
                                                    {Object.keys(oddsBetsTeamList).map(function (row) {
                                                        return (
                                                            <th> {oddsBetsTeamList[row]} </th>
                                                        );
                                                    })}
                                                    <th>Date &amp; Time</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {oddList.map((row, i) => {
                                                    let k = i;
                                                    let m = 0;


                                                    return (
                                                        <tr>
                                                            <td>{++k}</td>
                                                            <td>{row.client_id}</td>
                                                            <td>{row.bitrate}</td>
                                                            <td>{row.Mode == "KHAI" ? "LAGAI" : "KHAI"}</td>
                                                            <td>{team[row.Team_id]}</td>
                                                            <td>{row.Amount}</td>
                                                            {Object.keys(oddsBetsTeamList).map(function (row1) {
                                                                ++m;
                                                                return (
                                                                    <td style={{ color: row["Amount" + m] * (-1) > 0 ? "blue" : "red" }}> {row["Amount" + m] * (-1)} </td>
                                                                );
                                                            })}
                                                            <td>{row.datetime}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>

                                            <tbody>
                                                <tr>
                                                    <th class="text-center" colspan="3">{oddsBetsTeamList[mDetails.WonbyTeam_id]}</th>
                                                    <th class="text-center" colspan="2">{mPlusMinus > 0 ? 'Minus' : 'Plus'}</th>
                                                    <th class="text-red">{mPlusMinus}</th>
                                                    <th class="text-red">{mTotal.Amount1}</th>
                                                    <th class="text-red">{mTotal.Amount2}</th>
                                                    <th></th>
                                                </tr>
                                            </tbody>

                                            {/* <tbody>
                                            <tr>
                                                <th class="text-center" colspan="4">NEWZE</th>
                                                <th class="text-center">Minus</th>
                                                <th class="text-red">{oddAmt}</th>

                                                {Object.keys(oddTeam).map(function (row1) {
                                                    let m = 0;
                                                    console.log("oddTeam", oddTeam.row1);
                                                    return (
                                                        <td style={{ color: oddTeam[m] * (-1) > 0 ? "blue" : "red" }}> {oddTeam[m] * (-1)} </td>
                                                    );
                                                })}
                                                <th></th>
                                            </tr>
                                        </tbody> */}
                                        </table>
                                    </div>

                                    <div class="col-md-6">
                                        <table class="table table-striped table-bordered">
                                            <thead>

                                                <tr>
                                                    <th class="text-center bg-secondary" colspan="8">SESSION BETS
                                                    </th>
                                                </tr>

                                                <tr>
                                                    <th>#</th>
                                                    <th>Session</th>
                                                    <th>Rate</th>
                                                    <th>Run</th>
                                                    <th>Mode</th>
                                                    <th>Amount</th>
                                                    <th>Dec</th>
                                                    <th>Date &amp; Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {fancyList.map((row, i) => {
                                                    let k = i;
                                                    fancyAmt += parseInt(row.Amount);
                                                    return (
                                                        <tr>
                                                            <td>{++k}</td>
                                                            <td>
                                                                {fancyNameList[row.Session_id].name}
                                                            </td>
                                                            <td>{row.bitrate}</td>
                                                            <td>{row.run}</td>
                                                            <td style={{ color: row.Mode === "YES" ? "blue" : "red" }}>{row.Mode}</td>
                                                            <td>{row.Amount}</td>
                                                            <td>
                                                                {fancyNameList[row.Session_id].declareRun}
                                                            </td>
                                                            <td>{row.datetime}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>

                                            <tfoot>
                                                <tr><th colspan="5" class="text-center">{sPlusMinus > 0 ? 'Session Minus' : 'Session Plus'}</th>
                                                    <th colspan="3" class={sPlusMinus > 0 ? 'text-blue' : 'text-red'} >{sPlusMinus}</th>
                                                </tr></tfoot>

                                        </table>
                                    </div>
                                </div>

                            </div>
                            {/* <div class="alert alert-warning">
                                <h6>No Record Found</h6>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Displaymatchsession;
