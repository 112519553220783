import React, { useState, useEffect } from "react";
import { TableConst } from "../OLDconst/TableConst";
import { Link } from "react-router-dom";
import Util from "../libs/Util";
import Cookies from "universal-cookie";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import "../config/GlobalVariable";
import Checkbox from "../components/Checkbox";
import whatsapp from "../assets/img/1212.png";

const Mainclienttable = (props) => {
  const cookies = new Cookies();

  const [state] = useState(TableConst[props.clientType]);
  const [list, setList] = useState([]);

  const [downList, setDownList] = useState([]);

  const [show, setShow] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [sharer, setSharer] = useState({});
  const [downId, setDownId] = useState(null);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  useEffect(() => {
    (async () => {
      await fecthDetails();
      fecthDownDetails();
      const script = document.createElement("script");

      // loadJsFile(script);

      script.src = "/js/content.js";
      script.async = true;
      document.body.appendChild(script);

      document.body.removeChild(script);
    })();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShareHide = () => setShowShare(false);
  const handleShareShow = (userid, password) => {
    setSharer({ user: userid, pass: password });
    setShowShare(true);
  };

  const loadJsFile = async (script) => {
    script.src = "/js/content.js";
    script.async = true;
    document.body.appendChild(script);
  };

  const fecthDetails = async () => {
    let autho = await Util.formSubmit(state.fetchUrl, "POST", {
      token: cookies.get(global.CookiesVariable),
      rType: "SHOWALL",
    });
    if (autho.status == "TRUE") {
      setList(autho.data);
    }
  };

  const fecthDownDetails = async () => {
    let autho = await Util.formSubmit(state.downfetchUrl, "POST", {
      token: cookies.get(global.CookiesVariable),
      rType: "SHOWALL",
    });
    if (autho.status == "TRUE") {
      setDownList(autho.data);
    }
  };

  const handleStatus = async (status, user, userType) => {
    let response = await Util.formSubmit(state.inActiveButton, "POST", {
      token: cookies.get(global.CookiesVariable),
      status: status,
      userId: user,
      userType: userType,
    });

    if (response.status == "TRUE") {
      swal(`${response.msg}`, {
        icon: "success",
        timer: 2000,
      });
      fecthDetails();
    } else {
      swal(`${response.msg}`, {
        icon: "error",
        timer: 4000,
        dangerMode: true,
      });
    }
  };

  const handleOtherStatus = async (status, user, pUser, userType) => {
    let response = await Util.formSubmit(state.inActiveOtherButton, "POST", {
      token: cookies.get(global.CookiesVariable),
      status: status,
      userId: user,
      parentId: pUser,
      statusType: userType,
    });

    if (response.status == "TRUE") {
      swal(`${response.msg}`, {
        icon: "success",
        timer: 2000,
      });
      fecthDetails();
    } else {
      swal(`${response.msg}`, {
        icon: "error",
        timer: 4000,
        dangerMode: true,
      });
    }
  };

  const handleEvent = (e) => {
    e.preventDefault();

    console.log(e.target.value);
    console.log(e.target.dataset.clientid);
  };

  const AllActiveBtn = async () => {
    let response = await Util.formSubmit(state.allActive, "POST", {
      token: cookies.get(global.CookiesVariable),
      status: "ACTIVE",
    });

    if (response.status == "TRUE") {
      swal(`${response.msg}`, {
        icon: "success",
        timer: 2000,
      });
      fecthDetails();
    } else {
      swal(`${response.msg}`, {
        icon: "error",
        timer: 4000,
        dangerMode: true,
      });
    }
  };

  const AllDeActiveBtn = async () => {
    let response = await Util.formSubmit(state.allDeactive, "POST", {
      token: cookies.get(global.CookiesVariable),
      status: "ACTIVE",
    });

    if (response.status == "TRUE") {
      swal(`${response.msg}`, {
        icon: "success",
        timer: 2000,
      });
      fecthDetails();
    } else {
      swal(`${response.msg}`, {
        icon: "error",
        timer: 4000,
        dangerMode: true,
      });
    }
  };

  const clientChange = async (value) => setDownId(value);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.sno));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  return (
    <div className="content-wrapper" style={{ minHeight: 856.413 }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{state.title}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="#">Home</Link>
                </li>
                <li className="breadcrumb-item active">{state.title}</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form action="/client/allChange" method="post" id="demoForm">
                  <div className="card-header">
                    <div className="form-group">
                      {state.downfetchUrl == "" ? (
                        <Link
                          to={`/client/create-sub-admin`}
                          className="btn btn-primary"
                        >
                          New <i className="fa fa-plus-circle" />
                        </Link>
                      ) : (
                        <Link
                          to="#"
                          onClick={handleShow}
                          className="btn btn-primary"
                        >
                          New <i className="fa fa-plus-circle" />
                        </Link>
                      )}

                      <button
                        className="btn btn-success"
                        id="allActive"
                        type="submit"
                        name="check"
                        value={1}
                      >
                        All Active
                      </button>

                      <button
                        className="btn btn-danger"
                        id="allInActive"
                        type="submit"
                        name="check"
                        value={0}
                      >
                        All Deactive &nbsp;
                        <i className="fa fa-ban" />
                      </button>

                      {state.limitButton ? (
                        <Link
                          to={`${state.limitLink}/limit`}
                          className="btn btn-primary"
                          data="hello"
                        >
                          Limit Update
                        </Link>
                      ) : null}
                    </div>
                  </div>

                  <div className="card-body">
                    <table
                      id="example1"
                      class="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>
                            <div align="center">
                              <Checkbox
                                type="checkbox"
                                id="selectAll"
                                handleClick={handleSelectAll}
                                isChecked={isCheckAll}
                              />
                            </div>
                          </th>
                          <th>#</th>
                          <th>CODE</th>
                          <th>Name</th>
                          {props.clientType === "agent" ? <th>Super</th> : null}
                          {props.clientType === "client" ? (
                            <th>Agent</th>
                          ) : null}
                          <th>Password</th>
                          <th>Limit</th>
                          {/* <th>Commission</th>
                          <th>Share</th> */}
                          <th>Status</th>
                          {/*  */}
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((data, i) => {
                          let k = i;
                          return (
                            <tr>
                              <td>
                                <div align="center">
                                  <Checkbox
                                    key={data.sno}
                                    type="checkbox"
                                    id={data.sno}
                                    handleClick={handleClick}
                                    isChecked={isCheck.includes(data.sno)}
                                  />
                                </div>
                              </td>
                              <td>
                                <label> {++k}</label>
                                <div class="btn-group">
                                  <button
                                    type="button"
                                    class="btn btn-xs btn-outline-secondary dropdown-toggle dropdown-hover dropdown-icon"
                                    data-toggle="dropdown"
                                  >
                                    <span class="sr-only">Toggle Dropdown</span>
                                  </button>
                                  <div class="dropdown-menu" role="menu">
                                    <Link
                                      class="dropdown-item"
                                      to={`${state.editLink}/${data.userid}`}
                                    >
                                      Edit
                                    </Link>
                                    {props.clientType === "client" && (
                                      <Link
                                        class="dropdown-item"
                                        onClick={(event) => {
                                          handleShareShow(
                                            data["userid"],
                                            data.password
                                          );
                                        }}
                                      >
                                        Share <img src={whatsapp} />
                                      </Link>
                                    )}

                                    <span>
                                      <Link
                                        class="dropdown-item"
                                        to=""
                                        onClick={() =>
                                          handleStatus(
                                            data.living_status,
                                            data["userid"],
                                            state.statusType
                                          )
                                        }
                                      >
                                        {data.living_status == "FALSE"
                                          ? "Active"
                                          : "InActive"}
                                      </Link>
                                    </span>

                                    {!state.limitButton2 ? (
                                      <Link
                                        class="dropdown-item btn"
                                        to={`${state.childUrl}/limit/?id=${data.userid}`}
                                      >
                                        {state.downUser} Limit
                                      </Link>
                                    ) : null}
                                  </div>
                                </div>
                              </td>
                              <td>{data["userid"]}</td>
                              <td>{data.username}</td>
                              {props.clientType === "agent" ? (
                                <td>{data.upline}</td>
                              ) : null}
                              {props.clientType === "client" ? (
                                <td>{data.upline}</td>
                              ) : null}
                              <td>{data.password}</td>
                              <td>{data.limit}</td>
                              {/* <td>{data.m_comm}</td>
                              <td>{data.m_share}</td> */}
                              <td>
                                <div>
                                  {data.living_status != "FALSE" ? (
                                    <span class="float-center badge bg-success">
                                      Active
                                    </span>
                                  ) : (
                                    <span class="float-center badge bg-danger">
                                      InActive
                                    </span>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{state.downLine}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <select
              class="form-control"
              onChange={(e) => clientChange(e.target.value)}
            >
              <option value="null" disabled selected>
                Select...
              </option>
              {downList.map((row, i) => {
                return <option value={row.userid}>{row.useridX}</option>;
              })}
            </select>
          </form>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <button type="button" class="btn btn-default" onClick={handleClose}>
            Close
          </button>
          {downId == null ? (
            <Link to="#" type="button" class="btn btn-primary">
              Continue
            </Link>
          ) : (
            <Link
              to={`${state.formLink}/${downId}`}
              type="button"
              class="btn btn-primary"
            >
              Continue
            </Link>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={showShare} onHide={handleShareHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            Share by Whatsapp <img src={whatsapp} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(global.sites).map((key) => {
            return (
              <a
                className="btn btn-warning btn-block"
                href={
                  "whatsapp://send?text=" +
                  encodeURI(
                    global.sites[key] + " \n" + sharer.user + "\n" + sharer.pass
                  )
                }
              >
                {key}
              </a>
            );
          })}
        </Modal.Body>
        <Modal.Footer className="justify-content-between"></Modal.Footer>
      </Modal>
    </div>
  );
};

export default Mainclienttable;
