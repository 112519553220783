import { useState } from 'react'
import Util from '../libs/Util';
import { Link } from 'react-router-dom';
import Cookies from "universal-cookie";
import { setSession } from '../appRedux/actions/Auth';
import { useDispatch } from 'react-redux';


// import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";


const Login = () => {

    const dispatch = useDispatch();
    const cookies = new Cookies();

    const [state, setState] = useState({
        fields: { userid: "", password: "", authToken: "" },
        // errors: { userid: "Please input your ID", password: "Please Input your password" },
        // hasError: { userid: false, password: false },
        redirect: { isRedirect: false, navigateTo: "/dashboard" }
    });

    const [error, setError] = useState(false)


    const loginHandler = async (e) => {

        e.preventDefault();

        let response = await Util.formSubmit(`${global.MainClient}/login`, "POST", state.fields);

        if (response.status === "TRUE") {
            setError(false)
            cookies.set(`${global.CookiesVariable}`, response.token, { path: '/' });
            dispatch(setSession(response.userinfo));
            window.location = "/dashboard";
        }
        else {
            // alert(response.msg);
            setError(true)
        }
    }





    const stateHandler = (e) => {
        let fields = state.fields;
        fields[e.target.name] = e.target.value;
        setState({ ...state, fields });
    }

    const authToken = (tokenor) => {
        let fields = state.fields;
        fields.authToken = tokenor;
        setState({ ...state, fields });
    }

    return (
        // <GoogleReCaptchaProvider
        //     language="en-US"
        //     reCaptchaKey="6LepbiIeAAAAAPypfdhMjQacsg-HgN3dQMrt-8Og"
        // >
        <div class="hold-transition login-page">
            <div class="login-box">
                <div class="login-logo">
                    <Link to="#"><b>{global.siteName} </b>Admin</Link>
                </div>

                <div class="card">
                    <div class="card-body login-card-body">
                        <p class="login-box-msg">Sign in to start your session</p>
                        {
                            error ? <div>
                                <div class="alert alert-danger">
                                    <label>Username or Password is incorrect !!</label>
                                </div>
                            </div> : null
                        }

                        <form onSubmit={loginHandler.bind(this)}>
                            {/* <GoogleReCaptcha
                                    onVerify={token => { authToken(token); }}
                                /> */}
                            <div class="input-group mb-3">
                                <input
                                    type="text"
                                    class="form-control"
                                    name='userid'
                                    placeholder="username"
                                    onChange={stateHandler.bind(this)}
                                    required
                                    autoComplete='true'
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>

                            </div>

                            <div class="input-group mb-3">
                                <input
                                    type="password"
                                    class="form-control"
                                    name='password'
                                    placeholder="password"
                                    onChange={stateHandler.bind(this)}
                                    required
                                    autoComplete='true'
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>

                            </div>


                            <div class="row">
                                <div class="col-8">
                                    <div class="icheck-primary">
                                        <input type="checkbox" id="remember" />
                                        <label for="remember">
                                            Remember Me
                                        </label>
                                    </div>
                                </div>


                            </div>


                            <div class="social-auth-links text-center mb-3">

                                <div class="col-12">
                                    <button type="submit" class="btn btn-info btn-block">Login</button>
                                </div>
                            </div>

                        </form>


                    </div>

                </div>
            </div>
        </div>
        // </GoogleReCaptchaProvider >
    );
}

export default Login;
