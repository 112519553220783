import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Util from '../../../libs/Util';

export const MatkaPosition = () => {

  const cookies = new Cookies();
  const { id } = useParams();

  const [pana, setPana] = useState({});
  const [matkaName, setMatkaName] = useState("");

  const fetchPana = async() =>{

    let response = await Util.formSubmit(`${global.MainClient}/matka/getpana`,"POST",{

      "token": cookies.get(global.CookiesVariable),
        "id": id
    });

    console.log(response.data.result);
    if(response.status==="TRUE")
    {
      
      setPana(response.data.result);
      setMatkaName(response.data.gameName);
    }
  }


  useEffect(() => {
   
    (async()=>{

      await fetchPana();
    })();
    
  }, []);
  


  return (
    <>
    <div className='content-wrapper'>
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Matka Position</h1>
          </div>
        </div>
      </div>
    </div>

      <section className='content'>
     <div className="row">
  <div className="col-12">
    <div className="card">
      <div className="card-header text-center">
        <div className="container">
          <div className="row">
            <div className="col">
              <h5 className="text-bold">
                {matkaName}
              </h5>
            </div>
        
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="form-row">
         {

            Object.entries(pana).map(([key,row])=>{

              console.log(row);

            return(
              <div className="col-md-4">
              <table
                align="left"
                id="sessionsTable"
                className="table table-striped  table-hover table-bordered"
              >
                <thead>
                  <tr>
                    <th>{key.toUpperCase()}</th>
                    <th>PROFIT/LOSS</th>
                  </tr>
                </thead>
                <tbody>
                 {
                  Object.entries(row).map(([key2,row2])=>{


                    return(
                      <tr>
                      <td>{key2}</td>
                      <td style={{ color: (row2<0)?"red":"blue" }}>{row2}</td>
                    </tr>


                    )

                  })
                 
                 }
                  
                </tbody>
              </table>
            </div>

            )
              
           
  

            })
         
         
        
      }
        </div>
      </div>
    </div>
  </div>
</div>


      </section>

    </div>
    </>
  )
}
