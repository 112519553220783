import React from 'react';
import Util from '../libs/Util'
import Cookies from 'universal-cookie';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';


const Setting = () => {

    const cookies = new Cookies();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();



    const handleChangePass = async (data) => {

        let response = await Util.formSubmit(`${global.MainClient}/change_password`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "oldPassword": data.oldPassword,
            "newPassword": data.newPassword
        });

        if (response.status == "TRUE") {
            swal(`${response.msg}`, {
                icon: "success",
                timer: 2000
            });
        } else {
            swal(`${response.msg}`, {
                icon: "error",
                timer: 4000,
                dangerMode: true
            });
        }

    }

    return (
        <div className='content-wrapper'>
            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Setting</h1>
                        </div>
                    </div>
                </div>
            </div>
            {/* Card */}
            <section class="content">
                <div class="container-fluid">
                    <div class="card">
                        <div class="card-header">
                            <h3>Change Password</h3>
                        </div>
                        <div class="card-body">
                            <form onSubmit={handleSubmit(handleChangePass.bind(this))}
                                className="form form-horizontal"
                                style={{ marginTop: 30, marginLeft: 5 }}>

                                <div className="row">

                                    <div className="col-md-6 col-xl-6">
                                        <div class="form-group row">
                                            <label for="inputPassword" class="col-sm-2 col-form-label">Old Password</label>
                                            <div class="col-sm-10">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="inputPassword"
                                                    placeholder="Password"
                                                    name='oldPassword'
                                                    {...register('oldPassword', { required: true })} />
                                                {errors.oldPassword && <p style={{ color: "red" }}>Old password is required.</p>}
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-6 col-xl-6">

                                        <div class="form-group row">
                                            <label for="inputPassword" class="col-sm-3 col-form-label">New Password</label>
                                            <div class="col-sm-9">
                                                <input
                                                    type="password"
                                                    class="form-control"
                                                    id="inputPassword"
                                                    placeholder="Password"
                                                    name='newPassword'
                                                    {...register('newPassword', { required: true })} />
                                                {errors.newPassword && <p style={{ color: "red" }}>New password is required.</p>}
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="row">
                                    <div className="col col-12 gx-text-right">
                                        <button type="submit" className="btn btn-primary">
                                            <span>Submit</span>
                                        </button>
                                    </div>
                                </div>


                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Setting;
