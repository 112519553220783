import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import Util from '../../../../libs/Util';
import { Link, useParams } from "react-router-dom";
import Checkbox from "../../../../components/Checkbox";
import { setSportPlusMinus } from '../../../../appRedux/actions/Auth';
import { useDispatch } from 'react-redux';




const Msplusminus = () => {

    const cookies = new Cookies();
    const { id } = useParams();
    const dispatch = useDispatch();



    const [fancyList, setFancyList] = useState([]);
    const [clientList, setClient] = useState([]);

    // first time check all
    const [isCheckFirstTime, setIsCheckFirstTime] = useState(true);

    // Session Checked
    const [isCheckAllSession, setIsCheckAllSession] = useState(false);
    const [isCheckSession, setIsCheckSession] = useState([]);

    // Client Checked
    const [isCheckAllUser, setIsCheckAllUser] = useState(false);
    const [isCheckUser, setIsCheckUser] = useState([]);


    useEffect(() => {
        fetchPlusMinus();
    }, []);

    const fetchPlusMinus = async () => {
        let response = await Util.formSubmit(`${global.MainClient}/cricket/plusminus_pre`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "matchId": id
        });

        if (response.status === "TRUE") {
            setFancyList(response.data.FANCY);
            setClient(response.data.USERS);

            setIsCheckAllSession(!isCheckAllSession);
            setIsCheckSession(response.data.FANCY.map(li => li.sessionId));
            if (isCheckAllSession) {
                setIsCheckSession([]);
            }

            setIsCheckAllUser(!isCheckAllUser);
            setIsCheckUser(response.data.USERS.map(li => li.userid));
            if (isCheckAllUser) {
                setIsCheckUser([]);
            }

        }

    }


    const handleSelectAllSession = () => {

        setIsCheckAllSession(!isCheckAllSession);
        setIsCheckSession(fancyList.map(li => li.sessionId));
        if (isCheckAllSession) {
            setIsCheckSession([]);
        }
    }

    const handleSelectAllUser = () => {
        setIsCheckAllUser(!isCheckAllUser);
        setIsCheckUser(clientList.map(li => li.userid));
        if (isCheckAllUser) {
            setIsCheckUser([]);
        }
    }


    const handleClickSession = e => {

        const { id, checked } = e.target;
        setIsCheckSession([...isCheckSession, id]);
        if (!checked) {
            setIsCheckSession(isCheckSession.filter(item => item !== id));
        }
    };


    const handleClickUser = e => {

        const { id, checked } = e.target;
        setIsCheckUser([...isCheckUser, id]);
        if (!checked) {
            setIsCheckUser(isCheckUser.filter(item => item !== id));
        }
    };


    // console.log("isCheckUser", isCheckUser);
    // console.log("isCheckSession", isCheckSession);

    const handleShow = async () => {
        dispatch(setSportPlusMinus([{ "USERS": isCheckUser }, { "FANCY": isCheckSession }]));
    }



    return (
        <div className='content-wrapper'>
            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Match PlusMinus</h1>
                        </div>
                    </div>
                </div>
            </div>

            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">

                            <div class="card">


                                <div class="card-header text-center">
                                    <div class="container">
                                        <div class="row">

                                            <div class="col">
                                                <h5 class="text-bold text-white">Admin OF : WARRIORS V BOLAND (T-20)</h5>
                                            </div>

                                            <div class="col">

                                                <Link
                                                    to={`/Events/${id}/plusMinusSelect/report`}
                                                    onClick={() => { handleShow() }}
                                                >
                                                    <button class="btn btn-primary">Show</button>
                                                </Link>
                                            </div>
                                        </div>

                                    </div>


                                </div>

                                <div class="card-body">


                                    <div class="form-row">

                                        <div class="col-md-8">
                                            <table align="left" id="sessionsTable" class="table table-striped  table-hover table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th width="15%">
                                                            <div align="center">

                                                                <Checkbox
                                                                    type="checkbox"
                                                                    handleClick={handleSelectAllSession}
                                                                    isChecked={isCheckAllSession}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th>
                                                            SESSION (POST DATE)
                                                        </th>
                                                        <th>
                                                            DECLARE
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fancyList.map(({ sessionId, Name, run }) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <div align="center">

                                                                        <Checkbox
                                                                            type="checkbox"
                                                                            name={Name}
                                                                            id={sessionId}
                                                                            handleClick={handleClickSession}
                                                                            isChecked={isCheckSession.includes(sessionId)}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>{Name}</td>
                                                                <td style={{ color: "red" }}>{run}</td>
                                                            </tr>
                                                        );
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="col-md-4">
                                            <table align="left" id="clientTable" class="table table-striped  table-hover table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th width="15%">
                                                            <div align="center">

                                                                <Checkbox
                                                                    type="checkbox"
                                                                    handleClick={handleSelectAllUser}
                                                                    isChecked={isCheckAllUser}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th>
                                                            Admin (CODE NAME)
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {clientList.map((row) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <div align="center">

                                                                        <Checkbox
                                                                            type="checkbox"
                                                                            name={row.username}
                                                                            id={row.userid}
                                                                            handleClick={handleClickUser}
                                                                            isChecked={isCheckUser.includes(row.userid)}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>{row.userid} {row.username}</td>
                                                            </tr>
                                                        );
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>


                                    </div>

                                </div>


                            </div>


                        </div>

                    </div>
                </div>
            </section>

        </div>
    );
}

export default Msplusminus;
