import '../config/GlobalVariable';

export const TableConst = {

    subadmin: {
        title: "Sub Admin",
        upLine: "Admin",
        downLine: "Sub Admin",
        fetchUrl: `${global.MainClient}/fetch-sub-admin-details`,
        formLink: "/client/create-sub-admin",
        limitLink: "/sub-admin",
        childUrl: "/master",
        downUser: "master",
        limitButton: true,
        downfetchUrl: ``,
        limitButton2: false,
        editLink: "/client/update-subadmin",
        inActiveButton: `${global.MainClient}/user/update-status`,
        inActiveOtherButton: `${global.MainClient}/user/update-other-status`,
        statusType: "SUBADMIN",
        allActive: `${global.MainClient}/subadmin/all-active`,
        allDeactive: `${global.MainClient}/subadmin/all-deactive`
    },


    // superMaster: {
    //     title: "Super Master",
    //     upLine: "Sub Admin",
    //     downLine: "Super Master",
    //     fetchUrl: `${global.MainClient}/fetch-super-master-details`,
    //     formLink: "/client/create-super-master",
    //     limitLink: "/super-master",
    //     childUrl: "/master",
    //     downUser: "master",
    //     limitButton: false,
    //     limitButton2: false,
    //     editLink: "/client/update-supermaster",
    //     inActiveButton: `${global.MainClient}/user/update-status`,
    //     downfetchUrl: `${global.MainClient}/fetch-sub-admin`,
    //     statusType: "SUPERMASTER",
    //     allActive: `${global.MainClient}/supermaster/all-active`,
    //     allDeactive: `${global.MainClient}/supermaster/all-deactive`
    // },


    master: {
        title: "Master",
        upLine: "SubAdmin",
        downLine: "Master",
        fetchUrl: `${global.MainClient}/fetch-master-details`,
        formLink: "/client/create-master",
        limitLink: "/master",
        childUrl: "/super-agent",
        downUser: "super agent",
        limitButton: false,
        limitButton2: false,
        editLink: "/client/update-master",
        inActiveButton: `${global.MainClient}/user/update-status`,
        inActiveOtherButton: `${global.MainClient}/user/update-other-status`,
        downfetchUrl: `${global.MainClient}/fetch-sub-admin`,
        statusType: "MASTER",
        allActive: `${global.MainClient}/master/all-active`,
        allDeactive: `${global.MainClient}/master/all-deactive`
    },

    superAgent: {
        title: "Super Agent",
        upLine: "Master",
        downLine: "Super Agent",
        fetchUrl: `${global.MainClient}/fetch-super-agent-details`,
        formLink: "/client/create-super-agent",
        limitLink: "/super-agent",
        childUrl: "/agent",
        downUser: "agent",
        limitButton: false,
        limitButton2: false,
        editLink: "/client/update-superagent",
        inActiveButton: `${global.MainClient}/user/update-status`,
        inActiveOtherButton: `${global.MainClient}/user/update-other-status`,
        downfetchUrl: `${global.MainClient}/fetch-master`,
        statusType: "SUPERAGENT",
        allActive: `${global.MainClient}/superagent/all-active`,
        allDeactive: `${global.MainClient}/superagent/all-deactive`
    },

    agent: {
        title: "Agent",
        upLine: "Super Agent",
        downLine: "Agent",
        fetchUrl: `${global.MainClient}/fetch-agent-details`,
        formLink: "/client/create-agent",
        limitLink: "/agent",
        childUrl: "/client",
        downUser: "client",
        limitButton: false,
        limitButton2: false,
        editLink: "/client/update-agent",
        inActiveButton: `${global.MainClient}/user/update-status`,
        inActiveOtherButton: `${global.MainClient}/user/update-other-status`,
        downfetchUrl: `${global.MainClient}/fetch-super-agent`,
        statusType: "AGENT",
        allActive: `${global.MainClient}/agent/all-active`,
        allDeactive: `${global.MainClient}/agent/all-deactive`
    },
    client: {
        title: "Client",
        upLine: "Agent",
        downLine: "Client",
        fetchUrl: `${global.MainClient}/fetch-client-details`,
        formLink: "/client/create",
        editLink: "/client/update-client",
        limitButton: false,
        limitButton2: true,
        inActiveButton: `${global.MainClient}/user/update-status`,
        inActiveOtherButton: `${global.MainClient}/user/update-other-status`,
        downfetchUrl: `${global.MainClient}/fetch-agent`,
        statusType: "CLIENT",
        allActive: `${global.MainClient}/client/all-active`,
        allDeactive: `${global.MainClient}/client/all-deactive`
    }


}























