import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Util from '../libs/Util';
import Cookies from "universal-cookie";
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { LoginReportConst } from '../OLDconst/LoginReport';


const MainLoginReport = (props) => {


    const cookies = new Cookies();

    const [preData] = useState(LoginReportConst[props.clientType]);

    const [state, setState] = useState({
        isClearable: true,
        isDisabled: false,
        isLoading: false,
        isRtl: false,
        isSearchable: true,
    });

    const [fields, setFields] = useState({
        client: "",
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        idName: ""
    });
    const [options, setOptions] = useState([]);
    const [limitData, setLimitData] = useState([]);


    useEffect(() => {
        fetchClient();
    }, []);


    const fetchClient = async () => {

        let response = await Util.formSubmit(preData.fetchClientUrl, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "rType": "SHOWALL"
        });

        if (response.status === "TRUE") {
            let arr = [];
            for (const iterator of response.data) {
                let obj = {};
                obj.label = iterator.userid + " " + iterator.username;
                obj.value = iterator.userid;
                arr.push(obj);
            }
            setOptions(arr);
        }

    }

    const onSubmit = async (e) => {

        e.preventDefault();

        console.log(fields);

        let response = await Util.formSubmit(preData.fetchLoginReportUrl, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "clientId": fields.client,
            "from": fields.from,
            "to": fields.to
        });

        if (response.status === "TRUE") {
            setLimitData(response.data)
        }

    }

    return (
        <div className='content-wrapper'>

            {/* Card */}
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">

                            <div class="card">

                                <form onSubmit={onSubmit}>

                                    <div class="card-header bg-gradient-purple">

                                        <div class="form-row">
                                            <div class="form-group col-md-3">
                                                <label for="name"> {preData.title} </label>
                                                <Select
                                                    className="basic-single bg-white"
                                                    classNamePrefix="select"
                                                    defaultValue={options[0]}
                                                    isDisabled={state.isDisabled}
                                                    isLoading={state.isLoading}
                                                    isClearable={state.isClearable}
                                                    isRtl={state.isRtl}
                                                    isSearchable={state.isSearchable}
                                                    name="client_id"
                                                    options={options}
                                                    onChange={(e) => setFields({ ...fields, client: e.value, idName: e.label })}
                                                />
                                            </div>


                                            <div class="form-group col-md-3">
                                                <div class="form-group">
                                                    <Form.Group controlId="dob">
                                                        <Form.Label>Date From</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name="dateFrom"
                                                            defaultValue={fields.from}
                                                            onChange={(e) => setFields({ ...fields, from: e.target.value })}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>




                                            <div class="form-group col-md-3">
                                                <div class="form-group">
                                                    <Form.Group controlId="dob">
                                                        <Form.Label>Date To</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name="dateTo"
                                                            defaultValue={fields.to}
                                                            onChange={(e) => setFields({ ...fields, to: e.target.value })} />
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-3">

                                                <label class="control-label text-purple" for="btn">`</label>
                                                <input type="submit" class="form-control btn-primary" id="btn" name="submit" value="Submit" />

                                            </div>


                                        </div>


                                    </div>

                                    <div class="card-body">
                                        <table id="example1" class="table table-bordered table-striped">
                                            <thead class="bg-gradient-yellow">
                                                <tr>

                                                    <th>#</th>
                                                    <th>Code</th>
                                                    <th>Login Name</th>
                                                    <th>IPAddress</th>
                                                    <th>DateTime</th>
                                                    <th>Last Activity</th>

                                                </tr>
                                            </thead>
                                            <tbody>





                                            </tbody>
                                            <tfoot>

                                            </tfoot>
                                        </table>
                                    </div>
                                </form>

                            </div>


                        </div>
                    </div>
                </div>
            </section >
        </div >
    );
}

export default MainLoginReport;
