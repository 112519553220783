export const LedgerHelper = {

    subadmin: {
        title: "Sub Admin",
        getUrl: `${global.MainClient}/ledger/lenadena/sub-admin`
    },
    // superMaster: {
    //     title: "Super Master",
    //     getUrl: `${global.MainClient}/ledger/lenadena/super-master`
    // },
    master: {
        title: "Master",
        getUrl: `${global.MainClient}/ledger/lenadena/master`
    },
    superAgent: {
        title: "Super Agent",
        getUrl: `${global.MainClient}/ledger/lenadena/super-agent`
    },
    agent: {
        title: "Agent",
        getUrl: `${global.MainClient}/ledger/lenadena/agent`
    },
    client: {
        title: "Client",
        getUrl: `${global.MainClient}/ledger/lenadena/client`,
        getUrls: `${global.MainClient}/ledger/plusminus/client`,
        getClient: `${global.MainClient}/ledger/client`,
    }
}