import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formHelper } from "../OLDconst/FormConst";
import Util from "../libs/Util";
import { useSelector } from "react-redux";

export default function MainClientForm(props) {
  // console.log(props);

  let cookies = new Cookies();
  const { id } = useParams();
  const navigate = useNavigate();
  const clientIp = useSelector((state) => state.ip);

  const [formField, setFormField] = useState({
    name: "",
    reference: "",
    myCoin: "",
    coins: "",
    password: "",
    matchShare: "",
    myMatchShare: "",
    mobShare: "",
    myMobShare: "",
    matchComm: "0",
    myMatchComm: "",
    sessComm: "0",
    mySessComm: "",
    casinoShare: "0",
    myCasinoShare: "0",
    casinoComm: "0",
    myCasinoComm: "0",
    matkaShare: "0",
    myMatkaShare: "0",
    matkaComm: "0",
    myMatkaComm: "0",
    vShare: "false",
    token: null,
    mUserId: null,
    clientIp: clientIp,
    agentShareonClient: "",
  });

  const [shcm, setShcm] = useState([]);
  const [defaultShcm, setDefaultShcm] = useState([]);
  const [errorShcm, setErrorShcm] = useState([]);

  const [formPreData] = useState(formHelper[props.clientType]);
  const [userMain, setUserMain] = useState(null);
  const [doesEditForm] = useState(props.type);

  useEffect(() => {
    auther();
  }, []);

  const handleGeneratePassword = () =>
    setFormField({ ...formField, password: generatePassword() });

  const handleField = (e) =>
    setFormField((prev) => ({ ...prev, [e.target.id]: e.target.value }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errorfields = errorShcm.filter(
      (row) => row.errorComm == true || row.errorShare == true
    );
    //    console.log(errorfields);
    if (errorfields.length > 0) {
      return false;
    }

    try {
      let retData = await Util.formSubmit(
        doesEditForm === undefined
          ? formPreData.createUrl
          : formPreData.editLink,
        "POST",
        {
          ...formField,
          token: cookies.get(global.CookiesVariable),
          mUserId: userMain,
          shareComm: shcm,
        }
      );

      if (retData.userid !== "undefined") navigate(formPreData.redirectLink);
    } catch (error) {
      console.log(error);
    }
  };

  const auther = async () => {
    setUserMain(id);

    let autho = await Util.formSubmit(formPreData.authUrl, "POST", {
      token: cookies.get(global.CookiesVariable),
      auth_for: id,
    });

    if (props.clientType === "agent") {
      setFormField((formField) => ({
        ...formField,
        myCoin: autho.coins,
        myMatchShare: autho.share,
        myCasinoShare: autho.casinoShare,
        myMatkaShare: autho.matkaShare,
        myMobShare: autho.mobShare,
        myMatchComm: autho.mcomm,
        mySessComm: autho.scomm,
        myCasinoComm: autho.casinoComm,
        myMatkaComm: autho.matkaComm,
        flatShare: "NO",
        agentShareonClient: autho.share,
      }));
    } else {
      setFormField((formField) => ({
        ...formField,
        myCoin: autho.coins,
        myMatchShare: autho.share,
        myCasinoShare: autho.casinoShare,
        myMatkaShare: autho.matkaShare,
        myMobShare: autho.mobShare,
        myMatchComm: autho.mcomm,
        mySessComm: autho.scomm,
        myCasinoComm: autho.casinoComm,
        myMatkaComm: autho.matkaComm,
        agentShareonClient: autho.share,
      }));
    }

    setShcm([...autho.shcm]);
    setDefaultShcm(JSON.parse(JSON.stringify(autho.shcm)));
    setErrorShcm(
      Array.from(Array(autho.shcm.length), (_, i) => ({
        errorComm: false,
        msgComm: "",
        errorShare: false,
        msgShare: "",
      }))
    );
  };

  function generatePassword() {
    let digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
  }

  const handleCommissionType = (e) => {
    if (e.target.value === "NO_COMM") {
      setFormField((formField) => ({
        ...formField,
        matchComm: 0,
        sessComm: 0,
        casinoComm: 0,
        matkaComm: 0,
      }));
    }

    if (e.target.value === "BET_BY_BET") {
      setFormField((formField) => ({
        ...formField,
        matchComm: formField.myMatchComm,
        sessComm: formField.mySessComm,
        casinoComm: formField.myCasinoComm,
        matkaComm: formField.myMatkaComm,
      }));
    }
  };

  const changeComm = (index, eve) => {
    let temp_shcm = shcm;
    let error_shcm = errorShcm;
    let freshComm = isNaN(eve.target.value) ? 0 : eve.target.value;
    let prevComm = defaultShcm[index].comm;

    if (
      parseFloat(freshComm) > parseFloat(prevComm) ||
      parseFloat(freshComm) < 0
    ) {
      //alert("Comm should not more than " + prevComm);
      error_shcm[index].errorComm = true;
      error_shcm[index].msgComm = "Comm should be between 0 to  " + prevComm;
      freshComm = prevComm;
    } else {
      error_shcm[index].errorComm = false;
      error_shcm[index].msgComm = "";
    }

    temp_shcm[index].comm = freshComm;
    temp_shcm[index].j_rate = parseInt(
      100 - (temp_shcm[index].j_rate / 100) * freshComm
    ); //.toFixed(1);
    temp_shcm[index].h_rate = parseFloat(
      10 - (temp_shcm[index].h_rate / 100) * freshComm
    ).toFixed(1);

    //console.log(temp_shcm);

    setShcm([...temp_shcm]);
    setErrorShcm([...errorShcm]);
  };

  const changeShare = (index, eve) => {
    let temp_shcm = shcm;
    let error_shcm = errorShcm;

    let prevShare = defaultShcm[index].share;
    let freshShare = eve.target.value;

    // console.log(freshShare,prevShare);
    // console.log(parseFloat(freshShare) > parseFloat(prevShare));
    if (
      parseFloat(freshShare) > parseFloat(prevShare) ||
      parseFloat(freshShare) < 0
    ) {
      error_shcm[index].errorShare = true;
      error_shcm[index].msgShare = "Share should not more than " + prevShare;

      freshShare = prevShare;
    } else {
      error_shcm[index].errorShare = false;
      error_shcm[index].msgShare = "";
    }

    temp_shcm[index].share = freshShare;

    //  console.log(temp_shcm);

    setShcm([...temp_shcm]);
    setErrorShcm([...errorShcm]);
  };

  return (
    <div className="content-wrapper" style={{ minHeight: 856.413 }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{`${doesEditForm !== undefined ? "Edit" : "Create"} ${
                formPreData.downLine
              }`}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  New {formPreData.downLine}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        {/* <div>
                    <div class="alert alert-danger">
                        <ul>
                            <li>Size SuperAgent Name min length 2 or max 30</li>
                        </ul>
                    </div>
                </div> */}

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div
              className={
                props.clientType !== "client" ? "col-md-6" : "col-md-12"
              }
            >
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">General</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      data-toggle="tooltip"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="code">Code</label>
                    <input
                      type="text"
                      id="code"
                      placeholder
                      readOnly
                      className="form-control"
                      name="code"
                      defaultValue="Auto Generated"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      placeholder="Name"
                      required
                      readOnly={doesEditForm === undefined ? false : true}
                      value={formField.name}
                      onChange={handleField}
                    />
                    <small className="form-text text-muted">
                      Minimum 3 Letters Required *
                    </small>
                  </div>

                  <div class="form-group">
                    <label for="name">Reference</label>
                    <input
                      type="text"
                      id="reference"
                      class="form-control"
                      placeholder="Reference"
                      required
                      name="reference"
                      value={formField.reference}
                      onChange={handleField}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div className="input-group ">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Password"
                        id="password"
                        required
                        name="password"
                        defaultValue={formField.password}
                        value={formField.password}
                        onChange={handleField}
                      />
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-info btn-flat"
                          onClick={handleGeneratePassword}
                        >
                          Generate Password
                        </button>
                      </span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div class="form-group col-md-6">
                      <label for="share">{formPreData.downLine} Limit</label>
                      <input
                        type="number"
                        max={formField.myCoin}
                        min="0"
                        placeholder={formPreData.downLine + " Limit"}
                        class="form-control"
                        id="coins"
                        step="0.01"
                        required
                        value={formField.coins}
                        onChange={handleField}
                      />
                    </div>

                    <div class="form-group col-md-6">
                      <label for="mshare">My Limit</label>
                      <input
                        type="number"
                        placeholder="Limit"
                        class="form-control"
                        id="myCoin"
                        readonly=""
                        defaultValue={formField.myCoin}
                        value={formField.myCoin}
                        onChange={handleField}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card card-warning">
                <div className="card-header">
                  <h3 className="card-title">Rate & Commission</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      data-toggle="tooltip"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="share card-body">
                  <div className="row">
                    <div className="col-3">
                      <label> Game Name </label>
                    </div>
                    <div className="col-3">
                      <label>J. Rate</label>
                    </div>
                    <div className="col-2">
                      <label>H. Rate</label>
                    </div>
                    <div className="col-2">
                      <label>Share</label>
                    </div>
                    <div className="col-2">
                      <label>Comm.</label>
                    </div>
                  </div>
                  {shcm.length > 0 &&
                    shcm.map((row, i) => {
                      return (
                        <>
                          <div className="row" key={i}>
                            <div className="col-3">
                              <input
                                className="form-control"
                                readOnly
                                value={row.matka_name}
                              />
                            </div>
                            <div className="col-3">
                              <input
                                className="form-control"
                                value={row.j_rate}
                                readOnly
                              />
                            </div>
                            <div className="col-2">
                              <input
                                className="form-control"
                                value={row.h_rate}
                                readOnly
                              />
                            </div>
                            <div className="col-2">
                              <input
                                className="form-control"
                                defaultValue={row.share}
                                onChange={(event) => {
                                  changeShare(i, event);
                                }}
                              />
                            </div>
                            <div className="col-2">
                              <input
                                className="form-control"
                                defaultValue={row.comm}
                                onChange={(event) => {
                                  changeComm(i, event);
                                }}
                              />
                            </div>
                          </div>
                          {(errorShcm[i]?.errorComm ||
                            errorShcm[i]?.errorShare) && (
                            <div className="row">
                              <div className="col-md-12">
                                <span
                                  className="error"
                                  style={{ color: "red" }}
                                >
                                  {errorShcm[i]?.msgComm +
                                    "," +
                                    errorShcm[i]?.msgShare}
                                </span>
                              </div>
                            </div>
                          )}
                          <br />
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Link
                to="#"
                onClick={() => navigate(-1)}
                className="btn btn-secondary"
              >
                Cancel
              </Link>
              <button
                type="submit"
                className="btn btn-success float-right"
                id="btn"
              >
                Create New {formPreData.downLine}
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
