import '../config/GlobalVariable';

export const ReportConst = {
    subadmin: {
        title: "Sub admin",
        cType: "SUBADMIN",
        fetchClientUrl: `${global.MainClient}/fetch-sub-admin-details`,
        fetchReportUrl: `${global.MainClient}/report`,
    },
    // superMaster: {
    //     title: "Super master",
    //     cType: "SUPERMASTER",
    //     fetchClientUrl: `${global.MainClient}/fetch-super-master-details`,
    //     fetchReportUrl: `${global.MainClient}/report`,
    // },
    master: {
        title: "Master",
        cType: "MASTER",
        fetchClientUrl: `${global.MainClient}/fetch-master-details`,
        fetchReportUrl: `${global.MainClient}/report`,
    },
    superAgent: {
        title: "Super agent",
        cType: "SUPERAGENT",
        fetchClientUrl: `${global.MainClient}/fetch-super-agent-details`,
        fetchReportUrl: `${global.MainClient}/report`,
    },
    agent: {
        title: "agent",
        cType: "AGENT",
        fetchClientUrl: `${global.MainClient}/fetch-agent-details`,
        fetchReportUrl: `${global.MainClient}/report`,
    },
    client: {
        title: "Client",
        cType: "CLIENT",
        fetchClientUrl: `${global.MainClient}/fetch-client-details`,
        fetchReportUrl: `${global.MainClient}/report`,
    }
}























