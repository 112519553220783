import { useState, useEffect } from 'react';
import Util from '../../../../libs/Util';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { useParams, useNavigate, Link } from "react-router-dom";

var fancyBetsListTimeOut = null;
var cricketTimeOuts = null;
var matchPositionTimeOut = null;
var oddsBetsListTimeOut = null;
var fancyBetsPositionListTimeOut = null;

const Msposition = () => {

    const cookies = new Cookies();
    const { id } = useParams();
    const navigate = useNavigate();


    const [liveMatchesOdds, setLiveMatchesOdds] = useState([]);
    const [liveMatchesFancy, setLiveMatchesFancy] = useState([]);
    const [score, setScore] = useState({});

    const [liveMatchPosition, setLiveMatchPosition] = useState({});

    const [showOddsTable, setShowOddsTable] = useState(true);

    const [oddsBetsList, setOddsBetsList] = useState([]);
    const [oddsBetsTeamList, setOddsBetsTeamList] = useState({});

    const [plusMinusList, setPlusMinusList] = useState([]);

    const [sessionName, setSessionName] = useState("");

    const [fancyBetsList, setFancyBetsList] = useState([]);

    const [fancyBetsPositionList, setFancyBetsPositionList] = useState([]);

    const [cricketInit, setCricketInit] = useState({});

    const [sessionHighList, setSessionHighList] = useState(null);

    useEffect(() => {

        fetchCricketInit();
        fetchMatchesReportLive();
        fetchMatchPosition();
        fetchOddsBetsList();
        cricketTimeOuts = setInterval(() => { fetchMatchesReportLive(); }, 200);
        matchPositionTimeOut = setInterval(() => { fetchMatchPosition(); }, 2000)
        oddsBetsListTimeOut = setInterval(() => { fetchOddsBetsList(); }, 2000)
        PlusMinusPostion();

        return () => {
            clearInterval(cricketTimeOuts);
            clearInterval(matchPositionTimeOut);
            clearInterval(oddsBetsListTimeOut);
            clearInterval(fancyBetsListTimeOut);
            clearInterval(fancyBetsPositionListTimeOut);
        };
    }, []);


    const fetchCricketInit = async () => {

        let response = await Util.formSubmit("data/cricket/init", "POST", {
            "token": cookies.get(global.CookiesVariable),
            "matchId": id
        });

        if (response.status === "TRUE") {
            setCricketInit(response.data.mdetails)
            if (response.data.mdetails.DeclareStatus === "DECLARE")
                navigate("/client/sport-details");
        }

    }


    const fetchMatchesReportLive = async () => {

        let response = await Util.formSubmit("data/cricket", "POST", {
            "token": cookies.get(global.CookiesVariable),
            "matchId": id
        });

        if (response.status === "TRUE") {
            setLiveMatchesOdds(response.data.ODDS);
            setLiveMatchesFancy(response.data.FANCY);
            setScore(response.data.SCORE);
        }

    }


    const fetchMatchPosition = async () => {
        let response = await Util.formSubmit(`${global.MainClient}/cricket/match_position`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "matchId": id
        });

        if (response.status === "TRUE") {
            setLiveMatchPosition(response.data)
        }

    }

    const handleShowTableOddsClick = () => {

        setSessionHighList(null);

        clearInterval(oddsBetsListTimeOut);
        setShowOddsTable(true)
        oddsBetsListTimeOut = setInterval(() => { fetchOddsBetsList(); }, 2000)
        clearInterval(fancyBetsListTimeOut);
    }

    const fetchOddsBetsList = async () => {

        let response = await Util.formSubmit(`${global.MainClient}/cricket/odds/list`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "matchId": id
        });

        if (response.status === "TRUE") {
            setOddsBetsList(response.data.data);
            setOddsBetsTeamList(response.data.Teams);
        }

    }

    const PlusMinusPostion = async () => {

        let response = await Util.formSubmit(`${global.MainClient}/cricket/plusminus_position`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "matchId": id
        });

        if (response.status === "TRUE") {
            setPlusMinusList(response.data);
        }

    }



    const handleShowTableFancyClick = async (sessionId, sessionName) => {

        setSessionHighList(sessionId);
        // clearInterval(matchPositionTimeOut);
        clearInterval(fancyBetsListTimeOut);
        clearInterval(fancyBetsPositionListTimeOut);

        setSessionName(sessionName);
        setShowOddsTable(false);
        fancyBetsListTimeOut = setInterval(() => { fetchOddsFancyList(sessionId); }, 2000);
        fancyBetsPositionListTimeOut = setInterval(() => { fetchFancyPositionList(sessionId); }, 500);

        clearInterval(oddsBetsListTimeOut);

    }


    const fetchOddsFancyList = async (sessionId) => {

        let response = await Util.formSubmit(`${global.MainClient}/cricket/fancy/list`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "matchId": id,
            "sessionId": sessionId
        });

        if (response.status === "TRUE") {
            setFancyBetsList(response.data)
        }

    }


    const fetchFancyPositionList = async (sessionId) => {

        let response = await Util.formSubmit(`${global.MainClient}/cricket/fancy_position`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "matchId": id,
            "sessionId": sessionId
        });

        if (response.status === "TRUE") {
            setFancyBetsPositionList(response.data)
        }
    }

    let plushMinus = 0;

    return (
        <div className='content-wrapper'>
            {/* Header */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Session Position</h1>
                        </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-secondary text-center">

                                <h5 className="card-header" id="scoreMsg">{cricketInit.MatchName}</h5>

                                <div className="card-body">
                                    <div className="row">



                                        <tr>
                                            <td valign="top">

                                                <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                                                    <tbody>


                                                        <tr>
                                                            <td align="left" valign="top">
                                                                <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                                                                    <tbody>

                                                                        <tr>

                                                                            <td width="35%" height={35} align="center" className="TeamCombo">
                                                                                <div className="ScoreCard">
                                                                                    <p>
                                                                                        <span id="LocalPlayer" > {score.Player0} ( {score.RUN0} ) </span><br />
                                                                                        <span id="VisitorPlayer" > {score.Player1} ( {score.RUN1} ) </span><br />
                                                                                        BOWLER : <span id="Status" /><br />
                                                                                    </p>
                                                                                </div>
                                                                            </td>

                                                                            <td width="45%" height={35} align="center" className="TeamCombo">
                                                                                <div className="ScoreCard">
                                                                                    <p>
                                                                                        <span id="LocalTeam" > {score.TEAM0} {score.R0}/{score.W0} ({score.O0}) </span><br />
                                                                                        <span id="VisitorTeam" >  {score.TEAM1} {score.R1}/{score.W1} ({score.O1}) </span> <br />
                                                                                        {/* 6 BALLS : <span id="RecentBall" > {score.balls6} </span><br /> */}
                                                                                        <span id="RecentBall" > {score.balls6} </span><br />
                                                                                    </p>
                                                                                </div>
                                                                            </td>

                                                                            <td width="20%" height={35} align="center" className="TeamCombo">
                                                                                <div className="ScoreCard">
                                                                                    <p>
                                                                                        <span id="Image">
                                                                                            <img src={`/assets/images/${score.com_signal}`} alt="Ball" id="sim" width={53} height={60} />
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                    </tbody >
                                                                </table >
                                                            </td >
                                                        </tr >


                                                    </tbody >
                                                </table >
                                            </td >
                                        </tr >



                                        <div class="remark" style={{
                                            width: "100%",
                                            background: "yellow",
                                            border: "1px solid red",
                                            fontSize: 14,
                                            fontWeight: 700,
                                            overflow: "hidden",
                                            textAlign: "center"
                                        }}>{score[score.remarkStatus]}</div>

                                    </div>
                                </div>


                                <div className="form-row">
                                    <div className="col-md-6">
                                        <table className="table table-bordered" id="mOdds">
                                            <thead>
                                                <tr>
                                                    <th className='text-center'> Team</th>
                                                    <th className="text-red text-center" style={{ width: 40 }}> KHAI</th>
                                                    <th className="text-blue text-center" style={{ width: 40 }}> LAGAI</th>
                                                    <th className='text-center'>
                                                        <b className="text-blue">PLUS</b>/<b className="text-red">MINUS</b>
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {liveMatchesOdds.map((row, i) => {
                                                    let k = i;
                                                    k++
                                                    return (
                                                        <tr>
                                                            <td className='text-center'>
                                                                <Link to="#" className="btn username" onClick={() => handleShowTableOddsClick()}> {row.Name} </Link>
                                                            </td>
                                                            <td className='text-center'>
                                                                <Link to="#" className="btn text-red"> {row.K} </Link>
                                                            </td>
                                                            <td className='text-center'>
                                                                <Link to="#" className="btn text-blue"> {row.L}</Link>
                                                            </td>
                                                            <td className='text-center'>
                                                                {liveMatchPosition["Amount" + k] > 0 ? (<Link to="#" class="btn text-bold text-blue"> {liveMatchPosition["Amount" + k]} </Link>) : (<Link to="#" class="btn text-bold text-red"> {liveMatchPosition["Amount" + k]} </Link>)}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6">
                                        <table className="table table-bordered" id="sOdds">

                                            <thead>
                                                <tr>
                                                    <th className='text-center'>
                                                        Session
                                                    </th>
                                                    <th className=" text-red text-center">
                                                        NOT
                                                    </th>
                                                    <th className="text-blue text-center">
                                                        YES
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {liveMatchesFancy.map((row, index) => {
                                                    return (
                                                        <tr>
                                                            <td className='text-center' style={{ backgroundColor: row.sessionId === sessionHighList ? "antiquewhite" : null }}>
                                                                <a className="btn username" onClick={() => handleShowTableFancyClick(row.sessionId, row.sessionName)}> {row.sessionName} <br />
                                                                    <small>Session Limit : {row.maxLimit}</small></a>
                                                            </td>

                                                            <td className='text-center'>
                                                                <h6 className="text-red"> {row.NO} </h6>
                                                                <b className="badge badge-danger" style={{ padding: 2 }}>{row.Nrate}</b>
                                                            </td>

                                                            <td className='text-center'>
                                                                <h6 className="text-blue"> {row.YES} </h6>
                                                                <b className="badge badge-primary" style={{ padding: 2 }}>{row.Yrate}</b>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>


                                </div>

                                {showOddsTable ?

                                    <div class="row">

                                        <div class="col-lg-12">

                                            <table class="table table-bordered table-responsive-md" id="sessionBetsTable">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Client</th>
                                                        <th>Team Name</th>
                                                        <th>Mode</th>
                                                        <th>BitRate</th>
                                                        <th>Amount</th>
                                                        {Object.keys(oddsBetsTeamList).map(function (row) {
                                                            return (
                                                                <th> {oddsBetsTeamList[row]} </th>
                                                            );
                                                        })}
                                                        <th>Date &amp; Time</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {oddsBetsList.map((row, i) => {
                                                        let k = i;
                                                        let m = 0;
                                                        return (
                                                            <tr>
                                                                <td> {++k} </td>
                                                                <td> {row.clientName} ({row.client_id}) </td>
                                                                <td> {row.teamName} </td>
                                                                <td style={{ color: row.Mode.toUpperCase() == "KHAI" ? "red" : "blue" }}> {row.Mode == "KHAI" ? "LAGAI" : "KHAI"} </td>
                                                                <td> {row.bitrate} </td>
                                                                <td> {row.Amount} </td>
                                                                {Object.keys(oddsBetsTeamList).map(function (row1) {
                                                                    ++m;
                                                                    return (
                                                                        <td style={{ color: row["Amount" + m] * (-1) > 0 ? "blue" : "red" }}> {row["Amount" + m] * (-1)} </td>
                                                                    );
                                                                })}
                                                                <td>{moment(row.datetime).format("DD MMM YYYY h:mm a")}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>

                                            </table>

                                        </div>

                                    </div>
                                    : <div class="row">

                                        <div class="col-lg-10">

                                            <table class="table table-bordered table-responsive-md" id="sessionBetsTable">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Client</th>
                                                        <th>Session</th>
                                                        <th>Runs</th>
                                                        <th>Rate</th>
                                                        <th>Mode</th>
                                                        <th>Amount</th>
                                                        <th>Not</th>
                                                        <th>Yes</th>
                                                        <th>Date &amp; Time</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {fancyBetsList.map((row, i) => {
                                                        let k = i;
                                                        return (
                                                            <tr>
                                                                <td> {++k} </td>
                                                                <td> {row.clientName} ({row.client_id}) </td>
                                                                <td> {row.sessionName} </td>
                                                                <td> {row.run} </td>
                                                                <td> {row.bitrate} </td>
                                                                <td style={{ color: row.Mode.toUpperCase() == "YES" ? "blue" : "red" }}> {row.Mode} </td>
                                                                <td> {row.Amount} </td>
                                                                <td style={{ color: row.Amount1 > 0 ? "blue" : "red" }} > {row.Amount1} </td>
                                                                <td style={{ color: row.Amount2 > 0 ? "blue" : "red" }} > {row.Amount2} </td>
                                                                <td>{moment(row.datetime).format("DD MMM YYYY h:mm a")}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>

                                            </table>

                                        </div>

                                        <div class="col-lg-2">
                                            <table class="table table-bordered" id="sessionPosition">
                                                <thead>
                                                    <tr>
                                                        <th colspan={2}>{sessionName}</th>
                                                    </tr>

                                                    <tr>
                                                        <th>Runs</th>
                                                        <th>Amount</th>
                                                    </tr>

                                                </thead>

                                                <tbody>
                                                    {fancyBetsPositionList.map((row) => {
                                                        return (
                                                            <tr class="text-bold text-blue">
                                                                <td>{row.run}</td>
                                                                <td style={{ color: row.position > 0 ? "blue" : "red" }} > {row.position} </td>
                                                            </tr>
                                                        )
                                                    })}


                                                </tbody>

                                            </table>
                                        </div>

                                    </div>
                                }




                                <div class="row">

                                    <div class="col-lg-12">

                                        <table class="table table-bordered table-responsive-md" id="sessionBetsTable">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Session</th>
                                                    <th>PlusMinus</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {plusMinusList.map((row, i) => {
                                                    let x = i;
                                                    plushMinus += row.amt;
                                                    return (
                                                        <tr>
                                                            <td> {++x} </td>
                                                            <td> {row.sname} </td>
                                                            <td> {row.amt} </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>  </td>
                                                    <td> Total </td>
                                                    <td> {plushMinus} </td>
                                                </tr>
                                            </tfoot>

                                        </table>

                                    </div>

                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </section >

        </div >
    );
}

export default Msposition;
