import React, { useState, useEffect } from 'react';
import Util from '../../../libs/Util';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import moment from 'moment';


const SportBetting = () => {

    var cookies = new Cookies();
    const [sportList, setSportList] = useState([]);

    useEffect(() => {
        fetchSportDetails();
        let timeOut = setInterval(() => { fetchSportDetails() }, 100000);
        return () => { clearInterval(timeOut) };
    }, []);

    const fetchSportDetails = async () => {

        let response = await Util.formSubmit(`${global.MainClient}/cricket/list`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "status": "inplay"
        });

       // console.log(response);

        if (response.status.toString().toUpperCase() === "TRUE") {
            setSportList(response.data);
        }

        // setTimeout(() => { fetchSportDetails() }, 100000);

    }

    return (
        <div className='content-wrapper'>
            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Sport Details</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Sports List</h3>
                                </div>

                                <div class="card-body">

                                    <table id="example1" className="table table-bordered table-striped ">
                                        <thead>
                                            <tr>
                                                <th>
                                                    #
                                                </th>
                                                <th>SNo</th>
                                                <th>Code</th>
                                                <th>Name</th>
                                                <th>Date Time</th>
                                                <th>Match Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sportList.map((row, index) => {
                                                let k = index;
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div align="center">
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn  btn-outline-primary dropdown-toggle dropdown-hover dropdown-icon" data-toggle="dropdown">
                                                                        <span className="sr-only">Toggle Dropdown</span>
                                                                    </button>
                                                                    <div className="dropdown-menu" role="menu">
                                                                        <Link className="dropdown-item btn" to={`/Events/${row.sno}/live-report`}>Match
                                                                            &amp;
                                                                            Session Position </Link>
                                                                        <Link className="dropdown-item btn" to={`/Events/${row.sno}/plusMinusSelect`}>Session
                                                                            Plus Minus
                                                                        </Link>
                                                                        <Link className="dropdown-item btn" to={`/Events/${row.sno}/displayMatchSession`}>
                                                                            Display
                                                                            Match &amp; Session Bets </Link>
                                                                        <Link className="dropdown-item btn" to={`/Events/${row.sno}/match-slips`}>
                                                                            Display
                                                                            Match Bets </Link>
                                                                        <Link className="dropdown-item btn" to={`/Events/${row.sno}/fancy-slips`}>Display
                                                                            Session Bets </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <label>{++k}</label>
                                                        </td>
                                                        <td>
                                                            <span> {row.sno}</span>
                                                        </td>
                                                        <td> {row.MatchName}</td>
                                                        <td>
                                                            {moment(row.MatchDateTime).format("YYYY-MM-DD hh:mm:ss")}
                                                        </td>
                                                        <td>{row.MatchType}</td>
                                                    </tr>);
                                            })}
                                        </tbody>
                                        <tfoot>
                                        </tfoot>
                                    </table>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SportBetting;
