import React, { useState, useEffect } from "react";
import Select from "react-select";
import Util from "../libs/Util";
import Cookies from "universal-cookie";
import { Form } from "react-bootstrap";
import moment from "moment";
import { ReportConst } from "../OLDconst/ReportConst";

const MainReport = (props) => {
  const cookies = new Cookies();

  const [preData] = useState(ReportConst[props.clientType]);

  const [state] = useState({
    isClearable: true,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: true,
  });

  const [fields, setFields] = useState({
    userType: "",
    client: "",
    reportType: "All",
    from: moment(new Date()).format("YYYY-MM-DD"),
    to: moment(new Date()).format("YYYY-MM-DD"),
    idName: "",
  });
  const [options, setOptions] = useState([]);
  const [limitData, setLimitData] = useState([]);

  useEffect(() => {
    fetchClient();
    const script = document.createElement("script");

    // loadJsFile(script);

    script.src = "/js/content.js";
    script.async = true;
    document.body.appendChild(script);

    document.body.removeChild(script);
  }, []);

  const fetchClient = async () => {
    let response = await Util.formSubmit(preData.fetchClientUrl, "POST", {
      token: cookies.get(global.CookiesVariable),
      type: preData.cType,
      rType: "SHOWALL",
    });

    if (response.status === "TRUE") {
      let arr = [];
      for (const iterator of response.data) {
        let obj = {};
        obj.label = iterator.userid + " " + iterator.username;
        obj.value = iterator.userid;
        arr.push(obj);
      }
      setOptions(arr);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    console.log(fields);

    // let response = await Util.formSubmit(`${global.MainClient}/limit/report/client`, "POST", {
    let response = await Util.formSubmit(preData.fetchReportUrl, "POST", {
      token: cookies.get(global.CookiesVariable),
      clientId: fields.client,
      reportType: fields.reportType,
      from: fields.from,
      to: fields.to,
      type: preData.cType,
    });

    if (response.status === "TRUE") {
      setLimitData(response.data);
    }
  };

  return (
    <div className="content-wrapper">
      {/* Card */}
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div
                  className="card-header bg-gradient-purple"
                  data-select2-id="4"
                >
                  <h4>{preData.title} Report</h4>
                  <form onSubmit={onSubmit}>
                    <div
                      class="card-header bg-gradient-purple"
                      data-select2-id="4"
                    >
                      <div class="form-row">
                        <div class="form-group col-md-3">
                          <label> {preData.title} </label>
                          <Select
                            className="basic-single bg-white"
                            classNamePrefix="select"
                            defaultValue={options[0]}
                            isDisabled={state.isDisabled}
                            isLoading={state.isLoading}
                            isClearable={state.isClearable}
                            isRtl={state.isRtl}
                            isSearchable={state.isSearchable}
                            name="client_id"
                            options={options}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                client: e.value,
                                idName: e.label,
                              })
                            }
                          />
                        </div>

                        <div class="form-group col-md-2">
                          <label for="type">Report Type</label>
                          <select
                            class="form-control custom-select"
                            required=""
                            id="type"
                            placeholder="Select Client"
                            name="type"
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                reportType: e.target.value,
                              })
                            }
                          >
                            <option selected disabled>
                              Select type
                            </option>
                            <option value="ALL">All</option>
                            <option value="NAME">Name</option>
                            <option value="LIMIT">Limit</option>
                            <option value="COMMISSION">Commission</option>
                            <option value="SHARE">Share</option>
                          </select>
                        </div>

                        <div class="form-group col-md-2">
                          <div class="form-group">
                            <Form.Group controlId="dob">
                              <Form.Label>Date From</Form.Label>
                              <Form.Control
                                type="date"
                                name="dateFrom"
                                defaultValue={fields.from}
                                onChange={(e) =>
                                  setFields({ ...fields, from: e.target.value })
                                }
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <div class="form-group col-md-2">
                          <div class="form-group">
                            <Form.Group controlId="dob">
                              <Form.Label>Date To</Form.Label>
                              <Form.Control
                                type="date"
                                name="dateTo"
                                defaultValue={fields.to}
                                onChange={(e) =>
                                  setFields({ ...fields, to: e.target.value })
                                }
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <div class="form-group col-md-3">
                          <label class="control-label text-purple" for="btn">
                            `
                          </label>
                          <input
                            type="submit"
                            class="form-control btn-primary"
                            id="btn"
                            name="submit"
                            value="Submit"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="card-body">
                  <table class="table table-bordered table-hover" id="example1">
                    <thead className="bg-gradient-yellow">
                      <tr>
                        <th>#</th>
                        <th>{preData.title}</th>
                        <th>Type</th>
                        <th>Old</th>
                        <th>New</th>
                        <th>User</th>
                        <th>Date</th>
                        <th>Ip</th>
                      </tr>
                    </thead>
                    <tbody>
                      {limitData.map(
                        (
                          {
                            userid,
                            pre_limit,
                            current_limit,
                            datentime,
                            report_type,
                          },
                          i
                        ) => {
                          let k = i;
                          return (
                            <tr
                              data-widget="expandable-table"
                              aria-expanded="false"
                            >
                              <td> {++k} </td>
                              <td>{fields.idName}</td>
                              <td>{report_type}</td>
                              <td>{pre_limit}</td>
                              <td> {current_limit} </td>
                              <td> {userid} </td>
                              <td>
                                {" "}
                                {moment
                                  .unix(datentime)
                                  .format("DD/MM/YYYY hh:mm:ss a")}{" "}
                              </td>
                              <td> </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainReport;
