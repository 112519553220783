import React, { useState, useEffect } from 'react';
import Util from '../../../libs/Util';
import Cookies from 'universal-cookie';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';



const SportComplete = () => {

    var cookies = new Cookies();
    const { id } = useParams();


    const [sportList, setSportList] = useState([]);
    const [currentPage, setCurrentPage] = useState(id);


    useEffect(() => {
        fetchSportDetails();
    }, [id]);


    const fetchSportDetails = async () => {



        let response = await Util.formSubmit(`${global.MainClient}/cricket/list`, "POST", {
            "token": cookies.get(global.CookiesVariable),
            "status": "complete",
            "paging": (parseInt(id) - 1)
        });

        if (response.status.toString().toUpperCase() === "TRUE") {
            setSportList(response.data);
        }

    }



    const nextPage = () => {
        fetchSportDetails(parseInt(currentPage) + 1);
        setCurrentPage(parseInt(currentPage) + 1)

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

    }

    const prevPage = () => {
        if (parseInt(currentPage) >= 1) {
            fetchSportDetails(parseInt(currentPage) - 1);
            setCurrentPage(parseInt(currentPage) - 1)
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }


    const currentPagePagination = (pagiNumber) => {
        fetchSportDetails(parseInt(pagiNumber));
        setCurrentPage(parseInt(pagiNumber));
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }



    return (
        <div className='content-wrapper'>
            {/* Header */}
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Games</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Sports List</h3>
                                </div>

                                <div class="card-body">


                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>

                                                <th>
                                                    #
                                                </th>
                                                <th>SNo</th>
                                                <th>Code</th>
                                                <th>Name</th>
                                                <th>Date Time</th>
                                                <th>Match Type</th>
                                                <th>Declare</th>
                                                <th>Won By</th>
                                                <th>Plus Minus</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {sportList.map((row, index) => {
                                                let k = index;
                                                return (
                                                    <tr class="ant-table-row ant-table-row-level-0" data-row-key="0">
                                                        <td>
                                                            <div align="center">
                                                                <div class="btn-group">
                                                                    <button type="button" class="btn  btn-outline-primary dropdown-toggle dropdown-hover dropdown-icon" data-toggle="dropdown">
                                                                        <span class="sr-only">Toggle Dropdown</span>
                                                                    </button>
                                                                    <div class="dropdown-menu" role="menu">

                                                                        <Link className="dropdown-item btn" to={`/Events/${row.sno}/plusMinusSelect`}>Match &amp; Session Plus Minus</Link>
                                                                        <Link className="dropdown-item btn" to={`/Events/${row.sno}/displayMatchSession`}>Display Match &amp; Session Bets</Link>
                                                                        <Link className="dropdown-item btn" to={`/Events/${row.sno}/match-slips`}>Display Match Bets </Link>
                                                                        <Link className="dropdown-item btn" to={`/Events/${row.sno}/fancy-slips`}>Display Session Bets</Link>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td> {++k}  </td>
                                                        <td> {row.sno}  </td>
                                                        <td>{row.MatchName}</td>
                                                        <td>{moment(row.MatchDateTime).format("DD/MM/YYYY hh:mm a")}</td>
                                                        <td>
                                                            <a href="/Events/1625305701/live-report"> {row.MatchType} </a>
                                                        </td>
                                                        <td>{row.DeclareStatus.toString().toUpperCase() === "RUNNING" || row.DeclareStatus.toString().toUpperCase() === "UNDECLARE" ? "NO" : "YES"}</td>
                                                        <td>{row.wonbyteamname}  </td>
                                                        <td style={{ color: row.plusminus >= 0 ? "blue" : "red" }}>{row.plusminus}</td>
                                                    </tr>
                                                );
                                            })}

                                        </tbody>

                                        <tfoot>

                                        </tfoot>
                                    </table>
















                                </div>


                                <div class="card-footer clearfix">

                                    {
                                        parseInt(currentPage) === 1 ?

                                            <ul class="pagination pagination-sm m-0 float-right">

                                                <div>
                                                    <li class={parseInt(currentPage) === 1 ? 'page-item active' : 'page-item'}>
                                                        <Link class="page-link" to={`/client/sport/complete/${parseInt(currentPage)}`} onClick={() => currentPagePagination(parseInt(currentPage))}> {parseInt(currentPage)} </Link>
                                                    </li>
                                                </div>

                                                <div>
                                                    <li class={parseInt(currentPage) === 1 ? 'page-item' : 'page-item active'}>
                                                        <Link class="page-link" to={`/client/sport/complete/${parseInt(currentPage) + 1}`} onClick={() => currentPagePagination(parseInt(currentPage) + 1)}> {parseInt(currentPage) + 1} </Link>
                                                    </li>
                                                </div>

                                                <div>
                                                    <li class="page-item ">
                                                        <Link class="page-link" to={`/client/sport/complete/${parseInt(currentPage) + 2}`} onClick={() => currentPagePagination(parseInt(currentPage) + 2)}> {parseInt(currentPage) + 2} </Link>
                                                    </li>
                                                </div>

                                                <div>
                                                    <li class="page-item">
                                                        <Link class="page-link" to={`/client/sport/complete/${parseInt(currentPage) + 1}`} onClick={nextPage}>»</Link>
                                                    </li>
                                                </div>

                                            </ul> :

                                            <ul class="pagination pagination-sm m-0 float-right">



                                                {
                                                    parseInt(currentPage) > 1 ? <div>
                                                        <li class="page-item">
                                                            <Link class="page-link" onClick={prevPage} to={`/client/sport/complete/${parseInt(currentPage) - 1}`}>«</Link>
                                                        </li>
                                                    </div> : null
                                                }


                                                <div>
                                                    <li class={parseInt(currentPage) === 0 ? 'page-item active' : 'page-item'}>
                                                        <Link class="page-link" to={`/client/sport/complete/${parseInt(currentPage) - 1}`} onClick={() => currentPagePagination(parseInt(currentPage) - 1)}> {parseInt(currentPage) - 1} </Link>
                                                    </li>
                                                </div>

                                                <div>
                                                    <li class={parseInt(currentPage) === 0 ? 'page-item' : 'page-item active'}>
                                                        <Link class="page-link" to={`/client/sport/complete/${parseInt(currentPage)}`} onClick={() => currentPagePagination(parseInt(currentPage))}> {parseInt(currentPage)} </Link>
                                                    </li>
                                                </div>

                                                <div>
                                                    <li class="page-item ">
                                                        <Link class="page-link" to={`/client/sport/complete/${parseInt(currentPage) + 1}`} onClick={() => currentPagePagination(parseInt(currentPage) + 1)}> {parseInt(currentPage) + 1} </Link>
                                                    </li>
                                                </div>

                                                <div>
                                                    <li class="page-item">
                                                        <Link class="page-link" to={`/client/sport/complete/${parseInt(currentPage) + 1}`} onClick={nextPage}>»</Link>
                                                    </li>
                                                </div>

                                            </ul>
                                    }

                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SportComplete;
